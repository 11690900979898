import React, { FC } from 'react';

import { Input, InputProps } from 'antd';
import AnimatedBackground from '../../Animation';

import useStyle from './style';

interface Props extends InputProps {
  animName: string;
  colors: { main: string; secondary: string };
}

const AnimatedINput: FC<Props> = ({ colors, animName, ...props }) => {
  const classNames = useStyle({ theme: { colors, animName } });

  return (
    <div className={classNames.root}>
      <AnimatedBackground animName={animName} colors={colors} />
      <Input {...props} />
    </div>
  );
};

export default AnimatedINput;
