import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [text, setText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <div>
      <input type="text" value={text} onChange={handleChange} />
      <p>{text}</p>
    </div>
  );
};

const Task1: FC = () => {
  const taskTitle: string =
    '1. Дан инпут и абзац. Сделай так, чтобы при наборе текста в инпуте он автоматически появлялся в абзаце.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { text: '' };

    handleChange(event) {
      this.setState({ text: event.target.value });
    }

    render() {
      return (
        <div>
          <input type="text" value={this.state.text} onChange={this.handleChange.bind(this)} />
          <p>{this.state.text}</p>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App: React.FC = () => {
    const [text, setText] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value);
    };

    return (
      <div>
        <input type="text" value={text} onChange={handleChange} />
        <p>{text}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task1;
