import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

interface StyleProps {
  isStart: boolean;
}

const useStyle = createUseStyles({
  '@keyframes wrapperLogo': {
    from: {
      scale: '1',
    },
    to: {
      scale: '0',
    },
  },
  '@keyframes circleAnim': {
    from: {
      opacity: '1',
    },
    to: {
      opacity: '0',
    },
  },

  root: (props: StyleProps) => ({
    '& .el': {
      position: 'relative',
      color: 'inherit',
    },
    '& .el::after': {
      position: 'absolute',
      top: '15px',
      right: '-10px',
      content: '""',
      width: '60px',
      height: '60px',
      background: 'rgb(255, 216, 62)',
      opacity: '0',
      borderRadius: '50%',
      animationName: '$circleAnim',
      animationDuration: '1000ms',
      animationTimingFunction: 'ease-in',
    },
    '& .small': {
      textAlign: 'center',
    },
    '& .sub-text': {
      display: 'flex',
      color: 'inherit',
      marginLeft: '.2em',
    },
    '& .logo-wrapper': {
      color: PALITRA.white,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'absolute',
      zIndex: 1000,
      top: 15,
      left: 20,
      fontSize: '1.7em',
      fontWeight: 600,
      cursor: 'default',
      userSelect: 'none',
    },
    '& .ml5': {
      position: 'absolute',
      zIndex: 10,
      fontWeight: 800,
      fontSize: '2.5em',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .ml5 .text-wrapper': {
      display: props.isStart ? 'flex' : 'none',
      color: 'aliceblue',
      animationDuration: '1s',
      animationDelay: '2000ms',
      animationName: '$wrapperLogo',
      animationTimingFunction: 'cubic-bezier(0.05, -0.47, 0.71, 0.31)',
      animationFillMode: 'forwards',
    },
    '@media screen and (max-width: 624px)': {
      '& .sub-text': {
        display: 'none',
      },
    },
  }),
});

export default useStyle;
