import React from 'react';
import ComponentTemplate from '../../Task';

const Task8: React.FC = () => {
  const taskTitle: string =
    '8. Реализуй игру крестики-нолики. Играют два игрока, каждый ходит по очереди.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task8;
