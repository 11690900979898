import React, { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Modal } from 'antd';

import { storageService } from '../../services/StorageService';
import { PALITRA } from '../../utils/constants';

interface ModalWindowProps {
  state: {
    popup: boolean;
    solutionVerified: boolean;
    solution: boolean;
  };
  setState: React.Dispatch<
    React.SetStateAction<{
      popup: boolean;
      solutionVerified: boolean;
      solution: boolean;
    }>
  >;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ state, setState }) => {
  const inputsValue: React.MutableRefObject<any> = useRef(null);

  const [errorStatus, setErrorStatus] = useState(false);

  const onOkClick = () => {
    const currentValue = inputsValue.current.state.value;
    const showCode = process.env.REACT_APP_SHOW_CODE;

    if (currentValue.length === 8) {
      currentValue === showCode &&
        setState({
          ...state,
          popup: false,
          solutionVerified: true,
          solution: true,
        });
      storageService.set('showPIN', currentValue);
      inputsValue.current.state.value = '';
    }
    setErrorStatus(true);
  };

  useEffect(() => {
    if (state.popup) {
      document.addEventListener('keydown', (event) => {
        if (event.isComposing || event.code !== 'Enter') {
          return;
        }

        onOkClick();
      });
    }
    return () => document.removeEventListener('keydown', onOkClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.popup]);

  return (
    <Modal
      title="Enter PIN-code"
      open={state.popup}
      onOk={onOkClick}
      onCancel={() => {
        setState({ ...state, popup: false });
        setErrorStatus(false);
      }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <ReactCodeInput
        type="text"
        name="react-code-input"
        inputMode="numeric"
        fields={8}
        ref={inputsValue}
      />
      <div>
        {errorStatus && (
          <h3 style={{ color: PALITRA.red.red }}>Code is invalid! Please try again...</h3>
        )}
      </div>
    </Modal>
  );
};

export default ModalWindow;
