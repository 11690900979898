import React from 'react';

import AdventageCard from './AdventageCard';
import { DATA_ADVANTAGES } from '../../utils/constants';

import useStyles from './style';

const Advantages = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className="title">The advantages you'll get</h2>
      <p className="subtitle">
        Our diverse background allows offering you the most effective practices.
      </p>
      <div className="advantage-wrapper">
        {DATA_ADVANTAGES.map((item) => (
          <AdventageCard key={item.id} title={item.title} icon={item.icon} desc={item.desc} />
        ))}
      </div>
    </div>
  );
};

export default Advantages;
