import { Button } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import useStyles from './style';

interface OneClientProps {
  name: string;
  src: string;
  desc: string;
  mirror: boolean;
  redirect: string;
}

const OneClient: FC<OneClientProps> = ({ name, src, desc, mirror, redirect }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, { 'client-reverse': mirror })}>
      <div className="client-image">
        <img src={src} alt={name} className="client-img" />
      </div>
      <div className="client-information">
        <h3 className="client-information-title">{name}</h3>
        <div className="client-desc">{desc}</div>
        <Button className="client-btn">
          <Link to={redirect}>View Case Study</Link>
        </Button>
      </div>
    </div>
  );
};

export default OneClient;
