const Pages = {
  HOME: '/',
  SiGN_UP: '/sign-up',
  LOGIN: '/login',
  OLD_HOME_PAGE: '/old-home-page',
  BLOG: '/blog',
  BLOG_ARTICLE: '/blog/:id',
  BATTLESHIP: '/battleship',
  TIC_TAC_TOE: '/tic-tac-toe',
  PROFILE: '/profile',
  TASK: '/lesson/:lesson/task/:task',
  LESSONS: '/lessons-page',
  LESSON: '/lesson/:lesson',
};

export default Pages;
