import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './style';

interface LessonListTemplateProps {
  tasks: JSX.Element[];
  lesson: string;
}

const LessonListTemplate: React.FC<LessonListTemplateProps> = ({ tasks, lesson }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ul className="list">
        {tasks.map((_, index) => (
          <li key={index} className="list-item">
            <Link to={`/lesson/${lesson}/task/${index + 1}`}>
              <div
                className="link-layer"
                style={{
                  backgroundImage: `url(https://picsum.photos/218/218?grayscale&random=${
                    index + 1
                  })`,
                }}></div>
              <div className="link-text">Task {index + 1}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LessonListTemplate;
