import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const state = { name: 'Иван', age: 25 };

  return (
    <div>
      имя: {state.name}, возраст: {state.age}
    </div>
  );
};

const Task1: FC = () => {
  const taskTitle: string = '1. Дан следующий объект:';
  const taskCode: string = `
    const person = {name: 'Иван', age: 25};
  `;
  const taskTitle2: string = `Сделай так, чтобы компонент вывел на экран следующее ('Иван' и 25 должны взяться из объекта):`;
  const taskCode2: string = `
    <div>
      имя: Иван, возраст: 25
    </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25 };

    render() {
      return (
        <div>
          имя: {this.state.name}, возраст: {this.state.age}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const person = { name: 'Иван', age: 25 };

    return (
      <div>
        имя: {person.name}, возраст: {person.age}
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
      />
    </div>
  );
};
export default Task1;
