import React, { FC } from 'react';

import useStyles from './style';

interface BlogItemProps {
  title: string;
  desc: string;
  src: string;
  date: string;
  moreInformation: () => void;
}

const BlogItem: FC<BlogItemProps> = ({ title, desc, src, date, moreInformation }) => {
  const classes = useStyles();

  const imageName = src.split('/')[3].replace('.', '');

  return (
    <div className={classes.root} onClick={() => moreInformation()}>
      <div className="blog-image">
        <img src={src} alt={imageName} className="blog-img" />
      </div>
      <div className="blog-content">
        <h3 className="blog-title">{title}</h3>
        <div className="blog-desc">{desc}</div>
        <div className="blog-date">{date}</div>
      </div>
    </div>
  );
};

export default BlogItem;
