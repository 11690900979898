import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '& .ant-divider-with-text-left': {
      borderTopColor: PALITRA.red.red,
    },
    '& .deviderHorizontal': {
      background: PALITRA.red.red,
    },
    '& .link': {
      marginLeft: '10px',
      color: 'rgba(0, 0, 0, 0.85)',
      '&:hover': {
        color: '#FF0057',
      },
    },
    '& .linkPhone': {
      marginLeft: '10px',
      color: 'rgba(0, 0, 0, 0.85)',
      '&:hover': {
        color: '#FF0057',
      },
    },
    '& .main-text': {
      textAlign: 'justify',
    },
    '@media screen and (max-width: 991px)': {
      '& .text': {
        fontSize: '18px',
      },
    },
    '@media screen and (max-width: 465px)': {
      '& .text': {
        fontSize: '14px',
      },
      '& .linkPhone': {
        fontSize: '13px',
      },
    },
  },
});

export default useStyles;
