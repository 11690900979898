import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    display: 'grid',
    textAlign: 'left',
    gridTemplateColumns: 'minmax(200px, 600px)',
    justifyContent: 'center',
    marginBottom: '30px',

    '@media screen and (min-width: 900px)': {
      gridTemplateColumns: 'repeat(2, minmax(50px, 1fr))',
      columnGap: '40px',

      '&.client-reverse': {
        '& .client-image': {
          order: 2,
        },
        '& .client-information': {
          order: 1,
        },
      },
    },

    '& .client-image': {
      position: 'relative',
      padding: '0px 0px 85% 0px',

      '@media screen and (max-width: 900px)': {
        marginBottom: '15px',
      },

      '& .client-img': {
        position: 'absolute',
        borderRadius: '5px',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .client-information': {
      '& .client-information-title': {
        fontSize: '24px',
        fontWeight: '600',
      },
      '& .client-desc': {
        fontSize: '16px',
        fontWeight: '300',
        marginBottom: '20px',
      },

      '& .client-btn': {
        height: '60px',
        fontWeight: '700',
        fontSize: '16px',
        cursor: 'pointer',
        background: 'transparent',
        border: `1px solid ${PALITRA.crimson}`,
        color: PALITRA.crimson,
        borderRadius: '5px',
        paddingRight: '35px',
        paddingLeft: '35px',
      },

      '@media screen and (max-width: 767px)': {
        '& .client-information-title': {
          fontSize: '22px',
        },
        '& .client-desc': {
          fontSize: '14px',
        },

        '& .client-btn': {
          paddingRight: '20px',
          paddingLeft: '20px',
        },
      },
    },
  },
});

export default useStyles;
