import React, { useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { appState } from '../../Context/secondClass';
import Modal from '../Modal';
import FeedbackForm from '../Forms/Feedback';

const Section8To9 = () => {
  const slider: any = useRef(null);
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const showModal = () => {
    setIsModalOpen(true);
    appState.isModalOpen = true;
  };

  const handleOk = () => {
    setIsModalOpen(false);
    appState.isModalOpen = false;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    appState.isModalOpen = false;
  };

  return (
    <section className="space__section space__section--2">
      <Modal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}>
        <FeedbackForm handleCancel={handleCancel} handleOk={handleOk} />
      </Modal>
      <div className="space__slides">
        <div className="space__slide sect2 top">
          <div className="finale-slide">
            <h2 className="finale-slide__title"> {t('section2.learn')}</h2>
          </div>
          <div className="swiper">
            <ArrowRightOutlined
              className="rightArrow"
              onClick={() => {
                slider.current.slickNext();
              }}
            />
            <ArrowLeftOutlined
              className="leftArrow"
              onClick={() => {
                slider.current.slickPrev();
              }}
            />
            <Slider {...settings} ref={slider}>
              <div className="benefit">
                <h3 className="benefit__title">{t('section2.lendstart')}</h3>
                <div className="wrapperBenefit">
                  <div className="benefit__icon">
                    <img alt="Lend start icon" src="assets/iconsHome/icon-lendstart.svg" />
                  </div>
                  <div className="wrapperTextBenefit">
                    <p className="benefit__description">{t('section2.financialText')}</p>
                  </div>
                </div>
                <button className="btn btn-outline">{t('section2.explore')}</button>
              </div>
              <div className="benefit">
                <h3 className="benefit__title">{t('section2.sonary')}</h3>
                <div className="wrapperBenefit">
                  <div className="benefit__icon">
                    <img alt="Sonary icon" src="assets/iconsHome/icon-sonary.svg" />
                  </div>
                  <div className="wrapperTextBenefit">
                    <p className="benefit__description">{t('section2.b2Bplatform')}</p>
                  </div>
                </div>
                <button className="btn btn-outline">{t('section2.explore')}</button>
              </div>

              <div className="benefit">
                <h3 className="benefit__title">{t('section2.playRight')}</h3>
                <div className="wrapperBenefit">
                  <div className="benefit__icon">
                    <img alt="play right icon" src="assets/iconsHome/icon-play-right.svg" />
                  </div>
                  <div className="wrapperTextBenefit">
                    <p className="benefit__description">{t('section2.established')}</p>
                  </div>
                </div>
                <button className="btn btn-outline">{t('section2.explore')}</button>
              </div>
            </Slider>
          </div>
        </div>

        <div className="space__slide">
          <h2 className="big-text big-text--white">
            <span>{t('section2.findBetter')}</span>
          </h2>

          <button className="btn btn-submit btn-submit--position" onClick={() => showModal()}>
            {t('section2.let_sGo')}
          </button>
        </div>
      </div>
    </section>
  );
};
export { Section8To9 };
