import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Layout } from 'antd';

import useStyles from './style';

const { Footer } = Layout;

const CustomFooter = () => {
  const classes = useStyles();
  const pathname: string = useHistory().location.pathname;
  const [isTask, setIsTask] = useState<boolean>(false);

  useEffect(() => {
    const conditionValue: boolean = pathname.includes('lesson') && pathname.includes('task');

    setIsTask(conditionValue);
  }, [pathname]);

  return (
    <Footer className={classNames(classes.root, { taskFooter: isTask })}>
      <div>© UniCode. All rights reserved.</div>
    </Footer>
  );
};

export default withRouter(CustomFooter);
