import React, { FC, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import LessonsDropdown from '../LessonsDropdown';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { authService } from '../../../../services/AuthService';
import { useTranslation } from '../../../../i18n';

import useStyles from './style';
import { SelectLanguage } from '../../../Selectanguage';
import Pages from '../../../../utils/pages';

interface DefaultMenuProps {
  isMenuOpen: boolean;
  pathname: string;
}

const DefaultMenu: FC<DefaultMenuProps> = ({ isMenuOpen, pathname }) => {
  const classes = useStyles();
  const numberLessons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const wrapperRef = useRef(null);

  const { t } = useTranslation();

  const [isLessonsDropped, setIsLessonsDropped] = useState(false);

  const history = useHistory();
  const link = history.location.pathname;

  const handleClickOutside = () => {
    setIsLessonsDropped(false);
  };

  useEffect(() => {
    setIsLessonsDropped(false);
  }, [pathname]);

  useOnClickOutside(wrapperRef, handleClickOutside);
  const items: MenuProps['items'] = numberLessons.map((lesson, i) => {
    return {
      label: (
        <Link className="header_dropdown" to={`/lesson/${lesson}`}>
          {t('defaultMenu.lessons')} {lesson}
        </Link>
      ),
      key: i,
    };
  });

  return (
    <ul className={classNames('default-menu', classes.root)}>
      <li
        className={classNames('logo__item', 'no-hover', {
          disabledLogo: isMenuOpen,
        })}>
        <Link to={Pages.HOME}>
          <img src="/assets/logo.webp" alt="logo" />
        </Link>
      </li>
      <li className={classNames(link === Pages.LESSONS && 'active')}>
        <Dropdown
          menu={{ items }}
          className="default-menu__dropdown"
          overlayClassName={classes.rootDrop}>
          <Space>
            <Link to={Pages.LESSONS}>{t('defaultMenu.lessons')}</Link>
            <DownOutlined />
          </Space>
        </Dropdown>
        {isLessonsDropped && (
          <div ref={wrapperRef}>
            <LessonsDropdown className="lessons__dropdown" />
          </div>
        )}
      </li>
      <li
        className={classNames(link === Pages.TIC_TAC_TOE && 'active', 'default-menu__tic-tac-toe')}>
        <Link to={Pages.TIC_TAC_TOE}>Tic tac toe</Link>
      </li>
      <li className={classNames(link === Pages.BATTLESHIP && 'active', 'default-menu__battleship')}>
        <Link to={Pages.BATTLESHIP}>Battleship</Link>
      </li>
      <li>
        <SelectLanguage styleForSelect={{ bg: '#1a374d', colorText: '#fff' }} />
      </li>
      {authService.isLogin() ? (
        <>
          <li className={classNames(link === Pages.PROFILE && 'active', 'profile__item')}>
            <Link to={Pages.PROFILE}>
              <img src="/assets/icons/user--avatar--filled--alt.svg" alt="avatar icon" />
            </Link>
          </li>
          <li className={classNames(link === Pages.LOGIN && 'active', 'logout__item')}>
            <Link to={Pages.LOGIN} onClick={() => authService.logoutUser()}>
              {t('defaultMenu.logout')}
            </Link>
          </li>
        </>
      ) : (
        <li className={classNames(link === Pages.LOGIN && 'active', 'login__item')}>
          <Link to={Pages.LOGIN}>{t('defaultMenu.login')}</Link>
        </li>
      )}
    </ul>
  );
};

export default DefaultMenu;
