import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { PALITRA } from '../../../utils/constants';
import ComponentTemplate from '../../Task';

interface EmplyeesTableProps {
  data: {
    firstName: string;
    secondName: string;
    salary: null | number;
  }[];
}

interface DataProps {
  options: {
    quanityOfItems: number;
    quanityOfPages: number;
    startIndex: number;
    endIndex: number;
    currentPage: number;
  };
  visibleData: { firstName: string; secondName: string; salary: null | number }[];
}

const employees = [
  { firstName: 'John', secondName: 'Robertson', salary: 543 },
  { firstName: 'David', secondName: 'Dubliakov', salary: 23453 },
  { firstName: 'Tom', secondName: 'Smith', salary: 1242 },
  { firstName: 'Rodjer', secondName: 'Smith', salary: 313 },
  { firstName: 'Jimmy', secondName: 'Smith', salary: 653 },
  { firstName: 'Pamela', secondName: 'Smith', salary: 335 },
  { firstName: 'Kris', secondName: 'Smith', salary: 533 },
  { firstName: 'Laueren', secondName: 'Smith', salary: 3564 },
  { firstName: 'Celvin', secondName: 'Smith', salary: 3756 },
  { firstName: 'Pimp', secondName: 'Smith', salary: 300 },
  { firstName: 'Harris', secondName: 'Smith', salary: 3226 },
  { firstName: 'Jenifer', secondName: 'Smith', salary: 70 },
  { firstName: 'Killy', secondName: 'Smith', salary: 946 },
  { firstName: 'Tyga', secondName: 'Smith', salary: 942 },
  { firstName: 'Eminem', secondName: 'Smith', salary: 734 },
  { firstName: 'Dima', secondName: 'Smith', salary: 3876 },
  { firstName: 'Yura', secondName: 'Smith', salary: 58 },
  { firstName: 'Vanya', secondName: 'Smith', salary: 92 },
  { firstName: 'Karen', secondName: 'Smith', salary: 4321 },
  { firstName: 'Gocha', secondName: 'Smith', salary: 433 },
  { firstName: 'Sasha', secondName: 'Smith', salary: 336 },
  { firstName: 'Vitya', secondName: 'Smith', salary: 999 },
  { firstName: 'Artem', secondName: 'Smith', salary: 412 },
  { firstName: 'Arkadiy', secondName: 'Smith', salary: 657 },
  { firstName: 'Liza', secondName: 'Smith', salary: 956 },
  { firstName: 'Fedor', secondName: 'Smith', salary: 536 },
];

const EmplyeesTable: FC<EmplyeesTableProps> = ({ data }) => (
  <table>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td width="15%">{item.firstName}</td>
          <td width="20%">{item.secondName}</td>
          <td width="10%">{item.salary}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const DEFAULT_QUANITY_OF_ITEMS = 10;

  const [data, setData] = useState<DataProps>({
    options: {
      quanityOfItems: DEFAULT_QUANITY_OF_ITEMS,
      quanityOfPages: Math.ceil(employees.length / DEFAULT_QUANITY_OF_ITEMS),
      startIndex: 0,
      endIndex: DEFAULT_QUANITY_OF_ITEMS,
      currentPage: 0,
    },
    visibleData: [{ firstName: '', secondName: '', salary: null }],
  });

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      visibleData: employees.slice(data.options.startIndex, data.options.endIndex),
    }));
  }, [data.options.startIndex, data.options.endIndex]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const copyData = { ...data };

    copyData.options.quanityOfItems = +event.target.value;
    copyData.options.startIndex = 0;
    copyData.options.endIndex = 0 + copyData.options.quanityOfItems;
    copyData.options.currentPage = 0;
    copyData.options.quanityOfPages = Math.ceil(employees.length / copyData.options.quanityOfItems);

    setData(copyData);
  };

  const createButtonsArr = (v: number) => {
    let arr = [];
    for (let i = 1; i <= v; i++) {
      arr.push(i);
    }
    return arr;
  };

  const handleChangePage = (index: number, commutator: string) => {
    const copyData = { ...data };

    let currentPage = index;

    if (commutator === 'minus' && currentPage !== 0) {
      currentPage = currentPage - 1;
    }

    if (commutator === 'plus' && currentPage !== copyData.options.quanityOfPages - 1) {
      currentPage = currentPage + 1;
    }

    index = currentPage;
    copyData.options.startIndex = 0;
    copyData.options.endIndex = 0 + copyData.options.quanityOfItems;
    copyData.options.startIndex = copyData.options.endIndex * index;
    copyData.options.endIndex = copyData.options.endIndex + copyData.options.quanityOfItems * index;
    copyData.options.currentPage = index;

    setData(copyData);
  };

  return (
    <div>
      <EmplyeesTable data={data.visibleData} />

      {createButtonsArr(data.options.quanityOfPages).map((item, index) => (
        <button
          style={{
            backgroundColor:
              data.options.currentPage === index ? PALITRA.red.redDark : 'transparent',
          }}
          key={index}
          onClick={() => handleChangePage(index, '')}>
          {item}
        </button>
      ))}

      <button onClick={() => handleChangePage(data.options.currentPage, 'minus')}>Prev</button>
      <button onClick={() => handleChangePage(data.options.currentPage, 'plus')}>Next</button>

      <select onChange={handleSelectChange} value={data.options.quanityOfItems}>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
      </select>
    </div>
  );
};

const Task13: FC = () => {
  const taskTitle: string =
    '13. Дан массив с работниками. У каждого работника есть имя, фамилия, зарплата. Выведи этих работников на экран в виде таблицы. Причем выведи только первых 10 работников, а под таблицей сделай ссылки (кнопки): 1, 2, 3, 4 и так далее. По нажатию на каждую ссылку в таблице будет отображаться заданный десяток работников. Ссылки под таблицей должны сгенерироваться автоматически исходя из количества работников.';
  const taskClassSolution: string = `
  const DEFAULT_QUANITY_OF_ITEMS = 10;

  const EMPLOYEES = [
    { firstName: 'John', secondName: 'Robertson', salary: 543 },
    { firstName: 'David', secondName: 'Dubliakov', salary: 23453 },
    { firstName: 'Tom', secondName: 'Smith', salary: 1242 },
    { firstName: 'Rodjer', secondName: 'Smith', salary: 313 },
    { firstName: 'Jimmy', secondName: 'Smith', salary: 653 },
    { firstName: 'Pamela', secondName: 'Smith', salary: 335 },
    { firstName: 'Kris', secondName: 'Smith', salary: 533 },
    { firstName: 'Laueren', secondName: 'Smith', salary: 3564 },
    { firstName: 'Celvin', secondName: 'Smith', salary: 3756 },
    { firstName: 'Pimp', secondName: 'Smith', salary: 300 },
    { firstName: 'Harris', secondName: 'Smith', salary: 3226 },
    { firstName: 'Jenifer', secondName: 'Smith', salary: 70 },
    { firstName: 'Killy', secondName: 'Smith', salary: 946 },
    { firstName: 'Tyga', secondName: 'Smith', salary: 942 },
    { firstName: 'Eminem', secondName: 'Smith', salary: 734 },
    { firstName: 'Dima', secondName: 'Smith', salary: 3876 },
    { firstName: 'Yura', secondName: 'Smith', salary: 58 },
    { firstName: 'Vanya', secondName: 'Smith', salary: 92 },
    { firstName: 'Karen', secondName: 'Smith', salary: 4321 },
    { firstName: 'Gocha', secondName: 'Smith', salary: 433 },
    { firstName: 'Sasha', secondName: 'Smith', salary: 336 },
    { firstName: 'Vitya', secondName: 'Smith', salary: 999 },
    { firstName: 'Artem', secondName: 'Smith', salary: 412 },
    { firstName: 'Arkadiy', secondName: 'Smith', salary: 657 },
    { firstName: 'Liza', secondName: 'Smith', salary: 956 },
    { firstName: 'Fedor', secondName: 'Smith', salary: 536 },
  ];

  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        options: {
          quanityOfItems: DEFAULT_QUANITY_OF_ITEMS,
          quanityOfPages: Math.ceil(EMPLOYEES.length / DEFAULT_QUANITY_OF_ITEMS),
          startIndex: 0,
          endIndex: DEFAULT_QUANITY_OF_ITEMS,
          currentPage: 0,
        },
        visibleData: this.fillingVisibleData(0, DEFAULT_QUANITY_OF_ITEMS),
      };
    }

    fillingVisibleData(startIndex, endIndex) {
      return EMPLOYEES.slice(startIndex, endIndex);
    }

    handleSelectChange(value) {
      const copyState = { ...this.state };

      copyState.options.quanityOfItems = Number(value);
      copyState.options.startIndex = 0;
      copyState.options.endIndex = 0 + copyState.options.quanityOfItems;
      copyState.options.currentPage = 0;
      copyState.options.quanityOfPages = Math.ceil(
        EMPLOYEES.length / copyState.options.quanityOfItems
      );

      copyState.visibleData = this.fillingVisibleData(
        copyState.options.startIndex,
        copyState.options.endIndex
      );
      this.setState(copyState);
    }

    createButtonsArr(v) {
      let arr = [];
      for (let i = 1; i <= v; i++) {
        arr.push(i);
      }
      return arr;
    }

    handleChangePage(index, commutator) {
      const copyState = { ...this.state };

      let currentPage = index;

      if (commutator === 'minus' && currentPage !== 0) {
        currentPage = currentPage - 1;
      }

      if (
        commutator === 'plus' &&
        currentPage !== copyState.options.quanityOfPages - 1
      ) {
        currentPage = currentPage + 1;
      }

      index = currentPage;
      copyState.options.startIndex = 0;
      copyState.options.endIndex = 0 + copyState.options.quanityOfItems;
      copyState.options.startIndex = copyState.options.endIndex * index;
      copyState.options.endIndex =
        copyState.options.endIndex + copyState.options.quanityOfItems * index;
      copyState.options.currentPage = index;
      copyState.visibleData = this.fillingVisibleData(
        copyState.options.startIndex,
        copyState.options.endIndex
      );

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <EmplyeesTable data={this.state.visibleData} />

          {this.createButtonsArr(this.state.options.quanityOfPages).map(
            (item, index) => (
              <button
                style={{
                  backgroundColor:
                    this.state.options.currentPage === index
                      ? '#A52A2A'
                      : 'transparent',
                }}
                key={index}
                onClick={() => this.handleChangePage(index, '')}
              >
                {item}
              </button>
            )
          )}

          <button
            onClick={() =>
              this.handleChangePage(this.state.options.currentPage, 'minus')
            }
          >
            Prev
          </button>
          <button
            onClick={() =>
              this.handleChangePage(this.state.options.currentPage, 'plus')
            }
          >
            Next
          </button>

          <select
            onChange={(event) => this.handleSelectChange(event.target.value)}
            value={this.state.options.quanityOfItems}
          >
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
          </select>
        </div>
      );
    }
  }

  class EmplyeesTable extends React.Component {
    render() {
      return (
        <table>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td width='15%'>{item.firstName}</td>
                <td width='20%'>{item.secondName}</td>
                <td width='10%'>{item.salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const employees = [
    { firstName: 'John', secondName: 'Robertson', salary: 543 },
    { firstName: 'David', secondName: 'Dubliakov', salary: 23453 },
    { firstName: 'Tom', secondName: 'Smith', salary: 1242 },
    { firstName: 'Rodjer', secondName: 'Smith', salary: 313 },
    { firstName: 'Jimmy', secondName: 'Smith', salary: 653 },
    { firstName: 'Pamela', secondName: 'Smith', salary: 335 },
    { firstName: 'Kris', secondName: 'Smith', salary: 533 },
    { firstName: 'Laueren', secondName: 'Smith', salary: 3564 },
    { firstName: 'Celvin', secondName: 'Smith', salary: 3756 },
    { firstName: 'Pimp', secondName: 'Smith', salary: 300 },
    { firstName: 'Harris', secondName: 'Smith', salary: 3226 },
    { firstName: 'Jenifer', secondName: 'Smith', salary: 70 },
    { firstName: 'Killy', secondName: 'Smith', salary: 946 },
    { firstName: 'Tyga', secondName: 'Smith', salary: 942 },
    { firstName: 'Eminem', secondName: 'Smith', salary: 734 },
    { firstName: 'Dima', secondName: 'Smith', salary: 3876 },
    { firstName: 'Yura', secondName: 'Smith', salary: 58 },
    { firstName: 'Vanya', secondName: 'Smith', salary: 92 },
    { firstName: 'Karen', secondName: 'Smith', salary: 4321 },
    { firstName: 'Gocha', secondName: 'Smith', salary: 433 },
    { firstName: 'Sasha', secondName: 'Smith', salary: 336 },
    { firstName: 'Vitya', secondName: 'Smith', salary: 999 },
    { firstName: 'Artem', secondName: 'Smith', salary: 412 },
    { firstName: 'Arkadiy', secondName: 'Smith', salary: 657 },
    { firstName: 'Liza', secondName: 'Smith', salary: 956 },
    { firstName: 'Fedor', secondName: 'Smith', salary: 536 },
  ];

  const EmplyeesTable = ({ data }) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td width="15%">{item.firstName}</td>
            <td width="20%">{item.secondName}</td>
            <td width="10%">{item.salary}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const TaskReady = () => {
    const DEFAULT_QUANITY_OF_ITEMS = 10;

    const [data, setData] = useState({
      options: {
        quanityOfItems: DEFAULT_QUANITY_OF_ITEMS,
        quanityOfPages: Math.ceil(employees.length / DEFAULT_QUANITY_OF_ITEMS),
        startIndex: 0,
        endIndex: DEFAULT_QUANITY_OF_ITEMS,
        currentPage: 0,
      },
      visibleData: [{ firstName: '', secondName: '', salary: null }],
    });

    useEffect(() => {
      setData((prev) => ({
        ...prev,
        visibleData: employees.slice(data.options.startIndex, data.options.endIndex),
      }));
    }, [data.options.startIndex, data.options.endIndex]);

    const handleSelectChange = (event) => {
      const copyData = { ...data };

      copyData.options.quanityOfItems = +event.target.value;
      copyData.options.startIndex = 0;
      copyData.options.endIndex = 0 + copyData.options.quanityOfItems;
      copyData.options.currentPage = 0;
      copyData.options.quanityOfPages = Math.ceil(employees.length / copyData.options.quanityOfItems);

      setData(copyData);
    };

    const createButtonsArr = (v) => {
      let arr = [];
      for (let i = 1; i <= v; i++) {
        arr.push(i);
      }
      return arr;
    };

    const handleChangePage = (index, commutator) => {
      const copyData = { ...data };

      let currentPage = index;

      if (commutator === 'minus' && currentPage !== 0) {
        currentPage = currentPage - 1;
      }

      if (commutator === 'plus' && currentPage !== copyData.options.quanityOfPages - 1) {
        currentPage = currentPage + 1;
      }

      index = currentPage;
      copyData.options.startIndex = 0;
      copyData.options.endIndex = 0 + copyData.options.quanityOfItems;
      copyData.options.startIndex = copyData.options.endIndex * index;
      copyData.options.endIndex = copyData.options.endIndex + copyData.options.quanityOfItems * index;
      copyData.options.currentPage = index;

      setData(copyData);
    };

    return (
      <div>
        <EmplyeesTable data={data.visibleData} />

        {createButtonsArr(data.options.quanityOfPages).map((item, index) => (
          <button
            style={{
              backgroundColor: data.options.currentPage === index ? '#A52A2A' : 'transparent',
            }}
            key={index}
            onClick={() => handleChangePage(index, '')}>
            {item}
          </button>
        ))}

        <button onClick={() => handleChangePage(data.options.currentPage, 'minus')}>Prev</button>
        <button onClick={() => handleChangePage(data.options.currentPage, 'plus')}>Next</button>

        <select onChange={handleSelectChange} value={data.options.quanityOfItems}>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task13;
