import React from 'react';
import { DATA_CLIENTS } from '../../utils/constants';

import Container from '../Container';
import OneClient from './OneClient';

import useStyles from './style';

const OurClients = () => {
  const classes = useStyles();

  return (
    <Container
      className={classes.root}
      title="Our Clients"
      subtitle="We offer a wide range of services, and here are some of our best projects.">
      <div className="client-wrap">
        {DATA_CLIENTS.map((item) => (
          <OneClient
            name={item.name}
            key={item.id}
            src={item.src}
            desc={item.desc}
            mirror={item.mirror}
            redirect={item.to}
          />
        ))}
      </div>
    </Container>
  );
};

export default OurClients;
