import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface SelectsProps {
  data: DateValuesProps;
  selectedArr: string;
  selectedValue: string;
  funcChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface DateValuesProps {
  day: number[];
  month: number[];
  year: number[];
  selectedDate: number;
  selectedMonth: number;
  selectedYear: number;
  selectedDayOfTheWeek: number | string;
}

const Selects: FC<SelectsProps> = ({ data, selectedArr, selectedValue, funcChange }) => {
  return (
    <select value={data[selectedValue]} name={selectedValue} onChange={funcChange}>
      {data[selectedArr].map((item: string, index: number) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

const TaskReady = () => {
  const [dateValues, setDateValues] = useState<DateValuesProps>({
    day: [],
    month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    year: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    selectedDate: new Date().getDate(),
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    selectedDayOfTheWeek: new Date().getDay(),
  });

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>) =>
    setDateValues({ ...dateValues, [name]: Number(value) });

  const returnDays = () => {
    const currentDate = new Date(dateValues.selectedYear, dateValues.selectedMonth, 0).getDate();

    const arr = [];

    for (let i = 1; i <= currentDate; i++) {
      arr.push(i);
    }

    return arr;
  };

  const converToString = (day: string | number) => {
    const newDate = new Date(
      dateValues.selectedYear,
      dateValues.selectedMonth - 1,
      dateValues.selectedDate,
    );
    const options: any = { weekday: 'long' };
    day = new Intl.DateTimeFormat('ru-Ru', options).format(newDate);
    return day;
  };

  return (
    <div>
      <Selects
        data={{ ...dateValues, day: returnDays() }}
        selectedArr="day"
        selectedValue="selectedDate"
        funcChange={handleChange}
      />

      <Selects
        data={dateValues}
        selectedArr="month"
        selectedValue="selectedMonth"
        funcChange={handleChange}
      />

      <Selects
        data={dateValues}
        selectedArr="year"
        selectedValue="selectedYear"
        funcChange={handleChange}
      />

      <p>{converToString(dateValues.selectedDayOfTheWeek)}</p>
    </div>
  );
};

const Task13: FC = () => {
  const taskTitle: string =
    '13. Даны 3 селекта. В первом можно выбрать день, во втором месяц, а в третьем год. Дан абзац. Сделай так, чтобы в этот абзац вывелся день недели за выбранную дату. Пусть по умолчанию в селекте отображается текущая дата.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        day: [],
        month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        year: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
        selectedDate: new Date().getDate(),
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
        selectedDayOfTheWeek: new Date().getDay(),
      };
    }

    handleChange({ target: { name, value } }) {
      this.setState({ ...this.state, [name]: Number(value) });
    }

    returnDays() {
      const currentDate = new Date(
        this.state.selectedYear,
        this.state.selectedMonth,
        0
      ).getDate();

      const arr = [];

      for (let i = 1; i <= currentDate; i++) {
        arr.push(i);
      }

      return arr;
    }

    converToString(day) {
      const newDate = new Date(
        this.state.selectedYear,
        this.state.selectedMonth - 1,
        this.state.selectedDate
      );
      const options = { weekday: 'long' };
      day = new Intl.DateTimeFormat('ru-Ru', options).format(newDate);
      return day;
    }

    render() {
      return (
        <div>
          <Select
            data={{ ...this.state, day: this.returnDays() }}
            selectedArr='day'
            selectedValue='selectedDate'
            funcChange={this.handleChange.bind(this)}
          />

          <Select
            data={this.state}
            selectedArr='month'
            selectedValue='selectedMonth'
            funcChange={this.handleChange.bind(this)}
          />

          <Select
            data={this.state}
            selectedArr='year'
            selectedValue='selectedYear'
            funcChange={this.handleChange.bind(this)}
          />

          <p>{this.converToString(this.state.selectedDayOfTheWeek)}</p>
        </div>
      );
    }
  }

  class Select extends React.Component {
    render() {
      return (
        <select
          value={this.props.data[this.props.selectedValue]}
          name={this.props.selectedValue}
          onChange={this.props.funcChange}
        >
          {this.props.data[this.props.selectedArr].map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Selects = ({ data, selectedArr, selectedValue, funcChange }) => {
    return (
      <select value={data[selectedValue]} name={selectedValue} onChange={funcChange}>
        {data[selectedArr].map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    );
  }

  const App = () => {
    const [dateValues, setDateValues] = useState({
      day: [],
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      year: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
      selectedDate: new Date().getDate(),
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      selectedDayOfTheWeek: new Date().getDay(),
    });

    const handleChange = ({ target: { name, value } }) =>
      setDateValues({ ...dateValues, [name]: Number(value) });

    const returnDays = () => {
      const currentDate = new Date(
        dateValues.selectedYear,
        dateValues.selectedMonth,
        0,
      ).getDate();

      const arr = [];

      for (let i = 1; i <= currentDate; i++) {
        arr.push(i);
      }

      return arr;
    };

    const converToString = (day) => {
      const newDate = new Date(
        dateValues.selectedYear,
        dateValues.selectedMonth - 1,
        dateValues.selectedDate,
      );
      const options = { weekday: 'long' };
      day = new Intl.DateTimeFormat('ru-Ru', options).format(newDate);
      return day;
    };

    return (
      <div>
        <Selects
          data={{ ...dateValues, day: returnDays() }}
          selectedArr="day"
          selectedValue="selectedDate"
          funcChange={handleChange}
        />

        <Selects
          data={dateValues}
          selectedArr="month"
          selectedValue="selectedMonth"
          funcChange={handleChange}
        />

        <Selects
          data={dateValues}
          selectedArr="year"
          selectedValue="selectedYear"
          funcChange={handleChange}
        />

        <p>{converToString(dateValues.selectedDayOfTheWeek)}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task13;
