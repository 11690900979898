import { randomInteger } from '../../utils/common';

const setDeck = (
  field: string[][],
  rowId: number,
  colId: number,
  orientation: number,
  deck: number
) => {
  for (let d = 0; d < deck; d++) {
    if (orientation === 0) {
      field[rowId][colId] = "X";
      rowId++;
    } else {
      field[rowId][colId] = "X";
      colId++;
    }
  }
};

const generateShipArray = (rowId: number, colId: number, field: string[][]) => {
  try {
    return field[rowId][colId];
  } catch (error) {
    return "";
  }
};

const checkIndex = (
  field: string[][],
  rowId: number,
  colId: number,
  deck: number,
  orientation: number
) => {
  let checkArray: string[][] = [
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
  ];

  if (orientation === 0) {
    for (let i = 0; i <= deck + 1; i++) {
      checkArray[i][0] = generateShipArray(rowId + i - 1, colId - 1, field);
      checkArray[i][1] = generateShipArray(rowId + i - 1, colId, field);
      checkArray[i][2] = generateShipArray(rowId + i - 1, colId + 1, field);
    }
  } else {
    for (let i = 0; i <= deck + 1; i++) {
      checkArray[0][i] = generateShipArray(rowId - 1, colId + i - 1, field);
      checkArray[1][i] = generateShipArray(rowId, colId + i - 1, field);
      checkArray[2][i] = generateShipArray(rowId + 1, colId + i - 1, field);
    }
  }

  for (let i = 0; i < checkArray.length; i++) {
    if (checkArray[i].indexOf("X") >= 0) return false;
  }

  return true;
};

const generateId = (field: string[][], deck: number, orientation: number) => {
  let rowId = 0;
  let colId = 0;

  do {
    rowId = randomInteger(0, 9);
    colId = randomInteger(0, 9);
  } while (
    field[rowId][colId] === "X" ||
    rowId + deck > 10 ||
    colId + deck > 10 ||
    checkIndex(field, rowId, colId, deck, orientation) === false
  );

  return [rowId, colId];
};

const placeShip = (field: string[][], ship: number, shipQuantity: number) => {
  for (let j = shipQuantity; j > 0; j--) {
    const orientation = randomInteger(0, 1);
    const index = generateId(field, ship, orientation);

    setDeck(field, index[0], index[1], orientation, ship);
  }
};

const fillingField = () => {
  const field = [
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", "", "", ""],
  ];
  const ships = [4, 3, 2, 1];

  for (let i = 1; i <= ships.length; i++) {
    placeShip(field, ships[i - 1], i);
  }

  return field;
};

export default fillingField;
