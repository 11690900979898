import React, { ChangeEvent, FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface CurrenciesProps {
  fromCurrencies: { key: string; name: string }[];
  cofs: { EUR: number; UAH: number; USD: number };
  currentFromCurrencie: string;
  currentToCurrencie: string;
  currentInputValue: '';
  convertedValue: null | number;
}

interface ListProps {
  data: CurrenciesProps;
  name: string;
  OnChangeFunc: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const List: FC<ListProps> = ({ data, name, OnChangeFunc }) => {
  return (
    <select value={data[name]} name={name} onChange={OnChangeFunc}>
      {data.fromCurrencies.map((item, index) => (
        <option key={index} value={item.key}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [currencies, setCurrencies] = useState<CurrenciesProps>({
    fromCurrencies: [
      { key: 'USD', name: 'Dollar' },
      { key: 'EUR', name: 'Euro' },
      { key: 'UAH', name: 'Hryvnia' },
    ],
    cofs: { EUR: 35, UAH: 1, USD: 29 },
    currentFromCurrencie: 'USD',
    currentToCurrencie: 'EUR',
    currentInputValue: '',
    convertedValue: null,
  });

  const handleChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCurrencies({ ...currencies, [name]: value });
  };

  const handleConvert = () => {
    const copyCurrencies = { ...currencies };

    if (copyCurrencies.currentFromCurrencie !== 'UAH') {
      copyCurrencies.convertedValue =
        Number(copyCurrencies.currentInputValue) *
        copyCurrencies.cofs[copyCurrencies.currentFromCurrencie];
      copyCurrencies.convertedValue =
        Math.ceil(
          (copyCurrencies.convertedValue / currencies.cofs[copyCurrencies.currentToCurrencie]) *
            100,
        ) / 100;
    } else {
      copyCurrencies.convertedValue =
        Math.ceil(
          (Number(copyCurrencies.currentInputValue) /
            currencies.cofs[copyCurrencies.currentToCurrencie]) *
            100,
        ) / 100;
    }

    setCurrencies(copyCurrencies);
  };

  return (
    <div>
      <input
        type="text"
        value={currencies.currentInputValue}
        name="currentInputValue"
        onChange={handleChange}
      />

      <span>From: </span>
      <List data={currencies} name="currentFromCurrencie" OnChangeFunc={handleChange} />

      <span>To: </span>
      <List data={currencies} name="currentToCurrencie" OnChangeFunc={handleChange} />

      <button onClick={handleConvert}>Конвертировать</button>

      <p>{currencies.convertedValue}</p>
    </div>
  );
};

const Task15: FC = () => {
  const taskTitle: string =
    '15. Реализуй калькулятор валют. Как он работает: дан инпут, в который вводится сумма и даны два селекта - в первом выбирается из какой валюты, а во втором - в какую. Дана также кнопка. По нажатию на эту кнопку в абзац должна вывестись сумма в выбранной валюте.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        currencies: {
          fromCurrencies: [
            { key: 'USD', name: 'Dollar' },
            { key: 'EUR', name: 'Euro' },
            { key: 'UAH', name: 'Hryvnia' },
          ],
          cofs: { EUR: 35, UAH: 1, USD: 29 },
          currentFromCurrencie: 'USD',
          currentToCurrencie: 'EUR',
          currentInputValue: '',
          convertedValue: null,
        },
      };
    }

    handleChange = ({ target: { value, name } }) => {
      this.setState({ currencies: { ...this.state.currencies, [name]: value } });
    };

    handleConvert() {
      const copyState = { ...this.state };

      if (copyState.currencies.currentFromCurrencie !== 'UAH') {
        copyState.currencies.convertedValue =
          Number(copyState.currencies.currentInputValue) *
          copyState.currencies.cofs[copyState.currencies.currentFromCurrencie];
        copyState.currencies.convertedValue =
          Math.ceil(
            (copyState.currencies.convertedValue /
              copyState.currencies.cofs[
                copyState.currencies.currentToCurrencie
              ]) *
              100
          ) / 100;
      } else {
        copyState.currencies.convertedValue =
          Math.ceil(
            (Number(copyState.currencies.currentInputValue) /
              copyState.currencies.cofs[
                copyState.currencies.currentToCurrencie
              ]) *
              100
          ) / 100;
      }

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <input
            type='text'
            value={this.state.currencies.currentInputValue}
            name='currentInputValue'
            onChange={(event) => this.handleChange(event)}
          />

          <span>From: </span>
          <List
            data={this.state.currencies}
            name='currentFromCurrencie'
            OnChangeFunc={this.handleChange.bind(this)}
          />

          <span>To: </span>
          <List
            data={this.state.currencies}
            name='currentToCurrencie'
            OnChangeFunc={this.handleChange.bind(this)}
          />

          <button onClick={() => this.handleConvert()}>Конвертировать</button>

          <p>{this.state.currencies.convertedValue}</p>
        </div>
      );
    }
  }

  class List extends React.Component {
    render() {
      return (
        <select
          value={this.props.data[this.props.name]}
          name={this.props.name}
          onChange={(event) => this.props.OnChangeFunc(event)}
        >
          {this.props.data.fromCurrencies.map((item, index) => (
            <option key={index} value={item.key}>
              {item.name}
            </option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ data, name, OnChangeFunc }) => {
    return (
      <select value={data[name]} name={name} onChange={OnChangeFunc}>
        {data.fromCurrencies.map((item, index) => (
          <option key={index} value={item.key}>
            {item.name}
          </option>
        ))}
      </select>
    );
  };

  const App = () => {
    const [currencies, setCurrencies] = useState({
      fromCurrencies: [
        { key: 'USD', name: 'Dollar' },
        { key: 'EUR', name: 'Euro' },
        { key: 'UAH', name: 'Hryvnia' },
      ],
      cofs: { EUR: 35, UAH: 1, USD: 29 },
      currentFromCurrencie: 'USD',
      currentToCurrencie: 'EUR',
      currentInputValue: '',
      convertedValue: null,
    });

    const handleChange = ({ target: { value, name } }) => {
      setCurrencies({ ...currencies, [name]: value });
    };

    const handleConvert = () => {
      const copyCurrencies = { ...currencies };

      if (copyCurrencies.currentFromCurrencie !== 'UAH') {
        copyCurrencies.convertedValue =
          Number(copyCurrencies.currentInputValue) *
          copyCurrencies.cofs[copyCurrencies.currentFromCurrencie];
        copyCurrencies.convertedValue =
          Math.ceil(
            (copyCurrencies.convertedValue / currencies.cofs[copyCurrencies.currentToCurrencie]) *
              100,
          ) / 100;
      } else {
        copyCurrencies.convertedValue =
          Math.ceil(
            (Number(copyCurrencies.currentInputValue) / currencies.cofs[copyCurrencies.currentToCurrencie]) *
              100,
          ) / 100;
      }

      setCurrencies(copyCurrencies);
    };

    return (
      <div>
        <input
          type="text"
          value={currencies.currentInputValue}
          name="currentInputValue"
          onChange={handleChange}
        />

        <span>From: </span>
        <List data={currencies} name="currentFromCurrencie" OnChangeFunc={handleChange} />

        <span>To: </span>
        <List data={currencies} name="currentToCurrencie" OnChangeFunc={handleChange} />

        <button onClick={handleConvert}>Конвертировать</button>

        <p>{currencies.convertedValue}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task15;
