import React, { useLayoutEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import Task11 from '../Lesson1/Task1';
import Task12 from '../Lesson1/Task2';
import Task13 from '../Lesson1/Task3';
import Task14 from '../Lesson1/Task4';
import Task15 from '../Lesson1/Task5';
import Task16 from '../Lesson1/Task6';
import Task17 from '../Lesson1/Task7';
import Task18 from '../Lesson1/Task8';
import Task19 from '../Lesson1/Task9';
import Task110 from '../Lesson1/Task10';
import Task111 from '../Lesson1/Task11';
import Task21 from '../Lesson2/Task1';
import Task22 from '../Lesson2/Task2';
import Task23 from '../Lesson2/Task3';
import Task24 from '../Lesson2/Task4';
import Task31 from '../Lesson3/Task1';
import Task32 from '../Lesson3/Task2';
import Task33 from '../Lesson3/Task3';
import Task34 from '../Lesson3/Task4';
import Task35 from '../Lesson3/Task5';
import Task36 from '../Lesson3/Task6';
import Task37 from '../Lesson3/Task7';
import Task41 from '../Lesson4/Task1';
import Task42 from '../Lesson4/Task2';
import Task43 from '../Lesson4/Task3';
import Task44 from '../Lesson4/Task4';
import Task51 from '../Lesson5/Task1';
import Task52 from '../Lesson5/Task2';
import Task53 from '../Lesson5/Task3';
import Task54 from '../Lesson5/Task4';
import Task55 from '../Lesson5/Task5';
import Task56 from '../Lesson5/Task6';
import Task57 from '../Lesson5/Task7';
import Task58 from '../Lesson5/Task8';
import Task59 from '../Lesson5/Task9';
import Task510 from '../Lesson5/Task10';
import Task511 from '../Lesson5/Task11';
import Task512 from '../Lesson5/Task12';
import Task61 from '../Lesson6/Task1';
import Task62 from '../Lesson6/Task2';
import Task63 from '../Lesson6/Task3';
import Task64 from '../Lesson6/Task4';
import Task65 from '../Lesson6/Task5';
import Task66 from '../Lesson6/Task6';
import Task67 from '../Lesson6/Task7';
import Task68 from '../Lesson6/Task8';
import Task69 from '../Lesson6/Task9';
import Task610 from '../Lesson6/Task10';
import Task611 from '../Lesson6/Task11';
import Task612 from '../Lesson6/Task12';
import Task613 from '../Lesson6/Task13';
import Task71 from '../Lesson7/Task1';
import Task72 from '../Lesson7/Task2';
import Task73 from '../Lesson7/Task3';
import Task74 from '../Lesson7/Task4';
import Task75 from '../Lesson7/Task5';
import Task76 from '../Lesson7/Task6';
import Task77 from '../Lesson7/Task7';
import Task78 from '../Lesson7/Task8';
import Task79 from '../Lesson7/Task9';
import Task710 from '../Lesson7/Task10';
import Task711 from '../Lesson7/Task11';
import Task712 from '../Lesson7/Task12';
import Task713 from '../Lesson7/Task13';
import Task714 from '../Lesson7/Task14';
import Task715 from '../Lesson7/Task15';
import Task716 from '../Lesson7/Task16';
import Task81 from '../Lesson8/Task1';
import Task91 from '../Lesson9/Task1';
import Task92 from '../Lesson9/Task2';
import Task93 from '../Lesson9/Task3';
import Task94 from '../Lesson9/Task4';
import Task95 from '../Lesson9/Task5';
import Task96 from '../Lesson9/Task6';
import Task97 from '../Lesson9/Task7';
import Task98 from '../Lesson9/Task8';
import Task99 from '../Lesson9/Task9';
import Task910 from '../Lesson9/Task10';
import Task101 from '../Lesson10/Task1';
import Task102 from '../Lesson10/Task2';
import Task103 from '../Lesson10/Task3';
import Task104 from '../Lesson10/Task4';
import Task105 from '../Lesson10/Task5';
import Task106 from '../Lesson10/Task6';
import Task107 from '../Lesson10/Task7';
import Task108 from '../Lesson10/Task8';
import Task109 from '../Lesson10/Task9';
import { appState } from '../../Context/secondClass';

const Middleware = () => {
  const history = useHistory();
  const items = history.location.pathname.split('/');

  useLayoutEffect(() => {
    appState.currentTask = { lesson: Number(items[2]), task: Number(items[4]) };

    // eslint-disable-next-line
  }, [appState.currentTask, history]);
  const GetTask = () => {
    const key = Number(String(Number(items[2])) + String(Number(items[4])));

    switch (key) {
      case 11:
        return <Task11 />;
      case 12:
        return <Task12 />;
      case 13:
        return <Task13 />;
      case 14:
        return <Task14 />;
      case 15:
        return <Task15 />;
      case 16:
        return <Task16 />;
      case 17:
        return <Task17 />;
      case 18:
        return <Task18 />;
      case 19:
        return <Task19 />;
      case 110:
        return <Task110 />;
      case 111:
        return <Task111 />;
      case 21:
        return <Task21 />;
      case 22:
        return <Task22 />;
      case 23:
        return <Task23 />;
      case 24:
        return <Task24 />;
      case 31:
        return <Task31 />;
      case 32:
        return <Task32 />;
      case 33:
        return <Task33 />;
      case 34:
        return <Task34 />;
      case 35:
        return <Task35 />;
      case 36:
        return <Task36 />;
      case 37:
        return <Task37 />;
      case 41:
        return <Task41 />;
      case 42:
        return <Task42 />;
      case 43:
        return <Task43 />;
      case 44:
        return <Task44 />;
      case 51:
        return <Task51 />;
      case 52:
        return <Task52 />;
      case 53:
        return <Task53 />;
      case 54:
        return <Task54 />;
      case 55:
        return <Task55 />;
      case 56:
        return <Task56 />;
      case 57:
        return <Task57 />;
      case 58:
        return <Task58 />;
      case 59:
        return <Task59 />;
      case 510:
        return <Task510 />;
      case 511:
        return <Task511 />;
      case 512:
        return <Task512 />;
      case 61:
        return <Task61 />;
      case 62:
        return <Task62 />;
      case 63:
        return <Task63 />;
      case 64:
        return <Task64 />;
      case 65:
        return <Task65 />;
      case 66:
        return <Task66 />;
      case 67:
        return <Task67 />;
      case 68:
        return <Task68 />;
      case 69:
        return <Task69 />;
      case 610:
        return <Task610 />;
      case 611:
        return <Task611 />;
      case 612:
        return <Task612 />;
      case 613:
        return <Task613 />;
      case 71:
        return <Task71 />;
      case 72:
        return <Task72 />;
      case 73:
        return <Task73 />;
      case 74:
        return <Task74 />;
      case 75:
        return <Task75 />;
      case 76:
        return <Task76 />;
      case 77:
        return <Task77 />;
      case 78:
        return <Task78 />;
      case 79:
        return <Task79 />;
      case 710:
        return <Task710 />;
      case 711:
        return <Task711 />;
      case 712:
        return <Task712 />;
      case 713:
        return <Task713 />;
      case 714:
        return <Task714 />;
      case 715:
        return <Task715 />;
      case 716:
        return <Task716 />;
      case 81:
        return <Task81 />;
      case 91:
        return <Task91 />;
      case 92:
        return <Task92 />;
      case 93:
        return <Task93 />;
      case 94:
        return <Task94 />;
      case 95:
        return <Task95 />;
      case 96:
        return <Task96 />;
      case 97:
        return <Task97 />;
      case 98:
        return <Task98 />;
      case 99:
        return <Task99 />;
      case 910:
        return <Task910 />;
      case 101:
        return <Task101 />;
      case 102:
        return <Task102 />;
      case 103:
        return <Task103 />;
      case 104:
        return <Task104 />;
      case 105:
        return <Task105 />;
      case 106:
        return <Task106 />;
      case 107:
        return <Task107 />;
      case 108:
        return <Task108 />;
      case 109:
        return <Task109 />;
      default:
        return null;
    }
  };

  return <GetTask />;
};

export default withRouter(Middleware);
