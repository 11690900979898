import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'antd';
import { storageService } from '../../../services/StorageService';
import { useGetUserInfoQuery, useSaveTaskMutation } from '../../../redux';

import useStyles from './style';

const SaveChecked: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [saveTask] = useSaveTaskMutation();
  const userID = storageService.get<string>('user-id');
  const { data } = useGetUserInfoQuery(userID);
  const [isSaved, setIsSaved] = useState(false);
  const [currentTask, setCurrentTask] = useState<CurrentTaskProps>({
    task: 0,
    lesson: 0,
  });

  const getTaskInfo = async (lesson: number, task: number) => {
    setIsSaved(data?.lessons[lesson - 1].tasks[task - 1][task]);
  };

  const handleSaveClick = async () => {
    userID &&
      (await saveTask({
        task: currentTask.task,
        lesson: currentTask.lesson,
        userID,
      }));

    setIsSaved(!isSaved);
  };

  useEffect(() => {
    const items = history.location.pathname.split('/');

    setCurrentTask({ task: Number(items[4]), lesson: Number(items[2]) });

    getTaskInfo(Number(items[2]), Number(items[4]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, data]);

  return (
    <Checkbox
      className={classes.root}
      type="primary"
      checked={isSaved}
      onClick={() => handleSaveClick()}
    />
  );
};

export default SaveChecked;
