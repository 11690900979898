import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const text1 = <p>текст1</p>;
  const text2 = <p>текст2</p>;
  return (
    <div>
      {text1}
      <p>!!!</p>
      {text2}
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string =
    "4. Пусть в компоненте есть переменная text1 с текстом '<p>текст1</p>' и переменная text2 с текстом '<p>текст2</p>'. С их помощью выведи следующее:";
  const taskCode: string = `
  <div>
    <p>текст1</p>
    <p>!!!</p>
    <p>текст2</p>
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const text1 = <p>текст1</p>;
      const text2 = <p>текст2</p>;

      return (
        <div>
          {text1}
          <p>!!!</p>
          {text2}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const text1 = <p>текст1</p>;
    const text2 = <p>текст2</p>;

    return (
      <div>
        {text1}
        <p>!!!</p>
        {text2}
      </div>
    );
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task4;
