import React, { FC, useRef, useState } from 'react';
import ComponentTemplate from '../../Task';

interface List {
  data: Array<string>;
}

const List: FC<List> = ({ data }) => {
  return (
    <ul>
      {data.map((item: string, index: number) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  let newUser: React.MutableRefObject<any> = useRef(null);
  const [names, setNames] = useState(['Александр', 'Давид', 'Максим']);
  const handleClick = () => {
    const copyNames = [...names];
    copyNames.push(newUser.current.value);
    newUser.current.value = '';
    setNames(copyNames);
  };

  return (
    <div>
      <List data={names} />
      <input type="text" ref={newUser} />
      <button onClick={handleClick}>Click on me</button>
    </div>
  );
};

const Task8: FC = () => {
  const taskTitle: string =
    '8. Дан массив с именами пользователей. Выведите этот массив в виде списка ul. ' +
    'Также даны инпут и кнопка. Сделайте так, чтобы в инпут можно было ввести еще одно имя, нажать на кнопку - и это имя добавилось в конец списка ul.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {items: ['Александр', 'Давид', 'Максим'], value: ''};
    }
  
    handleChange(event){
      this.setState({value:event.target.value})
    }
  
    addName(){
      this.state.items.push(this.state.value);
      this.setState({items: this.state.items, value:''})
    }
  
    render() {
      const listNames= this.state.items.map((value, index)=>{
        return <li key={index}>{value}</li>
      });
  
      return (
        <div>
          <input
            type="text"
            placeholder="Введите имя"
            value={this.state.value}
            onChange={this.handleChange.bind(this)}
          />
          <button onClick={this.addName.bind(this)}>Нажми на меня</button>
          <ul>{listNames}</ul>
        </div>
      );
    }
  }
  `;

  const taskFuncSolution: string = `
  const List = ({ data }) => {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const App = () => {
    let newUser = useRef(null);
    const [names, setNames] = useState(['Александр', 'Давид', 'Максим']);
    const handleClick = () => {
      const copyNames = [...names];
      copyNames.push(newUser.current.value);
      newUser.current.value = '';
      setNames(copyNames);
    };

    return (
      <div>
        <List data={names} />
        <input type="text" ref={newUser} />
        <button onClick={handleClick}>нажми на меня</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task8;
