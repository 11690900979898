import React, { FC } from 'react';
import ComponentTemplate from '../../Task';

interface UsersProps {
  data: {
    id: number;
    lastName: string;
    firstName: string;
    age: number;
  }[];
}

const Users: FC<UsersProps> = ({ data }) => (
  <table width="100%">
    <thead>
      <tr>
        <td>id</td>
        <td>firstName</td>
        <td>lastName</td>
        <td>age</td>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.id}</td>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.age}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const users = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 54 },
    { id: 6, lastName: 'Melisandre', firstName: 'Anthony', age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  return <Users data={users} />;
};

const Task1: FC = () => {
  const taskTitle: string =
    '1. Пусть в стейте хранится массив с юзерами. У каждого юзера есть имя, фамилия и возраст. Выведи всех юзеров на экран в виде таблицы. Пусть отображением юзера на экран занимается отдельный компонент.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task1;
