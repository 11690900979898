import React from 'react';
import { withRouter } from 'react-router-dom';
import Routes from '../../router';
import CustomHeader from '../Header';
import CustomFooter from '../Footer';
import HomeFooter from '../HomeFooter';
import { Layout } from 'antd';
import useStyle from './style';

const MiddleWare = (props: any) => {
  const classes = useStyle();
  const isMain = props.location.pathname === '/';

  return (
    <Layout className={` layout ${!isMain && 'main-background'}`}>
      {!isMain && <CustomHeader />}

      <div className={isMain ? classes.root : classes.rootHome}>
        <Routes />
      </div>

      {isMain ? <HomeFooter /> : <CustomFooter />}
    </Layout>
  );
};

export default withRouter(MiddleWare);
