import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../../utils/constants';

const params = {
  baseColor: PALITRA.white,
  hoverColor: PALITRA.violet.violet,
  bgColor: PALITRA.menuBackground,
};

const useStyles = createUseStyles({
  root: {
    backgroundColor: PALITRA.menuBackground,

    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '-1',
    top: '0',
    left: '0',
    textAlign: 'center',
    overflow: 'auto',

    '&.ant-select-arrow': {
      color: 'white',
    },

    '&.collapsed': {
      maxHeight: '0px',
      opacity: '0',
      transitionDuration: '0.4s',
      transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
    },

    '&.selectLanguage': {
      textAlign: 'center',
      marginBottom: '15px',
    },

    '&.dropped': {
      minHeight: '100%',
      transitionDuration: '0.4s',
      transitionTimingFunction: 'ease-in',

      '& .list-items': {
        '& li > a > img': {
          marginBottom: '5px',
        },
      },
    },

    '& .list-items': {
      listStyle: 'none',
      paddingLeft: '0',
    },

    '& li': {
      fontSize: '25px',
      lineHeight: '63px',
      color: PALITRA.white,

      '&.lessons__item': {
        '& span': {
          display: 'inline-block',

          '&.drop__icon': {
            marginRight: '5px',
            transform: 'rotate(90deg)',
            transition: 'transform 0.2s linear',

            '&.dropped': {
              transform: 'rotate(270deg)',
            },
          },
        },
      },

      '& a, span': {
        userSelect: 'none',
        display: 'block',
        position: 'relative',
        color: PALITRA.white,
        backgroundImage: `linear-gradient(
              90deg,
              ${params.hoverColor} 0%,
              ${params.hoverColor} 50%,
              ${params.hoverColor} 50%,
              ${params.hoverColor} 100%
            )`,
        backgroundRepeat: 'repeat',
        backgroundSize: '200%',
        backgroundPosition: '100% 0',
        WebkitBackgroundClip: 'text',
        transition: 'background-position 300ms',
      },

      '&::before': {
        content: "''",
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '64px',
        borderBottom: `3px solid ${PALITRA.crimson}`,
        transformOrigin: '100% 0',
        transform: 'scale3d(0, 1, 1)',
        transition: 'transform 300ms',
      },

      '&:hover:not([class="logo__item"])': {
        '& a': {
          backgroundPosition: '0 0',
          color: params.baseColor,
        },

        '&::before': {
          transformOrigin: '0 0',
          transform: 'scale3d(1, 1, 1)',
        },
      },
    },

    '@media (max-width: 900px)': {
      '&.dropped': {
        opacity: '1',
        pointerEvents: 'all',
        zIndex: '1000',

        '& li': {
          cursor: 'pointer',
        },
      },
    },
  },
});

export default useStyles;
