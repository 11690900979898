import React, { FC } from 'react';

const WepAppIcon: FC<IconProps> = ({ ...props }) => (
  <svg {...props} id="Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.5,79H15V36H80v6.46a1.5,1.5,0,0,0,3,0v-22A1.5,1.5,0,0,0,81.5,19h-68A1.5,1.5,0,0,0,12,20.5v60A1.5,1.5,0,0,0,13.5,82h68a1.5,1.5,0,0,0,0-3ZM80,22V33H15V22Z" />
    <path d="M20,29h6a1.5,1.5,0,0,0,0-3H20a1.5,1.5,0,0,0,0,3Z" />
    <path d="M34,29h6a1.5,1.5,0,0,0,0-3H34a1.5,1.5,0,0,0,0,3Z" />
    <path d="M97.88,61.56l0-.13a1.47,1.47,0,0,0,0-.15s0-.09,0-.14,0-.1,0-.15,0-.1,0-.15,0-.09,0-.14a1.47,1.47,0,0,0,0-.15l0-.13-.06-.14-.08-.13-.05-.09C97.28,59.55,87.52,47,74.88,47,61,47,50.76,59.53,50.33,60.06l-.08.12-.08.12a1.47,1.47,0,0,0-.06.15l0,.12a1.48,1.48,0,0,0,0,.16s0,.08,0,.13,0,.1,0,.15,0,.1,0,.15,0,.08,0,.13a1.48,1.48,0,0,0,0,.16l0,.12a1.47,1.47,0,0,0,.06.15l.08.12.08.12C50.76,62.47,61,75,74.88,75c12.64,0,22.4-12.55,22.81-13.09l.05-.09.08-.13ZM74.88,72c-10.37,0-18.8-8.19-21.39-11,2.59-2.81,11-11,21.39-11,9.31,0,17.24,8.22,19.66,11C92.13,63.78,84.19,72,74.88,72Z" />
    <path d="M74,52.5A8.5,8.5,0,1,0,82.5,61,8.51,8.51,0,0,0,74,52.5Zm0,14A5.5,5.5,0,1,1,79.5,61,5.51,5.51,0,0,1,74,66.5Z" />
  </svg>
);

export default WepAppIcon;
