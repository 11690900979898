import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const state = {
    hrefs: [
      { href: '1.html', text: 'ссылка 1' },
      { href: '2.html', text: 'ссылка 2' },
      { href: '3.html', text: 'ссылка 3' },
    ],
  };

  const list = state.hrefs.map((item, index) => (
    <li key={index}>
      <a href={item.href}>{item.text}</a>
    </li>
  ));

  return <ul>{list}</ul>;
};

const Task7: FC = () => {
  const taskTitle: string = '7. Дан следующий объект:';

  const taskCode: string = `
  const state = {
    hrefs: [
      {href: '1.html', text: 'ссылка 1'},
      {href: '2.html', text: 'ссылка 2'},
      {href: '3.html', text: 'ссылка 3'},
    ]
  };
  `;

  const taskTitle2: string = `С помощью state.hrefs выведи на экран следующее:`;

  const taskCode2: string = `
  <ul>
    <li><a href="1.html">ссылка 1</a></li>
    <li><a href="2.html">ссылка 2</a></li>
    <li><a href="3.html">ссылка 3</a></li>
  </ul>
  `;

  const taskClassSolution: string = `
  class App extends React.Component {
    state = {
      hrefs: [
        { href: '1.html', text: 'ссылка 1' },
        { href: '2.html', text: 'ссылка 2' },
        { href: '3.html', text: 'ссылка 3' },
      ],
    };

    render() {
      const list = this.state.hrefs.map((item, index) => (
        <li key={index}>
          <a href={item.href}>{item.text}</a>
        </li>
      ));

      return <ul>{list}</ul>;
    }
  }
  `;

  const taskFuncSolution: string = `
  const App = () => {
    const state = {
      hrefs: [
        { href: '1.html', text: 'ссылка 1' },
        { href: '2.html', text: 'ссылка 2' },
        { href: '3.html', text: 'ссылка 3' },
      ],
    };

    const list = state.hrefs.map((item, index) => (
      <li key={index}>
        <a href={item.href}>{item.text}</a>
      </li>
    ));

    return <ul>{list}</ul>;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
      />
    </div>
  );
};
export default Task7;
