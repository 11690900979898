import { Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { languageFunc } from '../../utils/constants';
import { appState } from '../../Context/secondClass';
import { useStyles } from './style';

interface PropsColor {
  styleForSelect: { bg: string; colorText: string };
}

const SelectLanguage: FC<PropsColor> = ({ styleForSelect }) => {
  const classes = useStyles({ theme: { styleForSelect } });

  const { t } = useTranslation();
  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
    appState.selectLanguage = value;
  };

  return (
    <div className={classes.root}>
      <Select
        defaultValue={t('defaultMenu.en')}
        value={appState.selectLanguage}
        onChange={handleChange}
        options={languageFunc(t)}
        popupClassName={classes.select}
      />
    </div>
  );
};

export { SelectLanguage };
