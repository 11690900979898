import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface TableProps {
  data: {
    firstName: string;
    secondName: string;
    salary: number;
    checked: boolean;
  }[];
  func: (index: number) => void;
}

const Table: FC<TableProps> = ({ data, func }) => (
  <table>
    <tbody>
      {data.map((item, index) => (
        <tr>
          <td>
            <input
              key={index}
              type="checkbox"
              checked={item.checked}
              onChange={() => func(index)}
            />
          </td>
          <td width="15%">{item.firstName}</td>
          <td width="20%">{item.secondName}</td>
          <td width="10%">{item.salary}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [work, setWork] = useState({
    workers: [
      { firstName: 'John', secondName: 'Robertson', salary: 100, checked: true },
      { firstName: 'David', secondName: 'Dubliakov', salary: 200, checked: true },
      { firstName: 'Ben', secondName: 'Smith', salary: 300, checked: true },
    ],
    salarySum: 600,
  });

  const handleCheckboxChange = (index: number) => {
    const copyWork = { ...work };

    copyWork.workers[index].checked = !copyWork.workers[index].checked;
    copyWork.workers[index].checked === true
      ? (copyWork.salarySum += copyWork.workers[index].salary)
      : (copyWork.salarySum -= copyWork.workers[index].salary);

    setWork(copyWork);
  };

  return (
    <div>
      <Table data={work.workers} func={handleCheckboxChange} />
      <p>{work.salarySum}</p>
    </div>
  );
};

const Task2: FC = () => {
  const taskTitle: string =
    '2. Дан массив с работниками. У каждого работника есть имя, фамилия и зарплата. Выведи этих работников на экран в виде таблицы. Для каждого работника сделай чекбокс, который изначально будет отмечен. Под таблицей выведите сумму зарплат тех работников, для которых чекбокс отмечен.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        workers: [
          {
            firstName: 'John',
            secondName: 'Robertson',
            salary: 100,
            checked: true,
          },
          {
            firstName: 'David',
            secondName: 'Dubliakov',
            salary: 200,
            checked: true,
          },
          { firstName: 'Ben', secondName: 'Smith', salary: 300, checked: true },
        ],
        salarySum: 600,
      };
    }

    handleCheckboxChange(index) {
      const copyWork = { ...this.state };

      copyWork.workers[index].checked = !copyWork.workers[index].checked;
      copyWork.workers[index].checked === true
        ? (copyWork.salarySum += copyWork.workers[index].salary)
        : (copyWork.salarySum -= copyWork.workers[index].salary);

      this.setState(copyWork);
    }

    render() {
      return (
        <div>
          <Table
            data={this.state.workers}
            func={this.handleCheckboxChange.bind(this)}
          />
          <p>{this.state.salarySum}</p>
        </div>
      );
    }
  }

  class Table extends React.Component {
    render() {
      return (
        <table>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr>
                <td>
                  <input
                    key={index}
                    type='checkbox'
                    checked={item.checked}
                    onChange={() => this.props.func(index)}
                  />
                </td>
                <td width='15%'>{item.firstName}</td>
                <td width='20%'>{item.secondName}</td>
                <td width='10%'>{item.salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Table = ({ data, func }) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr>
            <td>
              <input
                key={index}
                type="checkbox"
                checked={item.checked}
                onChange={() => func(index)}
              />
            </td>
            <td width="15%">{item.firstName}</td>
            <td width="20%">{item.secondName}</td>
            <td width="10%">{item.salary}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const TaskReady = () => {
    const [work, setWork] = useState({
      workers: [
        { firstName: 'John', secondName: 'Robertson', salary: 100, checked: true },
        { firstName: 'David', secondName: 'Dubliakov', salary: 200, checked: true },
        { firstName: 'Ben', secondName: 'Smith', salary: 300, checked: true },
      ],
      salarySum: 600,
    });

    const handleCheckboxChange = (index) => {
      const copyWork = { ...work };

      copyWork.workers[index].checked = !copyWork.workers[index].checked;
      copyWork.workers[index].checked === true
        ? (copyWork.salarySum += copyWork.workers[index].salary)
        : (copyWork.salarySum -= copyWork.workers[index].salary);

      setWork(copyWork);
    };

    return (
      <div>
        <Table data={work.workers} func={handleCheckboxChange} />
        <p>{work.salarySum}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task2;
