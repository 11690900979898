import React, { FC } from 'react';

import LessonListTemplate from '../LessonListTemplate';
import Task1 from './Task1';
import Task2 from './Task2';
import Task3 from './Task3';
import Task4 from './Task4';

const tasks = [<Task1 />, <Task2 />, <Task3 />, <Task4 />];

export const tasksQuantity4: number = tasks.length;

const LessonList: FC = () => <LessonListTemplate tasks={tasks} lesson="4" />;

export default LessonList;
