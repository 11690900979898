import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '& .contact-wrap': {
      maxWidth: '640px',
      margin: '0 auto',

      '& .contact-text': {
        fontSize: '14px',
        marginBottom: '20px',
        color: PALITRA.black,
        fontWeight: '600',
      },

      '& .contact-subtitle': {
        fontSize: '16px',
        fontWeight: '300',
        marginBottom: '30px',
      },

      '& .contact-icon': {
        width: '47px',
        height: '47px',
        margin: '0 auto',
        cursor: 'pointer',

        '& .contact-icn': {
          fill: PALITRA.blue.blueGrey,
        },
      },
    },
  },
});

export default useStyles;
