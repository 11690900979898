import React, { FC } from 'react';
import { Modal as AntModal } from 'antd';
import useStyle from './style';

type Props = {
  isModalOpen: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  width?: string;
};

const Modal: FC<Props> = ({ isModalOpen, handleOk, handleCancel, children, width = '1000px' }) => {
  const classNames = useStyle();

  return (
    <AntModal
      wrapClassName={classNames.root}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      mask
      centered
      width={width}>
      <div className="modal-body">{children}</div>
    </AntModal>
  );
};

export default Modal;
