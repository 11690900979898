import { createUseStyles } from 'react-jss';

import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    color: PALITRA.white,
    backgroundColor: PALITRA.headerAndFooter,
    height: '56px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.taskFooter': {
      position: 'absolute',
      bottom: '-7px',
    },
  },
});

export default useStyles;
