import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { errorTitle, success, wrongEmail } from '../../utils/constants';
import { useRegisterUserMutation } from '../../redux';
import { Loader } from '../Loader';

import useStyles from './style';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { md: { span: 10 }, sm: { span: 24 }, xs: { span: 24 } },
};

const tailLayout = {
  wrapperCol: {
    md: { offset: 7, span: 10 },
    sm: { offset: 7, span: 24 },
    xs: { offset: 0, span: 24 },
  },
};

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [registerUser, { data, error, isLoading }] = useRegisterUserMutation();

  useEffect(() => {
    if (!data) {
      return;
    }
    notification.success({
      message: success,
      duration: 1.5,
    });
    history.push('/login');
  }, [data, history]);

  useEffect(() => {
    error &&
      notification.error({
        message: errorTitle,
        description: wrongEmail,
        duration: 2.5,
      });
  }, [error]);

  const onFinish = async (values: any) => {
    await registerUser({ ...values });
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loader />
      ) : (
        <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                pattern: /^[a-zа-яё]+$/i,
                message: 'Please input your first name!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                pattern: /^[a-zа-яё]+$/i,
                message: 'Please input your last name!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                message: 'Please input your email!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="pass"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}>
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Git repository url"
            name="repositoriesUrl"
            rules={[
              {
                required: true,
                pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                message: 'Please input your git repository URL!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Sign up
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SignUp;
