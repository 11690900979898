import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [value, setValue] = useState<string>('');

  const handleEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <p>{value.toUpperCase()}</p>
      <input value={value} onChange={handleEvent} />
    </div>
  );
};

const Task2: FC = () => {
  const taskTitle: string =
    '2.Модифицируй предыдущую задачу так, чтобы текст в абзац выводился в верхнем регистре.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { value: '' };

    handleEvent(event) {
      this.setState({ value: event.target.value });
    }

    render() {
      return (
        <div>
          <p>{this.state.value.toUpperCase()}</p>
          <input value={this.state.value} onChange={this.handleEvent.bind(this)} />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [value, setValue] = useState('');

    const handleEvent = (event) => {
      setValue(event.target.value);
    };

    return (
      <div>
        <p>{value.toUpperCase()}</p>
        <input value={value} onChange={handleEvent} />
      </div>
    );
    };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task2;
