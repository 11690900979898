import React, { FC } from 'react';

const Adventage1: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    id="Слой_2"
    data-name="Слой 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100">
    <title>Монтажная область 18</title>
    <path d="M87.5,59H80V27.5A1.5,1.5,0,0,0,78.5,26H67V11.5A1.5,1.5,0,0,0,65.5,10h-31A1.5,1.5,0,0,0,33,11.5V26H21.5A1.5,1.5,0,0,0,20,27.5V59H12.5A1.5,1.5,0,0,0,11,60.5v19A1.5,1.5,0,0,0,12.5,81h19A1.5,1.5,0,0,0,33,79.5v-19A1.5,1.5,0,0,0,31.5,59H23V29H33V42.5A1.5,1.5,0,0,0,34.5,44H49V59H40.5A1.5,1.5,0,0,0,39,60.5v19A1.5,1.5,0,0,0,40.5,81h19A1.5,1.5,0,0,0,61,79.5v-19A1.5,1.5,0,0,0,59.5,59H52V44H65.5A1.5,1.5,0,0,0,67,42.5V29H77V59H68.5A1.5,1.5,0,0,0,67,60.5v19A1.5,1.5,0,0,0,68.5,81h19A1.5,1.5,0,0,0,89,79.5v-19A1.5,1.5,0,0,0,87.5,59ZM30,78H14V62H30Zm28,0H42V62H58Zm6-37H36V13H64ZM86,78H70V62H86Z" />
  </svg>
);

export default Adventage1;
