import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

  const showText = () => {
    setState({ ...state, show: true });
  };

  const text = (
    <p>
      имя: {state.name}, возраст: {state.age}
    </p>
  );

  return (
    <div>
      {state.show && text}
      <button onClick={showText}>Нажми на меня</button>
    </div>
  );
};

const Task2: FC = () => {
  const taskTitle: string = '2. Дан следующий стейт:';
  const taskCode: string = `
  const [state, setState] = useState({name: 'Иван', age: 25, show: false});
  `;
  const taskTitle2: string = `Дана кнопка (результат рендеринга компонента):`;
  const taskCode2: string = `
  <div>
    <button>Нажми на меня</button>
  </div>
  `;
  const taskTitle3: string =
    "Сделай так, чтобы по нажатию на кнопку компонент отрендерил следующее ('Иван' и 25 должны взяться из стейта):";
  const taskCode3: string = `
  <div>
    <p>имя: Иван, возраст: 25</p>
    <button>Нажми на меня</button>
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25, show: false };

    showText() {
      this.setState({ show: true });
    }

    render() {
      const text = (
        <p>
          имя: {this.state.name}, возраст: {this.state.age}
        </p>
      );

      return (
        <div>
          {this.state.show && text}
          <button onClick={() => this.showText()}>Нажми на меня</button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

    const showText = () => {
      setState({ ...state, show: true });
    };

    const text = (
      <p>
        имя: {state.name}, возраст: {state.age}
      </p>
    );

    return (
      <div>
        {state.show && text}
        <button onClick={showText}>Нажми на меня</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
        taskTitle3={taskTitle3}
        taskCode3={taskCode3}
      />
    </div>
  );
};
export default Task2;
