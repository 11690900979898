import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { HashRouter as Router } from 'react-router-dom';

import MiddleWare from './Components/AnotherFooter';
import { authService } from './services/AuthService';
import { storageService } from './services/StorageService';
import useStyles from './style';

const App = () => {
  const classes = useStyles();
  const onIdle = () => {
    if (storageService.get('access_token')) {
      authService.logoutUser();
      window.location.href = '/login';
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 7200000,
    crossTab: true,
    startOnMount: true,
  });

  return (
    <div className={classes.root}>
      <Router>
        <MiddleWare />
      </Router>
    </div>
  );
};

export default App;
