import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const getText1 = () => 1;
  const getText2 = () => 2;

  return <div>текст {getText1() + getText2()}</div>;
};

const Task11: FC = () => {
  const taskTitle: string =
    '11. Пусть компонент App имеет функцию getNum1(), которая своим результатом возвращает число 1 и функция getNum2(), которая своим результатом возвращает число 2. Используя эти функциии выведите на экран сумму результатов этих функций (3 - результат сложения getNum1() и getNum2()):';
  const taskCode: string = `
  <div>
    текст 3
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    getText1() {
      return 1;
    }

    getText2() {
      return 2;
    }

    render() {
      return(
        <div>
          текст {this.getText1() + this.getText2()}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const getText1 = () => 1;
    const getText2 = () => 2;

    return <div>текст {getText1() + getText2()} </div>
    );
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task11;
