import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const date: number = new Date().getFullYear();
  const [value, setValue] = useState<string | undefined>('');

  const handleEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const res = event.target.value.match(/[0-9]/);

    if (Number(event.target.value) <= date) {
      setValue(res ? res.input : '');
    }
  };

  const year = date - Number(value);

  return (
    <div>
      {value && <p>{year}</p>}
      <input type="text" value={value} onChange={handleEvent} />
    </div>
  );
};

const Task3: FC = () => {
  const taskTitle: string =
    '3.Дан инпут и абзац. В инпут вводится возраст пользователя. Сделай так, чтобы при наборе текста в абзаце автоматически появлялся год рождения пользователя.';
  const taskClassSolution: string = `
  class App extends React.Component {
    date = new Date().getFullYear();
    state = { value: '' };

    handleEvent(event) {
      const value = event.target.value.match(/[0-9]/);

      if (Number(event.target.value) <= this.date) {
        this.setState({ value: value ? value.input : '' });
      }
    }

    render() {
      const value = this.date - Number(this.state.value);

      return (
        <div>
          {this.state.value && <p>{value}</p>}
          <input type="text" value={this.state.value} onChange={this.handleEvent.bind(this)} />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const date = new Date().getFullYear();
    const [value, setValue] = useState('');

    const handleEvent = (event) => {
      const res = event.target.value.match(/[0-9]/);

      if (Number(event.target.value) <= date) {
        setValue(res ? res.input : '');
      }
    };

    const year = date - Number(value);

    return (
      <div>
        {value && <p>{year}</p>}
        <input type="text" value={value} onChange={handleEvent} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task3;
