import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface UsersProps {
  data: {
    id: number;
    lastName: string;
    firstName: string;
    age: number;
    link: string;
  }[];
  deleteMetod: (id: number) => void;
}

const Users: FC<UsersProps> = ({ data, deleteMetod }) => (
  <table width="100%">
    <thead>
      <tr>
        <td>id</td>
        <td>firstName</td>
        <td>lastName</td>
        <td>age</td>
        <td>link</td>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.id}</td>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.age}</td>
          <td onClick={() => deleteMetod(index)}>{item.link}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [users, setUsers] = useState([
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35, link: '1.html' },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42, link: '2.html' },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45, link: '3.html' },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16, link: '4.html' },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 54, link: '5.html' },
    { id: 6, lastName: 'Melisandre', firstName: 'Anthony', age: 150, link: '6.html' },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, link: '7.html' },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, link: '8.html' },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, link: '9.html' },
  ]);

  const deleteUser = (id: number) => {
    const copyUsers = [...users];
    copyUsers.splice(id, 1);
    setUsers(copyUsers);
  };

  return <Users data={users} deleteMetod={deleteUser} />;
};

const Task5: FC = () => {
  const taskTitle: string =
    '5. Модифицируй предыдущую задачу. Поменяй showMessage на deleteUser и сделай так, чтобы по клику на ссылку юзер удалялся из таблицы.';
  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task5;
