import React, { FC, useEffect } from 'react';

import { ConfigProvider, Form, Typography } from 'antd';
import AnimatedButton from '../../Buttons/AnimatedButton';
import AnimatedInput from '../../Inputs/AnimatedInput';
import AnimatedTextArea from '../../Inputs/AnimatedTextArea';

import { PALITRA } from '../../../utils/constants';
import useStyle from './style';

const { Title } = Typography;

type Props = {
  handleCancel: () => void;
  handleOk: () => void;
};

const FeedbackForm: FC<Props> = ({ handleCancel, handleOk }) => {
  const classNames = useStyle();
  const [form] = Form.useForm();

  const validateMessages = {
    // eslint-disable-next-line
    required: 'Please input your ${name}!',
    types: {
      // eslint-disable-next-line
      email: 'Is not a valid ${label}!',
    },
  };

  const onFinish = (values: any) => {
    form.resetFields();
    handleOk();
  };

  const onFinishFailed = (errorInfo: any) => {
    //console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, handleCancel]);

  return (
    <div className={classNames.root}>
      <Title level={1}>Let`s do this!</Title>
      <ConfigProvider form={{ validateMessages }}>
        <Form
          form={form}
          size="large"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <div className="horisontal">
            <Form.Item name="name" rules={[{ required: true }]} className="horisontal-item">
              <AnimatedInput
                placeholder="Name"
                colors={{ main: PALITRA.red.redPink, secondary: PALITRA.yellow }}
                animName={'inputAnim'}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, type: 'email' }]}
              className="horisontal-item">
              <AnimatedInput
                placeholder="Email"
                colors={{ main: PALITRA.red.redPink, secondary: PALITRA.yellow }}
                animName={'inputAnim'}
              />
            </Form.Item>
          </div>
          <Form.Item name="message">
            <AnimatedTextArea
              placeholder="Message"
              autoSize={{ minRows: 4 }}
              size="large"
              colors={{ main: PALITRA.red.redPink, secondary: PALITRA.yellow }}
              animName={'inputAnim'}
            />
          </Form.Item>
          <Form.Item className="form-buttons">
            <AnimatedButton
              animName="submitAnim"
              content="Submit"
              htmlType="submit"
              colors={{ main: PALITRA.red.redPink, secondary: PALITRA.yellow }}
            />
            <AnimatedButton
              animName="cancelAnim"
              content="Cancel"
              size="large"
              onClick={handleCancel}
              colors={{ main: PALITRA.yellow, secondary: PALITRA.red.redPink }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default FeedbackForm;
