import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import GeneralSecton from '../../Components/BodyHome';
import { Section1To4 } from '../../Components/Section0';
import { Section5To7 } from '../../Components/Section1';
import { Section8To9 } from '../../Components/Section2';
import HomeLogics from '../../Components/HomeLogics';
import useStyle from './style';
import Logo from '../../Components/Logo';

const Home: FC = () => {
  const classNames = useStyle();

  useEffect(() => {
    HomeLogics();
  }, []);

  return (
    <div className={classNames.root}>
      <Helmet>
        <meta name="description" content="Web site created for learning React" />
        <title>UniCode website</title>
      </Helmet>
      <Logo />
      <GeneralSecton />
      <main className="space" id="space">
        <div className="space__control" id="scene">
          <section className="space__bubbles" id="bubbles">
            <span id="bubble-unicorn" className="space__bubble-unicorn"></span>
          </section>
          <Section1To4 />
          <Section5To7 />
          <Section8To9 />
        </div>
      </main>
    </div>
  );
};

export default Home;
