import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import classNames from 'classnames';
import TaskNavigation from '../TaskNavigation';
import SaveCheckbox from './SaveCheckbox';
import { appState } from '../../Context/secondClass';
import useStyles from './style';

const Breadcrumbs: FC<RouteComponentProps> = ({ location }) => {
  const classes = useStyles();

  const items: string[] = location.pathname.split('/');

  return (
    <div className={classNames(classes.root, 'breadcrumbs-section')}>
      <Breadcrumb>
        <Breadcrumb.Item>
          {items[2] && <span className="bread-crumbs__title">Lesson {items[2]}</span>}
          {items[4] && <span className="bread-crumbs__sub-title"> / Task {items[4]}</span>}
        </Breadcrumb.Item>
      </Breadcrumb>
      {items[4] && !appState.screenSizeDesktop && <TaskNavigation />}
      {items[4] && <SaveCheckbox />}
    </div>
  );
};

export default withRouter(Breadcrumbs);
