import React, { ChangeEvent, FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface TableStringsProps {
  data: {
    firstName: string;
    secondName: string;
    salary: string;
    gender: string;
  }[];
}

const TableStrings: FC<TableStringsProps> = ({ data }) => (
  <table>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td width="15%">{item.firstName}</td>
          <td width="20%">{item.secondName}</td>
          <td width="10%">{item.salary}</td>
          <td width="10%">{item.gender}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [employees, setEmployees] = useState({
    employeesList: [
      { firstName: 'John', secondName: 'Robertson', salary: '100', gender: 'Male' },
      { firstName: 'David', secondName: 'Dubliakov', salary: '200', gender: 'Male' },
      { firstName: 'Ben', secondName: 'Smith', salary: '300', gender: 'Male' },
    ],
    newEmployee: { firstName: '', secondName: '', salary: '', gender: 'Male' },
  });

  const handleChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setEmployees({
      ...employees,
      newEmployee: {
        ...employees.newEmployee,
        [name]: value,
      },
    });
  };

  const handleClick = () => {
    const copyEmployees = { ...employees };

    copyEmployees.employeesList.push(copyEmployees.newEmployee);
    copyEmployees.newEmployee = {
      firstName: '',
      secondName: '',
      salary: '',
      gender: 'Male',
    };

    setEmployees(copyEmployees);
  };

  return (
    <div>
      <TableStrings data={employees.employeesList} />
      <br />
      <div>
        <div>
          <span>Name:</span>
          <input
            type="text"
            value={employees.newEmployee.firstName}
            name="firstName"
            onChange={handleChange}
          />
        </div>
        <div>
          <span>Second name:</span>
          <input
            type="text"
            value={employees.newEmployee.secondName}
            name="secondName"
            onChange={handleChange}
          />
        </div>
        <div>
          <span>Salary:</span>
          <input
            type="number"
            value={employees.newEmployee.salary}
            name="salary"
            onChange={handleChange}
          />
        </div>
        <div>
          <span>Gender:</span>
          <select value={employees.newEmployee.gender} name="gender" onChange={handleChange}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <button onClick={handleClick}>Add Employee</button>
      </div>
    </div>
  );
};

const Task12: FC = () => {
  const taskTitle: string =
    '12. Дан стейт внутри которого массив с работниками. У каждого работника есть имя, фамилия, зарплата, пол. Выведи этих работников на экран в виде таблицы. Под таблице сделай форму, с помощью которой в таблицу можно будет добавить нового работника. В этой форме имя, фамилия, зарплата будут инпутами, а пол - селектом, в котором можно будет выбрать один из двух вариантов.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        employeesList: [
          {
            firstName: 'John',
            secondName: 'Robertson',
            salary: '100',
            gender: 'Male',
          },
          {
            firstName: 'David',
            secondName: 'Dubliakov',
            salary: '200',
            gender: 'Male',
          },
          {
            firstName: 'Ben',
            secondName: 'Smith',
            salary: '300',
            gender: 'Male',
          },
        ],
        newEmployee: {
          firstName: '',
          secondName: '',
          salary: '',
          gender: 'Male',
        },
      };
    }

    handleChange({ target: { value, name } }) {
      this.setState({
        ...this.state,
        newEmployee: {
          ...this.state.newEmployee,
          [name]: value,
        },
      });
    }

    handleClick() {
      const copyState = { ...this.state };

      copyState.employeesList.push(copyState.newEmployee);
      copyState.newEmployee = {
        firstName: '',
        secondName: '',
        salary: '',
        gender: 'Male',
      };

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <TableStrings data={this.state.employeesList} />
          <br />
          <div>
            <div>
              <span>Name:</span>
              <input
                type='text'
                value={this.state.newEmployee.firstName}
                name='firstName'
                onChange={(event) => this.handleChange(event)}
              />
            </div>
            <div>
              <span>Second name:</span>
              <input
                type='text'
                value={this.state.newEmployee.secondName}
                name='secondName'
                onChange={(event) => this.handleChange(event)}
              />
            </div>
            <div>
              <span>Salary:</span>
              <input
                type='number'
                value={this.state.newEmployee.salary}
                name='salary'
                onChange={(event) => this.handleChange(event)}
              />
            </div>
            <div>
              <span>Gender:</span>
              <select
                value={this.state.newEmployee.gender}
                name='gender'
                onChange={(event) => this.handleChange(event)}
              >
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
              </select>
            </div>
            <button onClick={() => this.handleClick()}>Add Employee</button>
          </div>
        </div>
      );
    }
  }

  class TableStrings extends React.Component {
    render() {
      return (
        <table>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td width='15%'>{item.firstName}</td>
                <td width='20%'>{item.secondName}</td>
                <td width='10%'>{item.salary}</td>
                <td width='10%'>{item.gender}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const TableStrings = ({ data }) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td width="15%">{item.firstName}</td>
            <td width="20%">{item.secondName}</td>
            <td width="10%">{item.salary}</td>
            <td width="10%">{item.gender}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const App = () => {
    const [employees, setEmployees] = useState({
      employeesList: [
        { firstName: 'John', secondName: 'Robertson', salary: '100', gender: 'Male' },
        { firstName: 'David', secondName: 'Dubliakov', salary: '200', gender: 'Male' },
        { firstName: 'Ben', secondName: 'Smith', salary: '300', gender: 'Male' },
      ],
      newEmployee: { firstName: '', secondName: '', salary: '', gender: 'Male' },
    });

    const handleChange = ({ target: { value, name } }) => {
      setEmployees({
        ...employees,
        newEmployee: {
          ...employees.newEmployee,
          [name]: value,
        },
      });
    };

    const handleClick = () => {
      const copyEmployees = { ...employees };

      copyEmployees.employeesList.push(copyEmployees.newEmployee);
      copyEmployees.newEmployee = {
        firstName: '',
        secondName: '',
        salary: '',
        gender: 'Male',
      };

      setEmployees(copyEmployees);
    };

    return (
      <div>
        <TableStrings data={employees.employeesList} />
        <br />
        <div>
          <div>
            <span>Name:</span>
            <input
              type="text"
              value={employees.newEmployee.firstName}
              name="firstName"
              onChange={handleChange}
            />
          </div>
          <div>
            <span>Second name:</span>
            <input
              type="text"
              value={employees.newEmployee.secondName}
              name="secondName"
              onChange={handleChange}
            />
          </div>
          <div>
            <span>Salary:</span>
            <input
              type="number"
              value={employees.newEmployee.salary}
              name="salary"
              onChange={handleChange}
            />
          </div>
          <div>
            <span>Gender:</span>
            <select value={employees.newEmployee.gender} name="gender" onChange={handleChange}>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <button onClick={handleClick}>Add Employee</button>
        </div>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task12;
