import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const str = 'block';
  return <div className={str}>текст</div>;
};

const Task6: FC = () => {
  const taskTitle: string =
    "6. Пусть в компоненте есть переменная str с текстом 'block'. Сделай так, чтобы компонент вывел на экран следующее (значение атрибута class должно вставится из переменной str):";
  const taskCode: string = `
  <div class="block">
    текст
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const str = 'block';
      return <div className={str}>текст</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const str = 'block';
    return <div className={str}>текст</div>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task6;
