import { createUseStyles } from 'react-jss';

interface StyleProps {
  colors: { main: string; secondary: string };
  animName: string;
}

const useStyles = createUseStyles(({ colors, animName }: StyleProps) => ({
  root: {
    background: colors.main,
    borderRadius: '5px',
    padding: '2px 1.8px',

    '&:hover': {
      animation: `${animName} 0.4s linear`,
      animationFillMode: 'backwards',
      animationDirection: 'alternate',
    },

    '& .ant-input': {
      border: 'none',
      borderRadius: '3px',

      '&:focus': {
        animation: 'none',
        borderColor: colors.main,
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
}));

export default useStyles;
