import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    width: '50px',
    '& .testBtn': {
      position: 'absolute',
      width: '20px',
      height: '20px',
      background: 'yellow',
      top: '-10px',
      left: '3px',
    },
    '& .prev__button': {
      float: 'left',
    },
    '& .next__button': {
      float: 'right',
    },

    '&.task-navigation-container.side-bars': {
      width: '10%',
      position: 'relative',
      zIndex: '2',

      '& .ant-btn.ant-btn-circle.ant-btn-sm.ant-btn-icon-only': {
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0',

        '&::after': {
          content: "''",
          width: '0px',
          height: '0px',
          display: 'none !important',
        },
      },

      '& span': {
        backgroundColor: PALITRA.white,
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        '& svg': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      },

      '&.right-only': {
        '& .prev__button': {
          display: 'none',
        },
        '& .next__button': {
          position: 'absolute',
          left: '0%',
          background: 'inherit',
          height: '50% !important',
          width: '50% !important',
          transform: 'translate(50%, 50%) !important',
        },
      },

      '&.left-only': {
        '& .next__button': {
          display: 'none',
        },
        '& .prev__button': {
          position: 'absolute',
          left: '0',
          background: 'inherit',
          height: '50% !important',
          width: '50% !important',
          transform: 'translate(50%, 50%) !important',
        },
      },
    },
  },
});

export default useStyles;
