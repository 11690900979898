import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [status, setStatus] = useState(true);

  const handleCheckboxChange = () => setStatus(!status);

  return (
    <div>
      <p>{status ? 'true' : 'false'}</p>
      <input type="checkbox" checked={status} onChange={handleCheckboxChange} />
    </div>
  );
};

const Task2: FC = () => {
  const taskTitle: string =
    '2.  Дан чекбокс и абзац. Сделай так, чтобы состояние чекбокса выводилось в этот абзац.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        status: false,
      };
    }

    render() {
      return (
        <div>
          <p>{String(this.state.status)}</p>
          <input
            type='checkbox'
            checked={this.state.status}
            onChange={() => this.setState({ status: !this.state.status })}
          />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [status, setStatus] = useState(true);

    const handleCheckboxChange = () => setStatus(!status);

    return (
      <div>
        <p>{status ? 'true' : 'false'}</p>
        <input type="checkbox" checked={status} onChange={handleCheckboxChange} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task2;
