interface ICurrentTask {
  lesson: number;
  task: number;
}

export class AppState {
  private _selectLanguage: string;
  private _currentTask: ICurrentTask;
  private _screenSizeDesktop: boolean;
  private _isModalOpen: boolean;
  private _render: () => void;

  constructor(
    selectLanguage: string = 'en',
    currentTask: ICurrentTask = { lesson: 1, task: 1 },
    screenSizeDesktop: boolean = true,
    isModalOpen: boolean = false,
    render: () => void = () => {},
  ) {
    this._selectLanguage = selectLanguage;
    this._currentTask = currentTask;
    this._screenSizeDesktop = screenSizeDesktop;
    this._isModalOpen = isModalOpen;
    this._render = render;
  }

  set render(value: () => void) {
    this._render = value;
  }

  get selectLanguage(): string {
    return this._selectLanguage;
  }

  set selectLanguage(value: string) {
    this._selectLanguage = value;
    this._render();
  }

  get currentTask(): ICurrentTask {
    return this._currentTask;
  }

  set currentTask(value: ICurrentTask) {
    this._currentTask = value;
    this._render();
  }

  get screenSizeDesktop(): boolean {
    return this._screenSizeDesktop;
  }

  set screenSizeDesktop(value: boolean) {
    this._screenSizeDesktop = value;
    this._render();
  }
  get isModalOpen(): boolean {
    return this._isModalOpen;
  }

  set isModalOpen(value: boolean) {
    this._isModalOpen = value;
    this._render();
  }
}

export const appState = new AppState();
