import React from 'react';
import ComponentTemplate from '../../Task';

const Task3: React.FC = () => {
  const taskTitle: string =
    "3. Реализуй тест. Дан стейт в котором массив с вопросами и правильными ответами. Вопросы должны показываться по одному. Пользователь может двигаться по вопросам стрелками 'назад' и 'вперед'. После того, как пользователь ответит на все вопросы, должна появится кнопка 'проверить ответы'. По нажатию на эту кнопку нужно вывести все вопросы на экран, под вопросами должно быть следующее: 'ваш ответ такой-то, правильно' или 'ваш ответ такой-то, не правильно, правильный ответ такой-то'. Правильные ответы должны быть зеленого цвета, а неправильные - красного.";

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task3;
