import React from 'react';

interface TableProps {
  data: Matrix[][];
  stateFunc: (v: Matrix[][]) => void;
  winFunc: () => string;
}

let n: number = -1;

const Table: React.FC<TableProps> = ({ data, stateFunc, winFunc }) => {
  const handleClick = (item: number, id: number) => {
    const copyData = [...data];

    let value: string = copyData[item][id].value;
    let checked: boolean = copyData[item][id].checked;

    if (n < 1000 && checked === false) n++;

    if (n % 2 === 0 && checked === false) {
      value = 'X';
      checked = true;
    } else if (checked === false) {
      value = 'O';
      checked = true;
    }

    copyData[item][id].value = value;
    copyData[item][id].checked = checked;

    stateFunc(copyData);
  };

  return (
    <table className={winFunc() === '' ? 'active-tic-tac-toe' : 'disabled-tic-tac-toe'}>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {item.map((cell, id) => (
              <td className="td-tic-tac-toe" key={id} onClick={() => handleClick(index, id)}>
                {cell.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
