import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '& .ant-btn.ant-btn-primary': {
      marginBottom: '10px',
    },

    '& .save__button.ant-btn.ant-btn-primary': {
      marginTop: '10px',
      background: PALITRA.green.greenBright,
      borderColor: PALITRA.green.greenBright,
    },

    '& .ant-btn:focus.ant-btn-primary, .ant-btn:active.ant-btn-primary': {
      color: PALITRA.white,
      background: PALITRA.violet.violet,
    },

    '& .save__button.ant-btn:focus.ant-btn-primary, .save__button.ant-btn:active.ant-btn-primary': {
      color: PALITRA.white,
      background: PALITRA.green.greenBright,
    },
  },
});

export default useStyles;
