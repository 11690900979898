import { PALITRA } from '../../utils/constants';

const HomeLogics = () => {
  let position = 900;
  let bgColor = PALITRA.red.redPink;
  let isOpenMenu = false;
  let isWhiteStyle = true;

  const header = (window as any).header;

  const backgroundColorPoints = [
    { min: 0, max: 3000, color: PALITRA.red.redPink, headerClass: '' },
    { min: 3000, max: 5300, color: PALITRA.yellow, headerClass: 'header--black' },
    { min: 5100, max: 5500, color: PALITRA.white, headerClass: 'header--black' },
    { min: 5500, max: 8000, color: PALITRA.red.redPink, headerClass: '' },
  ];

  const slidesOpacityPoints = [
    { min: 800, max: 1150 },
    { min: 1100, max: 1700 },
    { min: 1650, max: 2150 },
    { min: 2100, max: 2500 },
    { min: 3050, max: 4100 },
    { min: 4100, max: 4800 },
    { min: 4800, max: 5300 },
    { min: 5100, max: 5600 },
    { min: 5500, max: 7500 },
  ];

  const spaceEl: any = document.getElementById('space');
  const sceneEl: any = document.getElementById('scene');
  const slidesEl: any = document.body.getElementsByClassName('space__slide');
  const logoEl: any = document.getElementById('logo');
  const homePage: any = document.getElementById('space');

  homePage.addEventListener(
    'wheel',
    (e: { deltaY: any }) => {
      scrollEffect(e.deltaY);
    },
    false,
  );

  function scrollEffect(deltaY: any) {
    if ((deltaY < 0 && position <= 850) || (deltaY > 0 && position >= 6000)) {
      return;
    }

    backgroundColorPoints.forEach((point) => {
      if (position >= point.min && position <= point.max && bgColor !== point.color) {
        bgColor = point.color;
        spaceEl.style.backgroundColor = bgColor;

        if (!isOpenMenu) {
          header.className = 'header ' + point.headerClass;
        }

        isWhiteStyle = !point.headerClass.length;

        bgColor === PALITRA.yellow || bgColor === PALITRA.white
          ? (logoEl.style.color = PALITRA.red.redPink)
          : (logoEl.style.color = PALITRA.white);
      }
    });

    let translate3d = `translate3d(0px, 0px, ${position}px)`;

    if (position > 2400 && position < 5700) {
      const unicornEl: any = document.getElementById('bubble-unicorn');

      if (position < position + deltaY / 2 && position < 3200) {
        unicornEl.classList.add('space__bubble-unicorn--animate-in');
        unicornEl.classList.remove('space__bubble-unicorn--animate-out');
        position = 3200;
      }

      if (position > position + deltaY / 2 && position < 3200) {
        unicornEl.classList.add('space__bubble-unicorn--animate-out');
        unicornEl.classList.remove('space__bubble-unicorn--animate-in');
        position = 2400;
      }

      translate3d = `translate3d(-15%, 10%, ${position}px)`;
    }

    if (position > 5100) {
      translate3d = `translate3d(-15%, -100%, ${5100}px)`;

      if (position > 5500) {
        translate3d = `translate3d(-15%, -100%, ${position}px)`;
      }
    }

    slidesOpacityPoints.forEach((point, index) => {
      if (position >= point.min && position <= point.max) {
        slidesEl[index].style.opacity = '1';
        slidesEl[index].style.visibility = 'visible';

        if (index < 5 && slidesEl[index].classList.contains('space__slide--init-state')) {
          slidesEl[index].classList.remove('space__slide--init-state');
        }

        for (let i = 0; i < slidesEl.length; i++) {
          if (i !== index) {
            slidesEl[i].style.opacity = '0';
            slidesEl[i].style.visibility = 'hidden';
          }
        }
      }
    });

    position += deltaY / 2;
    sceneEl.style.transform = `${translate3d} scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
  }

  function swipeEventListener() {
    let startY = 0;

    window.addEventListener('touchstart', (e) => {
      startY = e.changedTouches[0].clientY;
    });
    window.addEventListener('touchmove', (e) => {
      const currentTouchPosition = e.changedTouches[0].clientY;
      const deltaY = (startY - currentTouchPosition) / 2;
      scrollEffect(deltaY);
    });
  }

  function buildBubblesSpace() {
    let slidePoint = 1;
    let translateZ = 0;
    const bubblesEl: any = document.getElementById('bubbles');
    const randomPosition = (min: any, max: any) => {
      const random = Math.floor(Math.random() * (max - min)) + min;
      return random + '%';
    };

    Array.from({ length: 80 }).forEach((_, index) => {
      translateZ += -20;
      let minL = -10;
      let minT = -10;
      let maxL = 110;
      let maxT = 110;

      if (slidePoint === 6) {
        slidePoint = 0;
        translateZ += -300;
      }

      if (translateZ < -2700 && translateZ > -5000) {
        minL = 10;
      }

      if (translateZ < -5000) {
        minT = 90;
        maxT = 180;
        minL = 10;
      }

      const randomTop = randomPosition(minT, maxT);
      const randomLeft = randomPosition(minL, maxL);
      const bubble = document.createElement('span');
      bubble.classList.add('space__bubble');
      bubble.style.setProperty('--bubble-z', `${translateZ}px`);
      bubble.style.top = randomTop;
      bubble.style.left = randomLeft;
      bubblesEl.appendChild(bubble);

      slidePoint += 1;
    });
  }

  function initHeaderBtnListener() {
    const headerBtn: any = document.getElementById('btn-header');

    headerBtn.addEventListener('click', () => {
      isOpenMenu = !isOpenMenu;
      const isBlackStyle = header.classList.contains('header--black');

      if (isOpenMenu) {
        header.classList.add('show-nav');
        spaceEl.classList.add('space--with-menu');

        if (!isBlackStyle) {
          header.classList.add('header--black');
        }
        return;
      }

      header.classList.remove('show-nav');
      spaceEl.classList.remove('space--with-menu');

      if (isWhiteStyle) {
        header.className = 'header';
      }
    });
  }

  buildBubblesSpace();
  swipeEventListener();
  initHeaderBtnListener();
};

export default HomeLogics;
