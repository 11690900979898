import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const List = ({ names }: any) => {
  return (
    <ul>
      {names.map((item: string, index: number) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const TaskReady = () => {
  const [state, setState] = useState({ items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] });

  const addElement = () => {
    state.items.push('пункт');
    setState({ ...state });
  };

  return (
    <div>
      <List names={state.items} />
      <button onClick={addElement}>Добавить</button>
    </div>
  );
};

const Task2: FC = () => {
  const taskTitle: string =
    "2. Переделай предыдущую задачу так, чтобы под списком появилась кнопка, по нажатию на которую в конец списка будет добавляться элемент с текстом 'пункт'.";
  const taskClassSolution: string = `
  class List extends React.Component {
    render() {
      return (
        <ul>
          {this.props.names.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }
  }

  class App extends React.Component {
    state = { items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] };

    addElement() {
      this.state.items.push('пункт');
      this.setState(this.state);
    }

    render() {
      return (
        <div>
          <List names={this.state.items} />
          <button onClick={() => this.addElement()}>Добавить</button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ names }) => {
    return (
      <ul>
        {names.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const App = () => {
    const [state, setState] = useState({ items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] });

    const addElement = () => {
      state.items.push('пункт');
      setState({ ...state });
    };

    return (
      <div>
        <List names={state.items} />
        <button onClick={addElement}>Добавить</button>
      </div>
    );
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task2;
