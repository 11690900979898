import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
    height: 'calc(100vh - 100px)',

    '& .ant-form.ant-form-horizontal': {
      width: '80%',
    },

    '& .ant-form-item-control-input': {
      '& span > input': {
        height: '25px',
      },

      '& input': {
        height: '35px',
      },

      '& label.ant-checkbox-wrapper': {
        fontSize: '18px',
      },

      '& button': {
        height: '40px',
        width: '100%',

        '&:focus': {
          color: PALITRA.white,
          bordercColor: PALITRA.violet.violet,
        },

        '& span': {
          fontSize: '18px',
        },
      },
    },

    '& .ant-form-item-label > label': {
      fontSize: '18px',
    },

    '& .ant-col.ant-col-8.ant-form-item-label > label': {
      fontSize: '18px',
    },

    '@media screen and (max-width: 767px)': {
      '& .ant-form-item-label > label': {
        fontSize: '14px',
      },
    },
  },
});

export default useStyles;
