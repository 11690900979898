import React, { FC } from 'react';
import ComponentTemplate from '../../Task';

interface UsersProps {
  data: {
    name: string;
    secondName: string;
    age: number;
  }[];
}

const Users: FC<UsersProps> = ({ data }) => {
  return (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.secondName}</td>
            <td>{item.age}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TaskReady: FC = () => {
  const users = [
    { name: 'David', secondName: 'Dubliakov', age: 18 },
    { name: 'Alex', secondName: 'Kostenko', age: 22 },
    { name: 'Bodya', secondName: 'Zavgorodskiy', age: 22 },
  ];

  return <Users data={users} />;
};

const Task1: FC = () => {
  const taskTitle: string =
    '1. Пусть в стейте хранится массив с юзерами. У каждого юзера есть имя, фамилия и возраст. Выведи всех юзеров на экран с помощью цикла. Сделай так, чтобы отображением юзера на экран занимался отдельный компонент.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task1;
