import React, { useState } from 'react';

import Table from './Table';

const TicTacToe: React.FC = () => {
  const [matrix, setMatrix] = useState<Matrix[][]>([
    [
      { id: 1, checked: false, value: '' },
      { id: 2, checked: false, value: '' },
      { id: 3, checked: false, value: '' },
    ],
    [
      { id: 4, checked: false, value: '' },
      { id: 5, checked: false, value: '' },
      { id: 6, checked: false, value: '' },
    ],
    [
      { id: 7, checked: false, value: '' },
      { id: 8, checked: false, value: '' },
      { id: 9, checked: false, value: '' },
    ],
  ]);
  const restart = () => {
    setMatrix([
      [
        { id: 1, checked: false, value: '' },
        { id: 2, checked: false, value: '' },
        { id: 3, checked: false, value: '' },
      ],
      [
        { id: 4, checked: false, value: '' },
        { id: 5, checked: false, value: '' },
        { id: 6, checked: false, value: '' },
      ],
      [
        { id: 7, checked: false, value: '' },
        { id: 8, checked: false, value: '' },
        { id: 9, checked: false, value: '' },
      ],
    ]);
  };

  const winnerFunc = () => {
    let txt: string = '';

    let item1: string = matrix[0][0].value;
    let item2: string = matrix[0][1].value;
    let item3: string = matrix[0][2].value;
    let item4: string = matrix[1][0].value;
    let item5: string = matrix[1][1].value;
    let item6: string = matrix[1][2].value;
    let item7: string = matrix[2][0].value;
    let item8: string = matrix[2][1].value;
    let item9: string = matrix[2][2].value;

    if (item1 === 'X' && item2 === 'X' && item3 === 'X') {
      txt = 'Winner is X';
    } else if (item1 === 'O' && item2 === 'O' && item3 === 'O') {
      txt = 'Winner is O';
    } else if (item1 === 'X' && item5 === 'X' && item9 === 'X') {
      txt = 'Winner is X';
    } else if (item1 === 'O' && item5 === 'O' && item9 === 'O') {
      txt = 'Winner is O';
    } else if (item3 === 'X' && item5 === 'X' && item7 === 'X') {
      txt = 'Winner is X';
    } else if (item3 === 'O' && item5 === 'O' && item7 === 'O') {
      txt = 'Winner is O';
    } else if (item4 === 'X' && item5 === 'X' && item6 === 'X') {
      txt = 'Winner is X';
    } else if (item4 === 'O' && item5 === 'O' && item6 === 'O') {
      txt = 'Winner is O';
    } else if (item7 === 'X' && item8 === 'X' && item9 === 'X') {
      txt = 'Winner is X';
    } else if (item7 === 'O' && item8 === 'O' && item9 === 'O') {
      txt = 'Winner is O';
    } else if (item1 === 'X' && item4 === 'X' && item7 === 'X') {
      txt = 'Winner is X';
    } else if (item1 === 'O' && item4 === 'O' && item7 === 'O') {
      txt = 'Winner is O';
    } else if (item2 === 'X' && item5 === 'X' && item8 === 'X') {
      txt = 'Winner is X';
    } else if (item2 === 'O' && item5 === 'O' && item8 === 'O') {
      txt = 'Winner is O';
    } else if (item3 === 'X' && item6 === 'X' && item9 === 'X') {
      txt = 'Winner is X';
    } else if (item3 === 'O' && item6 === 'O' && item9 === 'O') {
      txt = 'Winner is O';
    } else if (
      item1 !== '' &&
      item2 !== '' &&
      item3 !== '' &&
      item4 !== '' &&
      item5 !== '' &&
      item6 !== '' &&
      item7 !== '' &&
      item8 !== '' &&
      item9 !== ''
    ) {
      txt = 'Draw. Friendship wins!';
    }

    return txt;
  };

  return (
    <div className="site-layout-content">
      <Table data={matrix} stateFunc={setMatrix} winFunc={winnerFunc} />
      <p className="para">{winnerFunc()}</p>
      {winnerFunc() && (
        <p className="restart-button" onClick={restart}>
          restart
        </p>
      )}
    </div>
  );
};

export default TicTacToe;
