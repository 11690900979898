import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const state = { show: true, name: 'Иван' };

  return (
    <div>
      <p>
        {state.show ? 'Привет' : 'Пока'}, {state.name}
      </p>
    </div>
  );
};

const Task1: FC = () => {
  const taskTitle: string = '1. Дан следующий объект:';
  const taskCode: string = `
    const state  = {show: 'true', name: 'Иван'};
  `;
  const taskTitle2: string = `Изначально компонент возвращает следующее ('Иван' берется из state.name):`;
  const taskCode2: string = `
    <div>
      <p>Привет, Иван!</p>
    </div>
  `;
  const taskTitle3: string =
    "Сделай так, чтобы если state.show был равен true, то выводился текст 'Привет, Иван', а если равен false - то 'Пока, Иван'.";
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { show: true, name: 'Иван' };

    render() {
      return (
        <div>
          <p>
            {this.state.show ? 'Привет' : 'Пока'}, {this.state.name}
          </p>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const state = { show: true, name: 'Иван' };

    return (
      <div>
        <p>
          {state.show ? 'Привет' : 'Пока'}, {state.name}
        </p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
        taskTitle3={taskTitle3}
      />
    </div>
  );
};
export default Task1;
