import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const arr = ['a', 'b', 'c', 'd', 'e'];
  const list = arr.map((item, index) => <li key={index}>{item}</li>);

  return <ul>{list}</ul>;
};

const Task9: FC = () => {
  const taskTitle: string =
    "9. Пусть в компоненте есть переменная arr, в которой лежит массив с элементами ['a', 'b', 'c', 'd', 'e']. Сделай так, чтобы компонент вывел на экран следующее (в каждую лишку запишется один из элементов массива):";
  const taskCode: string = `
  <ul>
    <li>a</li>
    <li>b</li>
    <li>c</li>
    <li>d</li>
    <li>e</li>
  </ul>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const arr = ['a', 'b', 'c', 'd', 'e'];
      const list = arr.map((item, index) => <li key={index}>{item}</li>);

    return <ul>{list}</ul>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const arr = ['a', 'b', 'c', 'd', 'e'];
    const list = arr.map((item, index) => <li key={index}>{item}</li>);

    return <ul>{list}</ul>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task9;
