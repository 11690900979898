import React, { FC, useRef, useState } from 'react';
import ComponentTemplate from '../../Task';

interface DaysOfTheWeekProps {
  data: string[];
}

const DaysOfTheWeek: FC<DaysOfTheWeekProps> = ({ data }) => {
  const selectedDayOfTheWeek: React.MutableRefObject<any> = useRef(null);

  return (
    <select ref={selectedDayOfTheWeek}>
      {data.map((item, index) => (
        <option key={index}> {item} </option>
      ))}
      ;
    </select>
  );
};

const TaskReady: FC = () => {
  const selectedLanguage: React.MutableRefObject<any> = useRef(null);

  const [values, setValues] = useState({
    language: 'Русский',
    daysOfTheWeek: [
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье',
    ],
  });

  const handleChange = () => {
    const copyValues = { ...values };
    if (selectedLanguage === null || selectedLanguage.current.value === 'Русский') {
      copyValues.language = 'Русский';
      copyValues.daysOfTheWeek = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ];
    } else {
      copyValues.language = 'English';
      copyValues.daysOfTheWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
    }
    setValues(copyValues);
  };

  return (
    <div>
      <select ref={selectedLanguage} onChange={handleChange}>
        <option>Русский</option>
        <option>English</option>
      </select>

      <DaysOfTheWeek data={values.daysOfTheWeek} />
    </div>
  );
};

const Task10: FC = () => {
  const taskTitle: string =
    '10. Даны два селекта. С помощью первого селекта можно выбрать язык пользователя (русский или английский). Если в первом селекте выбран русский язык, то во втором селекте должен появится список дней недели по-русски. А если в первом селекте выбран английский - то и во втором дни недели будут по-английски.';
  const taskClassSolution: string = `
  const ENGLISH_DAYS_OF_THE_WEEK = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const RUSSIAN_DAYS_OF_THE_WEEK = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ];

  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        language: 'Русский',
        daysOfTheWeek: RUSSIAN_DAYS_OF_THE_WEEK,
      };
      this.selectedLanguage = React.createRef();
    }

    handleChange() {
      const copyState = { ...this.state };
      if (
        this.selectedLanguage === null ||
        this.selectedLanguage.current.value === 'Русский'
      ) {
        copyState.language = 'Русский';
        copyState.daysOfTheWeek = RUSSIAN_DAYS_OF_THE_WEEK;
      } else {
        copyState.language = 'English';
        copyState.daysOfTheWeek = ENGLISH_DAYS_OF_THE_WEEK;
      }
      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <select
            ref={this.selectedLanguage}
            onChange={() => this.handleChange()}
          >
            <option>Русский</option>
            <option>English</option>
          </select>

          <DaysOfTheWeek data={this.state.daysOfTheWeek} />
        </div>
      );
    }
  }

  class DaysOfTheWeek extends React.Component {
    constructor() {
      super();
      this.selectedDayOfTheWeek = React.createRef();
    }

    render() {
      return (
        <select ref={this.selectedDayOfTheWeek}>
          {this.props.data.map((item, index) => (
            <option key={index}> {item} </option>
          ))}
          ;
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const DaysOfTheWeek = ({ data }) => {
    const selectedDayOfTheWeek = useRef(null);

    return (
      <select ref={selectedDayOfTheWeek}>
        {data.map((item, index) => (
          <option key={index}> {item} </option>
        ))}
        ;
      </select>
    );
  };

  const App = () => {
    const selectedLanguage = useRef(null);

    const [values, setValues] = useState({
      language: 'Русский',
      daysOfTheWeek: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],
    });

    const handleChange = () => {
      const copyValues = { ...values };
      if (selectedLanguage === null || selectedLanguage.current.value === 'Русский') {
        copyValues.language = 'Русский';
        copyValues.daysOfTheWeek = [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье',
        ];
      } else {
        copyValues.language = 'English';
        copyValues.daysOfTheWeek = [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ];
      }
      setValues(copyValues);
    };
    
    return (
    <div>
      <select ref={selectedLanguage} onChange={handleChange}>
        <option>Русский</option>
        <option>English</option>
      </select>

      <DaysOfTheWeek data={values.daysOfTheWeek} />
    </div>
  );
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task10;
