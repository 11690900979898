import React, { FC, useEffect, useState } from 'react';

import DropdownMenu from './DropdownMenu';
import DefaultMenu from './DefaultMenu';
import MenuNavigation from './MenuNavigation';

import useStyles from './style';

interface MenuProps {
  pathname: string;
}

const Menu: FC<MenuProps> = ({ pathname }) => {
  const classes = useStyles();

  const [dropdowns, setDropdowns] = useState({
    dropdownMenu: false,
    dropMenuLessons: true,
  });

  useEffect(() => {
    const checkMobile = () => {
      const viewChangeValue = 900;

      if (window.innerWidth > viewChangeValue && dropdowns.dropdownMenu) {
        setDropdowns((prev) => ({ ...prev, dropdownMenu: false }));
      }
    };

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDropdowns({ dropdownMenu: false, dropMenuLessons: false });
  }, [pathname]);

  return (
    <nav className={classes.root}>
      <DefaultMenu isMenuOpen={dropdowns.dropdownMenu} pathname={pathname} />
      <MenuNavigation isMenuOpen={dropdowns.dropdownMenu} setIsMenuOpen={setDropdowns} />
      <DropdownMenu isMenuOpen={dropdowns.dropdownMenu} />
    </nav>
  ); 
};

export default Menu;
