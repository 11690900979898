import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [status, setStatus] = useState(true);

  const handleCheckboxChange = () => setStatus(!status);

  return (
    <div>
      {status && <p>Абзац с каким-то текстом</p>}
      <input type="checkbox" checked={status} onChange={handleCheckboxChange} />
    </div>
  );
};

const Task3: FC = () => {
  const taskTitle: string =
    '3. Дан чекбокс и какой-то абзац с текстом. Сделай так, чтобы если чекбокс отмечен - абзац был виден на экране, а если не отмечен - не видим.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        status: true,
      };
    }

    render() {
      return (
        <div>
          <p>{this.state.status && 'Абзац с каким-то текстом'}</p>
          <input
            type='checkbox'
            checked={this.state.status}
            onChange={() => this.setState({ status: !this.state.status })}
          />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [status, setStatus] = useState(true);

    const handleCheckboxChange = () => setStatus(!status);

    return (
      <div>
        <p>{status && 'Абзац с каким-то текстом'}</p>
        <input type="checkbox" checked={status} onChange={handleCheckboxChange} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task3;
