import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const show: boolean = true;
  let text: string = '';

  show ? (text = 'текст 1') : (text = 'текст 2');

  return <div>{text}</div>;
};

const Task8: FC = () => {
  const taskTitle: string =
    '8. Пусть есть переменная show, которая может иметь значение true или false. Сделай так, чтобы, если эта переменная равна true, на єкран вывелось следующее:';
  const taskCode: string = `
  <div>
    текст 1
  </div>
  `;
  const taskTitle2: string = 'А если эта переменная равна false, то следующее:';
  const taskCode2: string = `
  <div>
    текст 2
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const show: boolean = true;
      let text: string = '';

      show ? text = 'текст 1' : text = 'текст 2';

      return <div>{text}</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const show = true;
    let text = '';

    show ? text = 'текст 1' : text = 'текст 2';

    return <div>{text}</div>;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
      />
    </div>
  );
};
export default Task8;
