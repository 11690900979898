import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface State {
  inputValue: string;
  returnValue: string;
}

const TaskReady: FC = () => {
  const [text, setText] = useState<State>({ inputValue: '', returnValue: '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText({ ...text, inputValue: e.target.value });
  };

  const handleClick = () => {
    const copyText = { ...text };
    copyText.returnValue = copyText.inputValue;
    copyText.inputValue = '';
    setText(copyText);
  };

  return (
    <div>
      <input type="text" value={text.inputValue} onChange={handleChange} />
      <button onClick={handleClick}>submit</button>
      <p>{text.returnValue}</p>
    </div>
  );
};

const Task5: FC = () => {
  const taskTitle: string =
    '5.Дан инпут и кнопка submit. В инпут вводится текст. Сделай так, чтобы по нажатию на кнопку этот текст вывелся в какой-нибудь абзац.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { value: '' , submittedData : ''};
  
    handleChange(event) {
      this.setState({...this.state, value:event.target.value})
    }
  
    handleOnClick() {
      this.setState({submittedData:this.state.value, value:''}) ;
    }
  
    render() {
      return (
        <div>
          <input value={this.state.value} type="text" onChange={this.handleChange.bind(this)} />
          <button onClick={this.handleOnClick.bind(this)}>submit</button>
          {<p>{this.state.submittedData}</p>}
        </div>  
      );
    }
  }
  `;

  const taskFuncSolution: string = `
  const App = () => {
    const [text, setText] = useState({ inputValue: '', returnValue: '' });

    const handleChange = (e) => {
      setText({ ...text, inputValue: e.target.value });
    };

    const handleClick = () => {
      const copyText = { ...text };
      copyText.returnValue = copyText.inputValue;
      copyText.inputValue = '';
      setText(copyText);
    };

    return (
      <div>
        <input type="text" value={text.inputValue} onChange={handleChange} />
        <button onClick={handleClick}>submit</button>
        <p>{text.returnValue}</p>
      </div>
    );
  };`;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task5;
