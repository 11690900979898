import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface UsersListProps {
  data: {
    name: string;
    surname: string;
    age: number;
    checked: boolean;
  }[];
  func: (index: number) => void;
}

const UsersList: FC<UsersListProps> = ({ data, func }) => (
  <ul>
    {data.map((item, index) => (
      <li key={index}>
        <input type="checkbox" checked={item.checked} onChange={() => func(index)} />
        {item.checked ? ` ${item.name} ${item.surname} ${item.age}` : `  ${item.name}`}
      </li>
    ))}
  </ul>
);

const TaskReady: FC = () => {
  const [users, setUsers] = useState([
    { name: 'Коля', surname: 'Иванов', age: 30, checked: true },
    { name: 'Вася', surname: 'Петров', age: 40, checked: true },
    { name: 'Петя', surname: 'Сидоров', age: 50, checked: true },
  ]);

  const handleCheckboxChange = (index: number) => {
    const copyUsers = [...users];
    copyUsers[index].checked = !copyUsers[index].checked;
    setUsers([...copyUsers]);
  };

  return <UsersList data={users} func={handleCheckboxChange} />;
};

const Task4: FC = () => {
  const taskTitle: string = '4. Дан стейт внутри которого массив с юзерами:';
  const taskCode: string = `
  const [state, setState] = useState({
    users: [
      {name: 'Коля', surname: 'Иванов', age: 30, checked: true},
      {name: 'Вася', surname: 'Петров', age: 40, checked: true},
      {name: 'Петя', surname: 'Сидоров', age: 50, checked: true},
    ]
  });
  `;
  const taskTitle2: string =
    'Выведи элементы этого массива в виде списка ul. Имя, фамилия и возраст каждого юзера должны стоять в одной li через пробел. Внутри каждой li сделай чекбокс. Если чекбокс не отмечен, то в li должно быть только имя юзера, а если отмечен - имя, фамилия и возраст.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        users: [
          { name: 'Коля', surname: 'Иванов', age: 30, checked: true },
          { name: 'Вася', surname: 'Петров', age: 40, checked: true },
          { name: 'Петя', surname: 'Сидоров', age: 50, checked: true },
        ],
      };
    }

    handleCheckboxChange(index) {
      const copyState = { ...this.state };
      copyState.users[index].checked = !copyState.users[index].checked;

      this.setState(copyState);
    }

    render() {
      return (
        <UsersList
          data={this.state.users}
          func={this.handleCheckboxChange.bind(this)}
        />
      );
    }
  }

  class UsersList extends React.Component {
    render() {
      return (
        <ul>
          {this.props.data.map((item, index) => (
            <li key={index}>
              <input
                type='checkbox'
                checked={item.checked}
                onChange={() => this.props.func(index)}
              />
              {item.checked
                ? \` \${item.name} \${item.surname} \${item.age}\`
                : \`  \${item.name}\`}
            </li>
          ))}
        </ul>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const UsersList = ({ data, func }) => (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <input type="checkbox" checked={item.checked} onChange={() => func(index)} />
          {item.checked ? \` \${item.name} \${item.surname} \${item.age}\` : \`  \${item.name}\`}
        </li>
      ))}
    </ul>
  );

  const App = () => {
    const [users, setUsers] = useState([
      { name: 'Коля', surname: 'Иванова', age: 30, checked: true },
      { name: 'Вася', surname: 'Петров', age: 40, checked: true },
      { name: 'Петя', surname: 'Сидоров', age: 50, checked: true },
    ]);

    const handleCheckboxChange = (index) => {
      const copyUsers = [...users];
      copyUsers[index].checked = !copyUsers[index].checked;
      setUsers([...copyUsers]);
    };

    return <UsersList data={users} func={handleCheckboxChange} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskTitle2={taskTitle2}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task4;
