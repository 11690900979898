import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../../utils/constants';

const params = {
  baseColor: 'rgba(255, 255, 255, 0.65)',
  hoverColor: PALITRA.violet.violet,
  bgColor: PALITRA.menuBackground,
};

const useStyles = createUseStyles({
  root: {
    '&.lessons__dropdown': {
      pointerEvents: 'all',
      zIndex: '9999',
      position: 'absolute',
      paddingLeft: '0',
      backgroundColor: params.bgColor,
      top: '56px',
      width: '225px !important',
      left: '0',

      '& li': {
        width: '100% !important',
        display: 'flex !important',
        justifyContent: 'flex-start !important',
        paddingLeft: '20px',
        boxSizing: 'border-box',

        '& a': {
          width: '100%',
        },
      },
    },

    '&.dropper-lessons__dropdown': {
      pointerEvents: 'all',
      zIndex: '9999',
      paddingLeft: '0',
      backgroundColor: params.bgColor,
      top: '140px',
      width: '100%',
      left: '0',

      '& li': {
        width: '100%',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
      },
    },
  },
});

export default useStyles;
