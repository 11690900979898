import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  devider: {
    '&.ant-divider-with-text-left': {
      borderTopColor: PALITRA.red.red,
    },
  },
  deviderHorizontal: {
    background: PALITRA.red.red,
  },

  bottomText: {
    marginBottom: '0px',
  },
  selectLanguage: {
    textAlign: 'center',
    marginBottom: '15px',
  },
});

export default useStyles;
