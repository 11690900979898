import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

interface StyleProps {
  colors: { main: string; secondary: string };
  animName: string;
}

const useStyle = createUseStyles({
  root: ({ colors, animName }: StyleProps) => ({
    '& .ant-btn': {
      marginBottom: 0,
      fontWeight: 600,
      borderRadius: '5px',
      maxWidth: '120px',
      border: 'none',
      outline: 0,
      background: `${colors.main}`,
      color: PALITRA.white,
      transition: 'box-shadow .5s',

      '&:hover': {
        boxShadow: `-3px 3px 0px 1px ${colors.secondary}`,
        animation: `${animName} .4s linear`,
        animationFillMode: 'backwards',
        animationDirection: 'alternate',
      },

      '&:focus': {
        fontWeight: 600,
        boxShadow: 'none',
      },
    },
  }),
});

export default useStyle;
