import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { storageService } from '../services/StorageService';

export const rtkApi = createApi({
  reducerPath: 'rtkApi',
  tagTypes: ['Tasks'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (header) => {
      const token = storageService.get('access_token');
      if (token) {
        header.set('Authorization', `Bearer ${token}`);
      }

      header.set('content-type', 'application/x-www-form-urlencoded');
      header.set('Access-Control-Allow-Credentials', 'true');
    },
  }),

  endpoints: (build) => ({
    loginUser: build.mutation({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body: queryString.stringify({
          ...body,
        }),
      }),
    }),
    registerUser: build.mutation({
      query: (body) => ({
        url: '/register',
        method: 'POST',
        body: queryString.stringify({
          ...body,
        }),
      }),
    }),
    saveTask: build.mutation({
      query: (body) => ({
        url: '/save-task',
        method: 'PUT',
        body: queryString.stringify({
          ...body,
        }),
      }),
      invalidatesTags: [{ type: 'Tasks', id: 'LIST' }],
    }),
    getUserInfo: build.query<UserModel, string | null>({
      query: (userID) => `/user-info/${userID}`,
      providesTags: (result) =>
        result
          ? [
              ...result.lessons.map(({ tasks }) => ({ type: 'Tasks' as const, tasks })),
              { type: 'Tasks', id: 'LIST' },
            ]
          : [{ type: 'Tasks', id: 'LIST' }],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useSaveTaskMutation,
  useGetUserInfoQuery,
} = rtkApi;
