import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    minHeight: '280px',
    background: 'url("./assets/img/meadow.webp") center/cover no-repeat',
    maxWidth: '1024px',
    textAlign: 'center',
    boxShadow: '0px 0px 8px rgba(237, 237, 237, 0.5)',
    borderRadius: '3px',
    color: PALITRA.white,
    marginBottom: '28px',

    '@media screen and (max-width: 1024px)': {
      margin: '0px 15px 28px 15px',
    },
    '& .shadow': {
      background: 'rgba(0,0,0, 0.75)',
      borderRadius: '3px',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      padding: '4px 24px 44px 24px',
      height: '100%',

      '& .contact-title': {
        color: PALITRA.white,
        fontSize: '48px',
        lineHeight: '1.2',
        fontWeight: '600',
        marginTop: '30px',
      },
      '& .contact-subtitle': {
        margin: '0 auto',
        fontSize: '24px',
        fontWeight: '300',
        maxWidth: '600px',
        marginBottom: '50px',
      },

      '@media screen and (max-width: 767px)': {
        '& .contact-title': {
          fontSize: '30px',
        },

        '& .contact-subtitle': {
          fontSize: '16px',
        },
      },

      '& .contact-form': {
        width: '90%',
        margin: '0 auto',

        '@media screen and (min-width: 900px)': {
          width: '100%',
        },

        '& .contact-wrapper': {
          display: 'grid',

          '@media screen and (min-width: 900px)': {
            gridTemplateColumns: 'repeat(3,1fr) 0.5fr',
            columnGap: '30px',
          },

          '& .ant-input-affix-wrapper': {
            height: '56px',
            border: `2px solid ${PALITRA.white}`,
            background: 'transparent',
          },

          '& .ant-input': {
            background: 'transparent',
            color: PALITRA.white,
            fontSize: '16px',
          },

          '& .ant-btn': {
            height: '56px',
            border: '0',
            background: PALITRA.crimson,
            color: PALITRA.white,
            fontWeight: '600',
            fontSize: '16px',
          },
        },

        '& .subLine': {
          fontSize: '14px',
          color: PALITRA.white,

          '@media screen and (max-width: 900px)': {
            marginTop: '20px',
          },
        },
      },
    },
  },
});

export default useStyles;
