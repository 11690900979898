import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import LessonsDropdown from '../LessonsDropdown';
import { SelectLanguage } from '../../../Selectanguage';
import { authService } from '../../../../services/AuthService';
import { RightOutlined } from '@ant-design/icons';
import Pages from '../../../../utils/pages';

import useStyles from './style';

interface DropdownMenuProps {
  isMenuOpen: boolean;
}

const DropdownMenu: FC<DropdownMenuProps> = ({ isMenuOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showLessonsDropdown, setShowLessonsDropdown] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');

    if (isMenuOpen) {
      body?.classList.add('lock');
    } else {
      body?.classList.remove('lock');
    }
  }, [isMenuOpen]);

  return (
    <div
      className={classNames(classes.root, 'dropdown-menu', isMenuOpen ? 'dropped' : 'collapsed')}>
      <ul className="list-items">
        <li>
          <Link to={Pages.HOME}>{t('burger.home')}</Link>
        </li>
        <li onClick={() => setShowLessonsDropdown(!showLessonsDropdown)} className="lessons__item">
          <RightOutlined className={classNames('drop__icon', { dropped: showLessonsDropdown })} />
          <span>{t('burger.lessons')}</span>
          {showLessonsDropdown && <LessonsDropdown className="dropper-lessons__dropdown" />}
        </li>
        <li>
          <Link to={Pages.TIC_TAC_TOE}>{t('burger.ticTacToe')}</Link>
        </li>
        <li>
          <Link to={Pages.BATTLESHIP}>{t('burger.battleship')}</Link>
        </li>
        {authService.isLogin() ? (
          <>
            <li>
              <Link to={Pages.PROFILE}>
                <img src="/assets/icons/user--avatar--filled--alt.svg" alt="avatar icon" />
                {t('burger.profile')}
              </Link>
            </li>
            <li className="logout__item">
              <Link to={Pages.LOGIN} onClick={() => authService.logoutUser()}>
                {t('burger.logout')}
              </Link>
            </li>
          </>
        ) : (
          <li className="login__item">
            <Link to={Pages.LOGIN}> {t('burger.login')}</Link>
          </li>
        )}
        <div className="selectLanguage">
          <SelectLanguage styleForSelect={{ bg: '#001529', colorText: '#ffffff' }} />
        </div>
      </ul>
    </div>
  );
};

export default DropdownMenu;
