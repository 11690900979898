import { createUseStyles } from 'react-jss';

import { PALITRA } from './utils/constants';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    '& .ant-layout.layout': {
      backgroundColor: PALITRA.red.redPink,
      height: '100%',

      //Task Navigation styles
      '& .task-section .task-navigation-container': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .task-navigation-container': {
        textAlign: 'center',
        marginTop: '50px',

        '@media screen and (max-width: 1280px)': {
          columnGap: '20px',

          '& .ant-btn': {
            width: '30px',
            height: '30px',
          },
        },
      },
      '& .breadcrumbs-section': {
        position: 'relative',
      },
      '& .breadcrumbs-section > .task-navigation-container': {
        position: 'absolute',
        top: '-15%',
        marginTop: '0px',
        left: '50%',
        width: '80px',
        transform: 'translateX(-50%)',
      },

      // Layout styles
      '& .ant-btn:focus, .ant-btn:active': {
        color: PALITRA.black,
        borderColor: PALITRA.grey.lightGrey1,
      },
      '& .ant-menu': {
        margin: '0 auto !important',
        maxWidth: '1024px',
        padding: '0 !important',
      },
      '& .site-layout-content': {
        minHeight: '280px',
        padding: '24px',
        background: PALITRA.backgroundSection,
        maxWidth: '1024px',
        margin: '0 auto',
        boxShadow: '0px 0px 8px rgba(237, 237, 237, 0.5)',
        borderRadius: '3px',
      },
      '& #components-layout-demo-top .logo': {
        float: 'left',
        width: '120px',
        height: '31px',
        margin: '16px 24px 16px 0',
        background: 'rgb(255, 255, 255, 0.3)',
      },
      '& .ant-row-rtl #components-layout-demo-top .logo': {
        float: 'right',
        margin: '16px 0 16px 24px',
      },
      '& main.ant-layout-content > *': {
        minHeight: 'auto',
        maxWidth: '1024px',
        minWidth: '100%',
      },
      '& main.ant-layout-content': {
        minHeight: 'calc(100vh - 64px)',
        width: '90%',
        padding: '0px 0 0px!important',
        maxWidth: '1024px',
      },
      '& .ant-tabs-nav': {
        margin: '0 !important',
      },

      //Noughts and crosses styles
      '& td': {
        width: '40px',
        height: '40px',
        border: `1px solid #1A374D`,
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all 0.5s ease-in-out',
        '&:hover': {
          backgroundColor: `${PALITRA.blue.blueBlackTransparent}`,
        },
      },
      '& .td-tic-tac-toe': {
        color: '#1A374D',
      },
      '& .para': {
        display: 'block',
        width: 'fit-content',
        margin: '15px auto',
        fontSize: ' 18px',
        color: `${PALITRA.blue.blueBlack}`,
      },
      '& .restart-button': {
        display: 'block',
        width: 'fit-content',
        margin: '15px auto',
        fontSize: ' 18px',
        color: `${PALITRA.black}`,
        transition: 'all 500ms ease-in-out',
        '&:hover': {
          cursor: 'pointer',
          color: `${PALITRA.blue.blue}`,
        },
      },
      '& .active-tic-tac-toe': {
        width: '300px',
        height: '300px',
        fontSize: '20px',
        margin: '0 auto',
        border: `2px solid  ${PALITRA.blue.blueBlack}`,
      },
      '& .disabled-tic-tac-toe': {
        pointerEvents: 'none',
        width: '300px',
        height: '300px',
        fontSize: '20px',
        margin: '0 auto',
        border: `2px solid  ${PALITRA.blue.blueBlack}`,
      },

      // Code highlight styles
      '& .pre-section': {
        width: '500px',
      },
      '& .task-section': {
        marginBottom: '60px',
        marginTop: '10px',
        padding: '0 10px 0 10px',
      },
      '& .ant-tabs.ant-tabs-top.ant-tabs-card': {
        marginTop: '20px',
      },
    },
    '& .ant-layout.main-background': {
      backgroundColor: PALITRA.mainBackground,
    },
  },
});

export default useStyles;
