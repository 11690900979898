import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  rootHome: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .main__block': {
      padding: '0 50px',
    },
    padding: '30px 0 28px!important',
    margin: '30px auto!important',
  },
  root: {
    display: 'flex',
    '& .main__block': {
      padding: '0 50px',
    },
  },
});

export default useStyles;
