import React, { useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import Lesson1 from '../Lesson1/';
import Lesson2 from '../Lesson2/';
import Lesson3 from '../Lesson3/';
import Lesson4 from '../Lesson4/';
import Lesson5 from '../Lesson5/';
import Lesson6 from '../Lesson6/';
import Lesson7 from '../Lesson7/';
import Lesson8 from '../Lesson8/';
import Lesson9 from '../Lesson9/';
import Lesson10 from '../Lesson10/';
import { appState } from '../../Context/secondClass';

const Lessons: React.FC<RouteComponentProps> = (props) => {
  const { lesson } = useParams<{ lesson?: string }>();
  useEffect(() => {
    const items = props.location.pathname.split('/');

    appState.currentTask = { lesson: Number(items[2]), task: Number(items[4]) };
    // eslint-disable-next-line
  }, [appState.currentTask, props.location.pathname]);

  const GetLesson = () => {
    const key = Number(lesson);

    switch (key) {
      case 1:
        return <Lesson1 />;
      case 2:
        return <Lesson2 />;
      case 3:
        return <Lesson3 />;
      case 4:
        return <Lesson4 />;
      case 5:
        return <Lesson5 />;
      case 6:
        return <Lesson6 />;
      case 7:
        return <Lesson7 />;
      case 8:
        return <Lesson8 />;
      case 9:
        return <Lesson9 />;
      case 10:
        return <Lesson10 />;
      default:
        return null;
    }
  };

  return (
    <div>
      <GetLesson />
    </div>
  );
};

export default withRouter(Lessons);
