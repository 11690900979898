import React from 'react';
import { Redirect } from 'react-router-dom';

import { authService } from '../services/AuthService';
import Pages from '../utils/pages';

const PrivateRoute = ({ children }: any) => {
  if (authService.isLogin()) {
    return children;
  }

  return <Redirect to={Pages.LOGIN} />;
};

export default PrivateRoute;
