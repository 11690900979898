import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '& .client-wrap': {
      marginTop: '30px',
    },
  },
});

export default useStyles;
