import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface State {
  name: string;
  lastName: string;
  secondName: string;
  fullName: string;
}

const TaskReady: FC = () => {
  const [values, setValues] = useState<State>({
    name: '',
    lastName: '',
    secondName: '',
    fullName: '',
  });

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: value });
  };

  const handleClick = () => {
    const copyValues = { ...values };
    copyValues.fullName = copyValues.lastName + ' ' + copyValues.name + ' ' + copyValues.secondName;
    copyValues.name = '';
    copyValues.lastName = '';
    copyValues.secondName = '';
    setValues(copyValues);
  };

  return (
    <div>
      <input
        type="text"
        name="name"
        value={values.name}
        onChange={handleChange}
        placeholder="Имя"
      />
      <input
        type="text"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        placeholder="Фамилия"
      />
      <input
        type="text"
        name="secondName"
        value={values.secondName}
        onChange={handleChange}
        placeholder="Отчество"
      />
      <button onClick={handleClick}>Нажми на меня</button>
      <p>{values.fullName}</p>
    </div>
  );
};

const Task7: FC = () => {
  const taskTitle: string =
    '7. Даны 3 инпута и кнопка. В инпуты вводится имя, фамилия и отчество. ' +
    'Сделай так, чтобы по нажатию на кнопку под инпутами в какой-нибудь абзац вывелось полное ФИО пользователя.';

  const taskClassSolution: string = `
    class App extends React.Component {
      state = { name: '' , lastName : '', secondName:'', fullName:''};
    
      handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, name: event.target.value})
      }
    
      handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, lastName: event.target.value})
      }
    
      handleChangeSecondName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, secondName: event.target.value})
      }
    
      handleOnClick() {
        this.setState({
          fullName: this.state.lastName + ' ' + this.state.name + ' ' + this.state.secondName,
          name: '',
          lastName: '',
          secondName: ''
        }) ;
      }
    
      render() {
        return (
          <div>
            <input
              value={this.state.name}
              placeholder="Имя"
              type="text"
              onChange={this.handleChangeFirstName.bind(this)}
            />
    
            <input
              value={this.state.lastName}
              placeholder="Фамилия"
              type="text"
              onChange={this.handleChangeLastName.bind(this)} 
             />
    
            <input
              value={this.state.secondName}
              placeholder="Отчество"
              type="text"
              onChange={this.handleChangeSecondName.bind(this)} 
             />
    
            <button onClick={this.handleOnClick.bind(this)}>Нажми на меня</button>
            {<p>{this.state.fullName}</p>}
          </div>
        );
      }
    }
  `;

  const taskFuncSolution: string = `
  const App = () => {
    const [values, setValues] = useState({
      name: '',
      lastName: '',
      secondName: '',
      fullName: '',
    });

    const handleChangeNameValue = (e) => {
      setValues({ ...values, name: e.target.value });
    };

    const handleChangeLastNameValue = (e) => {
      setValues({ ...values, lastName: e.target.value });
    };

    const handleChangeSecondNameValue = (e) => {
      setValues({ ...values, secondName: e.target.value });
    };

    const handleClick = () => {
      const copyValues = { ...values };
      copyValues.fullName = copyValues.fullName = copyValues.lastName + " " + copyValues.name + " " + copyValues.secondName;
      copyValues.name = '';
      copyValues.lastName = '';
      copyValues.secondName = '';
      setValues(copyValues);
    };

    return (
      <div>
        <input 
            type="text" 
            value={values.name} 
            onChange={handleChangeNameValue} 
            placeholder="Имя" 
        />
        <input
          type="text"
          value={values.lastName}
          onChange={handleChangeLastNameValue}
          placeholder="Фамилия"
        />
        <input
          type="text"
          value={values.secondName}
          onChange={handleChangeSecondNameValue}
          placeholder="Отчество"
        />
        <button onClick={handleClick}>Нажми на меня</button>
        <p>{values.fullName}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task7;
