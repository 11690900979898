import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [values, setValue] = useState('New York');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setValue(event.target.value);

  return (
    <div>
      <p>{values}</p>
      <select value={values} onChange={handleSelectChange}>
        <option>New York</option>
        <option>Moscow</option>
        <option>London</option>
        <option>Paris</option>
      </select>
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string =
    '4. Дан селект со списком городов (просто селект в HTML коде, option не из массива). Дан также абзац, в который выводится выбранный город. Сделай так, чтобы при смене города в селекте в абзаце он также менялся.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        selectedCity: 'New York',
      };
    }

    render() {
      return (
        <div>
          <p>{this.state.selectedCity}</p>
          <select
            value={this.state.selectedCity}
            onChange={(event) =>
              this.setState({ selectedCity: event.target.value })
            }
          >
            <option>New York</option>
            <option>Moscow</option>
            <option>London</option>
            <option>Paris</option>
          </select>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [values, setValue] = useState('New York');

    const handleSelectChange = (event) => setValue(event.target.value);

    return (
      <div>
        <p>{values}</p>
        <select value={values} onChange={handleSelectChange}>
          <option>New York</option>
          <option>Moscow</option>
          <option>London</option>
          <option>Paris</option>
        </select>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task4;
