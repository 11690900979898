import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  '@keyframes horizontally': {
    '0%': {
      left: '0',
    },
    '100%': {
      left: '90%',
    },
  },

  '@keyframes vertikal': {
    '0%': {
      top: '0',
    },
    '100%': {
      top: '90%',
    },
  },
  root: {
    paddingLeft: '10px',
    '& .vacancies__title': {
      width: 'fit-content',
      margin: '20px auto',
      color: PALITRA.red.redPink,
    },
    '& .vacancies__cards': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: '20px auto',

      '& > div:nth-child(1)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            top: '-17px',
            animation: '$horizontally 3.6s linear infinite alternate',
          },
        },
      },
      '& > div:nth-child(2)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            left: '-17px',
            animation: '$vertikal 3.8s linear infinite alternate',
          },
        },
      },
      '& > div:nth-child(3n)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            top: '100%',
            right: '-17px',
            animation: '$vertikal 5s linear infinite alternate',
          },
        },
      },
      '& > div:nth-child(4n)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            top: '93%',
            left: '95%',
            animation: '$horizontally 5s linear infinite alternate',
          },
        },
      },
      '& > div:nth-child(5n)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            left: '-17px',
            animation: '$vertikal 3.8s linear infinite alternate',
          },
        },
      },

      '& > div:nth-child(6n)': {
        '& .ant-card': {
          '&:before': {
            content: ' " "',
            position: 'absolute',
            display: 'block',
            width: '30px',
            height: '30px',
            border: '8px solid #FFF',
            background: '#FF6945',
            borderRadius: '50%',
            zIndex: '1',
            transition: 'border .5s ease-in-out',
            top: '93%',
            left: '95%',
            animation: '$horizontally 5s linear infinite alternate',
          },
        },
      },
    },
  },
});

export default useStyles;
