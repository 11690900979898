import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface EmployeesTableProps {
  data: {
    name: string;
    surname: string;
    salary: number;
  }[];
  onClickSort: (name: string) => void;
}

const EmployeesTable: FC<EmployeesTableProps> = ({ data, onClickSort }) => (
  <table>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.surname}</td>
          <td>{item.salary}</td>
        </tr>
      ))}
      <tr>
        <td>
          <button onClick={() => onClickSort('name')}>Соортировать</button>
        </td>
        <td>
          <button onClick={() => onClickSort('surname')}>Соортировать</button>
        </td>
        <td>
          <button onClick={() => onClickSort('salary')}>Соортировать</button>
        </td>
      </tr>
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [employees, setEmplyees] = useState([
    { name: 'Dan', surname: 'Williams', salary: 2000 },
    { name: 'Roberto', surname: 'Carlos', salary: 1000 },
    { name: 'Anna', surname: 'Gomez', salary: 4000 },
  ]);

  const handleClickSort = (name: string) => {
    const copyEmployees = [...employees];
    copyEmployees.sort((a, b) => (a[name] > b[name] ? 1 : -1));
    setEmplyees(copyEmployees);
  };

  return <EmployeesTable data={employees} onClickSort={handleClickSort} />;
};

const Task9: FC = () => {
  const taskTitle: string =
    '9. Дан стейт внутри которого массив с работниками. У каждого работника есть имя, фамилия, зарплата. Выведи этих работников на экран в виде таблицы. Сделай так, чтобы работников можно было посортировать по любой колонке этой таблицы.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        employees: [
          { name: 'Dan', surname: 'Williams', salary: 2000 },
          { name: 'Roberto', surname: 'Carlos', salary: 1000 },
          { name: 'Anna', surname: 'Gomez', salary: 4000 },
        ],
      };
    }

    handleClickSort(name) {
      const copyState = { ...this.state };
      copyState.employees.sort((a, b) => (a[name] > b[name] ? 1 : -1));

      this.setState(copyState);
    }

    render() {
      return (
        <EmployeesTable
          data={this.state.employees}
          onClickSort={this.handleClickSort.bind(this)}
        />
      );
    }
  }

  class EmployeesTable extends React.Component {
    render() {
      return (
        <table>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.surname}</td>
                <td>{item.salary}</td>
              </tr>
            ))}
            <tr>
              <td>
                <button onClick={() => this.props.onClickSort('name')}>
                  Соортировать
                </button>
              </td>
              <td>
                <button onClick={() => this.props.onClickSort('surname')}>
                  Соортировать
                </button>
              </td>
              <td>
                <button onClick={() => this.props.onClickSort('salary')}>
                  Соортировать
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const EmployeesTable = ({ data, onClickSort }) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.surname}</td>
            <td>{item.salary}</td>
          </tr>
        ))}
        <tr>
          <td>
            <button onClick={() => onClickSort('name')}>Соортировать</button>
          </td>
          <td>
            <button onClick={() => onClickSort('surname')}>Соортировать</button>
          </td>
          <td>
            <button onClick={() => onClickSort('salary')}>Соортировать</button>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const App = () => {
    const [employees, setEmplyees] = useState([
      { name: 'Dan', surname: 'Williams', salary: 2000 },
      { name: 'Roberto', surname: 'Carlos', salary: 1000 },
      { name: 'Anna', surname: 'Gomez', salary: 4000 },
    ]);

    const handleClickSort = (name) => {
      const copyEmployees = [...employees];
      copyEmployees.sort((a, b) => (a[name] > b[name] ? 1 : -1));
      setEmplyees(copyEmployees);
    };

    return <EmployeesTable data={employees} onClickSort={handleClickSort} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task9;
