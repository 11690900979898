import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [value, setValue] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.checked);

  return (
    <div>
      <input type="checkbox" checked={value} onChange={handleCheckboxChange} />
      <input type="text" disabled={!value} />
    </div>
  );
};

const Task12: FC = () => {
  const taskTitle: string =
    '12. Дан чекбокс и инпут. Сделай так, чтобы если чекбокс не отмечен - то инпут находится в заблокированном состоянии (через disabled, а если отмечен - то в незаблокированном.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        flag: false,
      };
    }

    render() {
      return (
        <div>
          <input
            type='checkbox'
            checked={this.state.flag}
            onChange={() => this.setState({ flag: !this.state.flag })}
          />
          <input type='text' disabled={!this.state.flag} />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [value, setValue] = useState(false);

    const handleCheckboxChange = (event) =>
      setValue(event.target.checked);

    return (
      <div>
        <input type="checkbox" checked={value} onChange={handleCheckboxChange} />
        <input type="text" disabled={!value} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task12;
