import React, { FC } from 'react';
import { PALITRA } from '../../../utils/constants';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const css = {
    color: 'green',
    border: `1px solid ${PALITRA.red.red}`,
    borderRadius: '30px',
  };

  return <div style={css}>текст</div>;
};

const Task7: FC = () => {
  const taskTitle: string =
    '7. Дан див с текстом. Установи этому диву зеленый цвет, красную границу и border-radius в 30px.';
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const css = {
        color: 'green',
        border: '1px solid red',
        borderRadius: '30px',
      };

      return <div style={css}>текст</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const css = {
      color: 'green',
      border: '1px solid red',
      borderRadius: '30px',
    };

    return <div style={css}>текст</div>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task7;
