import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [text, setText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setText(event.target.value);

  return (
    <div>
      <p>текст: {text}</p>
      <textarea value={text} onChange={handleChange} />
    </div>
  );
};

const Task1: FC = () => {
  const taskTitle: string =
    '1. Дан текстареа и абзац. Сделай так, чтобы при наборе текста в текстареа он автоматически появлялся в этом абзаце.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        text: '',
      };
    }

    handleChange(event) {
      this.setState({ text: event.target.value });
    }

    render() {
      return (
        <div>
          <p>текст: {this.state.text}</p>
          <textarea
            value={this.state.text}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [text, setText] = useState('');

    const handleChange = (event) => setText(event.target.value);

    return (
      <div>
        <p>текст: {text}</p>
        <textarea value={text} onChange={handleChange} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task1;
