import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const text = 'текст';
  return <div>{text}</div>;
};

const Task2: FC = () => {
  const taskTitle: string =
    "2. Пусть в компоненте есть переменная text с текстом 'текст'. С ее помощью выведи следующее:";
  const taskCode: string = `
  <div>
    текст
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      const text = 'текст';
      return <div>{text}</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const text = 'текст';
    return <div>{text}</div>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task2;
