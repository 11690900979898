import React from 'react';

import Adventage1 from '../Components/Icons/Adventage1';
import Adventage2 from '../Components/Icons/Adventage2';
import Adventage3 from '../Components/Icons/Adventage3';
import Adventage4 from '../Components/Icons/Adventage4';
import Adventage5 from '../Components/Icons/Adventage5';
import Adventage6 from '../Components/Icons/Adventage6';

import MobileWebIcon from '../Components/Icons/MobileAppIcon';
import WebAppIcon from '../Components/Icons/WepAppIcon';

export const errorDescription: string = 'Something went wrong!';
export const errorTitle: string = 'Oops...';
export const success: string = 'Success';
export const wrongEmail: string = 'Such mail already exists';
export const languageFunc = (t: (t: string) => void) => [
  {
    value: 'uk',
    label: (
      <>
        <img
          className="svgLanguage"
          src="/assets/icons/ukraine(UA).svg"
          width="20px"
          height="20px"
          alt="email icon"
        />
        {t('defaultMenu.uk')}
      </>
    ),
  },
  {
    value: 'en',
    label: (
      <>
        <img
          className="svgLanguage"
          src="/assets/icons/unitedKingdom(GB).svg"
          width="20px"
          height="20px"
          alt="email icon"
        />
        {t('defaultMenu.en')}
      </>
    ),
  },

  {
    value: 'rus',
    label: (
      <>
        <img
          className="svgLanguage"
          src="/assets/icons/rashka.png"
          width="20px"
          height="20px"
          alt="email icon"
        />
        {t('defaultMenu.rus')}
      </>
    ),
  },
];
export const PALITRA = {
  white: '#FFFFFF',
  black: '#2B2B2B',
  pink: '#BE054D',
  orange: '#FF6945',
  red: { red: '#FF0000', redPink: '#FF0057', redDark: '#A52A2A', redBrown: '#D44000' },
  yellow: '#FFD83E',
  mainBackground: '#F5F9FB',
  backgroundSection: '#FDFDFD',
  crimson: '#F13E5F',
  headerAndFooter: '#1A374D',
  menuBackground: '#001529',
  grey: { grey: '#545454', lightGrey1: '#D9D9D9', lightGrey2: '#D1D1D1' },
  green: {
    greenLight: '#28D4A8',
    greenLight2: '#73c57b',
    greenDark: '#1CB08A',
    greenGrey: '#56776C',
    greenBright: '#32CD32',
  },
  blue: {
    blue: '#3173CC',
    blueGrey: '#0077B7',
    blueDark: '#364d79',
    blueBlack: '#1a374d',
    blueBlackTransparent: 'rgb(26 55 77 / 15%)',
  },
  violet: { violet: '#b27fea', violetLight: '#FF00FF' },

  // old color
  iconBlack: '#46474B',
  mainFont: '#73738C',
  headerFont: '#353538',
  otherFont: '#AEAEAF',
  darkBlue: '#275CA3',
  lightblue: '#16A2DC',
  lightblue1: '#D0EBF8',
  lightBlue2: '#FBFCFE',
  error: '#E94B4B',
  success: '#8CE252',
};

export const DATA_HOW_IT_WORKS = [
  {
    id: 1,
    number: '-1-',
    title: 'Product Design',
    desc: `Great product design is an excellent starting point for a lovely     product. And it's not just about the UI. It's about creating personas, information architecture, interaction design, prototyping and only then visual design. Cooperating with you tightly, our design team can do all this and more.`,
  },
  {
    id: 2,
    number: '-2-',
    title: 'Product Engineering',
    desc: `When the design is in place, we engineer your product in short 1-2 week-long iterations, following the Agile methodology. This way, you can see the progress in almost real-time. It's vital for us to receive feedback consistently and adjust accordingly, which makes communication with the tech team critical for successful development and going live.`,
  },
  {
    id: 3,
    number: '-3-',
    title: 'Product Maintenance',
    desc: `After the product has gone live, you can focus on the core business while we're keeping an eye on your product's health. Our team will quickly react to every issue and do everything to keep the system's uptime as high as possible. On top of that, we can add new features and modify the existing ones to make the product even better.`,
  },
];

export const DATA_SERVICE = [
  {
    id: 1,
    icon: <WebAppIcon className="service-img" />,
    title: 'Web app development',
    content: `Our full-stack engineers develop custom mobile-first web products using the Agile methodology. You can start with a simple MVP to test your idea, smoothly pivot and then improve the app to become a high-load solution with millions of visits. With a responsive front end and a reliable back end, of course.`,
  },
  {
    id: 2,
    icon: <MobileWebIcon className="service-img" />,
    title: 'Mobile app development',
    content: `Excellent look and feel are essential for a successful mobile product, and that's what we focus on when developing iOS and Android apps for our clients. Whether it's a native or cross-platform app, we never compromise speed and mobile interaction design.`,
  },
];

export const DATA_ADVANTAGES = [
  {
    id: 1,
    title: 'Agile and Waterfall combination',
    icon: <Adventage1 className="advantage-icon" />,
    desc: `Software development is the process of continuous iterative improvement. Following the Agile principles, we also know when the best approaches of Waterfall can help.`,
  },
  {
    id: 2,
    title: 'Agile and Waterfall combination',
    icon: <Adventage2 className="advantage-icon" />,
    desc: `No matter how promising, every new technology needs time to prove itself. So, in our work, we rely on the ones that are reliable yet rapidly evolving.`,
  },
  {
    id: 3,
    title: 'Agile and Waterfall combination',
    icon: <Adventage3 className="advantage-icon" />,
    desc: `Delivering high-quality software requires the right mix of technical and soft skills. We carefully select professionals for each position and help them embrace our culture.`,
  },
  {
    id: 4,
    title: 'Agile and Waterfall combination',
    icon: <Adventage4 className="advantage-icon" />,
    desc: `Being a team doesn't require having a physical office. In fact, a remote team offers numerous benefits when you implement the management techniques we are good at.`,
  },
  {
    id: 5,
    title: 'Agile and Waterfall combination',
    icon: <Adventage5 className="advantage-icon" />,
    desc: `It's not enough to master technologies and tools. We wouldn't be able to produce great applications without understanding the business almost as well as the client.`,
  },
  {
    id: 6,
    title: 'Agile and Waterfall combination',
    icon: <Adventage6 className="advantage-icon" />,
    desc: `Our price is fair since we don't have a fancy office, top management expenses and don't invest much in advertising. After all, the best way to get new clients is through recommendations.`,
  },
];

export const DATA_CLIENTS = [
  {
    id: 1,
    src: '/assets/img/Clients/clientPage1.webp',
    name: 'WikiArt.org',
    desc: `The aim of the project was to make art accessible to anyone, anywhere. Today, WikiArt features over 250,000 artworks by 5,000 artists, localized to 8 languages. These artworks are displayed in museums, universities and town halls of more than 100 countries, yet most of it is not on public view. The client claims that they are planning to cover the entire art history of the Earth, from cave artworks to modern private collections.`,
    mirror: false,
    to: '#',
  },
  {
    id: 2,
    src: '/assets/img/Clients/clientPage2.webp',
    name: 'SmartLane',
    desc: `A B2B startup for car dealers who sell used vehicles across the USA via the marketplace. The developed application has a whole range of functions to support the workflows of car dealers and inspectors, like auctions workflow, live and proxy bidding, post-auction counter offering, and deal closing. The solution is integrated with different APIs to gather information about vehicles, helping dealers make the right decisions at auctions.`,
    mirror: true,
    to: '#',
  },
  {
    id: 3,
    src: '/assets/img/Clients/clientPage3.webp',
    name: 'Admit.me',
    desc: `Admit.me is a free virtual admissions coach that offers step-by-step guides through the admissions process of the best schools. The developed solution allows for generating personalized admissions programs based on the applicant's background and goals. Admit.me provides tools and lessons designed to optimize the applicant's admissions chances by framing the applicant's thinking and informing critical admissions decisions.`,
    mirror: false,
    to: '#',
  },
];

export const DATA_BLOG = [
  {
    title: 'UniCode Receives Clutch Award For Top Ukranian B2B Firm',
    desc: `We are thrilled to announce that Clutch have recognized us as one of the 250   best-performing software development companies in Ukraine.`,
    date: '26.05.2021',
    src: '/assets/img/Blog/blog1.webp',
  },

  {
    title: 'Tips for Outsourcing MVP Development. Part I',
    desc: `Project preparation, documentation and right tech stack — based on our case study of building an MVP in 3.5 months for the U.S. automotive startup`,
    date: '25.04.2021',
    src: '/assets/img/Blog/blog3.webp',
  },
  {
    title: 'Tips for Outsourcing MVP Development. Part II',
    desc: `Team, requirements management, sprint planning  estimation and communication for successful MVP delivery — based on our case study of building an MVP in 3.5 months for the U.S. automotive startup`,
    date: '16.05.2021',
    src: '/assets/img/Blog/blog2.webp',
  },
  {
    title: 'Is It Possible to Find 100+ Tech Specialists at Affordable Rates in a Fortnight? Yes!',
    desc: `We analyzed the market, various hiring channels, and payment models. Our experience has shown you CAN subcontract 100 specialists in a fortnight, but boy, will you have to work for it.`,
    date: '07.01.2021',
    src: '/assets/img/Blog/blog4.webp',
  },
];
