import React, { FC, useState } from 'react';
import { PALITRA } from '../../../utils/constants';
import ComponentTemplate from '../../Task';

interface SelectProps {
  colors: {
    colorValues: string[];
    selectValue: string;
  };
  funcSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: FC<SelectProps> = ({ colors, funcSelectChange }) => {
  return (
    <select value={colors.selectValue} onChange={funcSelectChange}>
      {colors.colorValues.map((item: string, index: number) => (
        <option style={{ backgroundColor: item }} key={index}>
          {item}
        </option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [colors, setColor] = useState({
    colorValues: [PALITRA.black, PALITRA.red.red, PALITRA.violet.violetLight],
    selectValue: PALITRA.black,
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setColor({ ...colors, selectValue: event.target.value });

  return (
    <div>
      <p style={{ color: colors.selectValue }}>Какой-то текст</p>
      <Select colors={colors} funcSelectChange={handleSelectChange} />
    </div>
  );
};

const Task8: FC = () => {
  const taskTitle: string =
    '8. Дан селект со списком CSS цветов. Дан абзац. Сделай так, чтобы при смене цвета абзац красился в выбранный цвет.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        colorValues: ['#000000', '#ff0000', '#ff00ff'],
        selectValue: '#000000',
      };
    }

    handleColorChange(value) {
      this.setState({
        ...this.state,
        selectValue: value,
      });
    }

    render() {
      return (
        <div>
          <p style={{ color: this.state.selectValue }}>Какой-то текст</p>
          <Select
            colors={this.state}
            handleColorChange={this.handleColorChange.bind(this)}
          />
        </div>
      );
    }
  }

  class Select extends React.Component {
    render() {
      return (
        <select
          value={this.props.colors.selectValue}
          onChange={(event) => this.props.handleColorChange(event.target.value)}
        >
          {this.props.colors.colorValues.map((item, index) => (
            <option style={{ backgroundColor: item }} key={index}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Select = ({ colors, funcSelectChange }) => {
    return (
      <select value={colors.selectValue} onChange={funcSelectChange}>
        {colors.colorValues.map((item, index) => (
          <option style={{ backgroundColor: item }} key={index}>
            {item}
          </option>
        ))}
      </select>
    );
  };

  const App = () => {
    const [colors, setColor] = useState({
      colorValues: ['#000000', '#ff0000', '#ff00ff'],
      selectValue: '#000000',
    });

    const handleSelectChange = (event) =>
      setColor({ ...colors, selectValue: event.target.value });

    return (
      <div>
        <p style={{ color: colors.selectValue }}>Какой-то текст</p>
        <Select colors={colors} funcSelectChange={handleSelectChange} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task8;
