import React from 'react';
import ComponentTemplate from '../../Task';

const Task2: React.FC = () => {
  const taskTitle: string =
    "2. Реализуй тест. Дан массив с вопросами и правильными ответами. Выведи все эти вопросы на экран, а под каждым вопросом - инпут. В этот инпут пользователь должен ввести правильный ответ на вопрос. Под всеми вопросами должна находиться кнопка 'сдать тест'. По нажатию на эту кнопку под вопросами вместо инпутов должно появится следующее: 'ваш ответ такой-то, правильно' или 'ваш ответ такой-то, не правильно, правильный ответ такой-то'. Правильные ответы должны быть зеленого цвета, а неправильные - красного.";

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task2;
