import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const showMessage = () => {
    alert('hello');
  };

  return <button onClick={showMessage}>нажми на меня</button>;
};

const Task2: FC = () => {
  const taskTitle: string =
    "2. Сделай кнопку, по нажатию на которую сработает функция showMessage и на экран алертом выведется сообщение с текстом 'hello'.";
  const taskClassSolution: string = `
  class App extends React.Component {
    showMessage() {
      alert('hello');
    }

    render() {
      return <button onClick={this.showMessage}>нажми на меня</button>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const showMessage = () => {
      alert('hello');
    };

    return <button onClick={showMessage}>нажми на меня</button>;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task2;
