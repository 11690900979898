import classNames from 'classnames';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Container from '../../Components/Container';
import { getNameImage } from '../../utils/common';

import useStyles from './style';

interface BlogPage3Props {
  id: number;
  info: DATA_BLOG_ITEM;
  clas: string;
}

const BlogPage3: FC<BlogPage3Props> = ({ id, info, clas }) => {
  const classes = useStyles();

  const history = useHistory();

  const prevPart = () => {
    const url = history.location.pathname.split('/');
    url[url.length - 1] = String(Number(url[url.length - 1]) - 1);
    history.push(url.join('/'));
  };

  return (
    <Container className={classNames(clas, classes.root)}>
      <Helmet>
        <meta name="description" content={info.desc} />
        <title>{info.title}</title>
      </Helmet>
      <div className="blog-wrap">
        <h2 className="blog-title">{info.title}</h2>
        <div className="blog-date">{info.date}</div>
        <div className="blog-image margin-bottom">
          <img src={info.src} alt={getNameImage(id)} className="blog-img" />
        </div>
        <div className="blog-text">
          <p className="blog-text-p">
            In the <span onClick={prevPart}>previous part</span> previous part of this article, we
            talked about the Lean approach, and why successful startups use it to accelerate time to
            market. We’ve also defined a minimal viable product (MVP) and gave the lowdown on the
            preparation to be made before the MVP development.
          </p>
          <p className="blog-text-p">
            In this part, we’ll get down to the nitty-gritty of the development process, along with
            practical tips on building a development team through the lens of a project we did for
            an American startup SmartLane. Read on to get clear instructions on how to realize your
            product idea in the MVP and make it attractive for potential users.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default BlogPage3;
