import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '&.how-work-item': {
      boxSizing: 'border-box',
      paddingBottom: '25px',
      borderBottom: `1px solid ${PALITRA.grey.lightGrey2} `,
      maxWidth: '95%',
      margin: '0 auto',

      '&:last-child': {
        borderBottom: '0px',
        paddingBottom: '20px',
      },

      '&>span': {
        display: 'inline-block',
        fontSize: '16px',
        fontWeight: '600',
        letterSpacing: '2.5px',
        marginBottom: '5px',
      },

      '& .how-work-item-title': {
        fontSize: '22px',
        fontWeight: '600',
      },

      '& .how-work-item-description': {
        fontSize: '16px',
        fontWeight: '300',
      },

      '@media screen and (min-width: 900px)': {
        paddingRight: '25px',
        borderRight: `1px solid ${PALITRA.grey.lightGrey2} `,

        paddingBottom: '0px',
        borderBottom: '0px',
        maxWidth: '100%',

        '&:last-child': {
          borderRight: '0px',
          paddingRight: '0px',
        },
      },

      '@media screen and (max-width: 576px)': {
        '&>span': {
          fontSize: '12px',
          fontWeight: '600',
          letterSpacing: '2.5px',
          marginBottom: '0px',
        },

        '& .how-work-item-title': {
          fontSize: '18px',
        },

        '& .how-work-item-description': {
          fontSize: '14px',
        },
      },
    },
  },
});

export default useStyles;
