import React from 'react';

import Lesson1 from '../Components/Lesson1';
import Lesson2 from '../Components/Lesson2';
import Lesson3 from '../Components/Lesson3';
import Lesson4 from '../Components/Lesson4';
import Lesson5 from '../Components/Lesson5';
import Lesson6 from '../Components/Lesson6';
import Lesson7 from '../Components/Lesson7';
import Lesson8 from '../Components/Lesson8';
import Lesson9 from '../Components/Lesson9';
import Lesson10 from '../Components/Lesson10';

const lessons = [
  <Lesson1 />,
  <Lesson2 />,
  <Lesson3 />,
  <Lesson4 />,
  <Lesson5 />,
  <Lesson6 />,
  <Lesson7 />,
  <Lesson8 />,
  <Lesson9 />,
  <Lesson10 />,
];

const lessonsQuanity: number = lessons.length;

export default lessonsQuanity;
