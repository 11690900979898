import { storageService } from '../services/StorageService';

class AuthService {
  logoutUser() {
    storageService.remove('access_token');
  }

  // async registerUser(body: RegisterUserProps) {
  //   await API.post(
  //     '/register',
  //     queryString.stringify({
  //       ...body,
  //     }),
  //   );
  // }

  // async loginUser(body: LoginUserProps) {
  //   return await API.post(
  //     '/login',
  //     queryString.stringify({
  //       ...body,
  //     }),
  //   );
  // }

  isLogin = (): boolean => !!storageService.get('access_token');
}

export const authService = new AuthService();
