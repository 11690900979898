import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface SelectProps {
  data: {
    paras: string[];
    currentPara: string;
  };
  changeFunc: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: FC<SelectProps> = ({ data, changeFunc }) => {
  return (
    <select value={data.currentPara} onChange={changeFunc}>
      {data.paras.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [paragraphs, setParagraphs] = useState({
    paras: ['Это первый параграф', 'Это второй параграф', 'Это третий параграф'],
    currentPara: 'Это первый параграф',
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setParagraphs({ ...paragraphs, currentPara: event.target.value });

  return (
    <div>
      <Select data={paragraphs} changeFunc={handleSelectChange} />
      <p>{paragraphs.currentPara}</p>
    </div>
  );
};

const Task10: React.FC = () => {
  const taskTitle: string =
    '10. Дан селект и 3 абзаца. В селекте можно выбрать один из трех пунктов. Сделай так, чтобы в зависимости от выбора на экране был виден один из трех абзацев.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        paragraphs: [
          'Это первый параграф',
          'Это второй параграф',
          'Это третий параграф',
        ],
        currentParagraph: 'Это первый параграф',
      };
    }

    render() {
      return (
        <div>
          <Select data={this.state} setData={this.setState.bind(this)} />
          <p>{this.state.currentParagraph}</p>
        </div>
      );
    }
  }

  class Select extends React.Component {
    render() {
      return (
        <select
          value={this.props.data.currentParagraph}
          onChange={(event) =>
            this.props.setData({
              ...this.props.data,
              currentParagraph: event.target.value,
            })
          }
        >
          {this.props.data.paragraphs.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Select = ({ data, changeFunc }) => {
    return (
      <select value={data.currentPara} onChange={changeFunc}>
        {data.paras.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    );
  };

  const App = () => {
    const [paragraphs, setParagraphs] = useState({
      paras: ['Это первый параграф', 'Это второй параграф', 'Это третий параграф'],
      currentPara: 'Это первый параграф',
    });

    const handleSelectChange = (event) =>
      setParagraphs({ ...paragraphs, currentPara: event.target.value });

    return (
      <div>
        <Select data={paragraphs} changeFunc={handleSelectChange} />
        <p>{paragraphs.currentPara}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task10;
