import React from 'react';
import ComponentTemplate from '../../Task';

const Task7: React.FC = () => {
  const taskTitle: string =
    "7. Реализуй органайзер. Он должен представлять собой календарь за текущий месяц. По нажатию на определенную дату календаря мы должны увидеть список дел, запланированных на этот день. Каждое дело можно поредактировать, отметить сделанным или удалить, можно также добавить новое дело. Над календарем должны быть стрелочки 'назад' и 'вперед', с помощью которых можно сменить месяц и год в календаре.";

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task7;
