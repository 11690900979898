import classNames from 'classnames';
import React, { FC } from 'react';

import useStyles from './style';

interface AdventageCardProps {
  title: string;
  icon: any;
  desc: string;
}

const AdventageCard: FC<AdventageCardProps> = ({ title, icon, desc }) => {
  const classes = useStyles();

  return (
    <div className={classNames('advantage-item', classes.root)}>
      <div className="advantage-item-wrapper">
        {icon}
        <h3 className="adventage-item-title">{title}</h3>
      </div>
      <p className="advantage-item-description">{desc}</p>
    </div>
  );
};

export default AdventageCard;
