import React, { FC } from 'react';

import LessonListTemplate from '../LessonListTemplate';
import Task1 from './Task1';
import Task2 from './Task2';
import Task3 from './Task3';
import Task4 from './Task4';
import Task5 from './Task5';
import Task6 from './Task6';
import Task7 from './Task7';

const tasks = [<Task1 />, <Task2 />, <Task3 />, <Task4 />, <Task5 />, <Task6 />, <Task7 />];

export const tasksQuantity3: number = tasks.length;

const LessonList: FC = () => <LessonListTemplate tasks={tasks} lesson="3" />;

export default LessonList;
