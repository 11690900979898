import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',

    '@media screen and (max-width: 900px)': {
      flexDirection: 'column',
    },

    '& .userInfo__block': {
      width: '40%',

      '& .name__heading': {
        fontSize: '25px',
        fontWeight: '800',
        margin: '12px 0',
      },
      '& .email__wrapper': {
        display: 'flex',
        alignItems: 'center',

        '& h3': {
          padding: '5px 0 0 10px',
        },
      },
      '& .github__wrapper': {
        display: 'flex',
        alignItems: 'center',

        '& img': {
          marginLeft: '-5px',
        },
        '& a': {
          padding: '5px 0 0 5px',
        },
      },
    },
    '& .spin__wrapper': {
      width: 'fit-content',
      margin: '20% auto',
    },
  },
});

export default useStyles;
