import React, { FC } from 'react';
import { Card } from 'antd';

import useStyle from './style';

type Props = {
  vacancy: string;
  vacancyDescription: string;
};

const VacancyСard: FC<Props> = ({ vacancy, vacancyDescription }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Card title={vacancy} bordered={false}>
        <p className="vacancy-card__text">{vacancyDescription}</p>
      </Card>
    </div>
  );
};

export default VacancyСard;
