import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  return <div>текст</div>;
};

const Task1: FC = () => {
  const taskTitle: string = '1. Сделай так, чтобы компонент вывел на экран следующее:';
  const taskCode: string = `
  <div>
    текст
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    render() {
      return <div>текст</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    return <div>текст</div>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task1;
