import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    minHeight: '220px',
    padding: '24px',
    paddingBottom: '44px',
    background: PALITRA.backgroundSection,
    maxWidth: '1024px',
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '28px',
    boxShadow: '0px 0px 8px rgba(237, 237, 237, 0.5)',
    borderRadius: '3px',

    '@media screen and (max-width: 1024px)': {
      margin: '0px 15px 28px 15px',
      padding: '14px',
    },
  },
});

export default useStyles;
