import React, { FC } from 'react';

const MobileWebIcon: FC<IconProps> = ({ ...props }) => (
  <svg {...props} id="Слой_2" data-name="Слой 2" xmlns="http://www.w3.org/2000/svg">
    <title>Монтажная область 24</title>
    <path d="M72.17,7H27.83A4.71,4.71,0,0,0,23,11.57V89.43A4.71,4.71,0,0,0,27.83,94H72.17A4.71,4.71,0,0,0,77,89.43V11.57A4.71,4.71,0,0,0,72.17,7ZM26,20H74V76H26Zm1.83-10H72.17A1.72,1.72,0,0,1,74,11.57V17H26V11.57A1.72,1.72,0,0,1,27.83,10ZM72.17,91H27.83A1.72,1.72,0,0,1,26,89.43V79H74V89.43A1.72,1.72,0,0,1,72.17,91Z" />
    <circle cx="50.5" cy="85.5" r="2.5" />
    <path d="M53.67,41.66a1.5,1.5,0,0,0-2,.67l-6,12a1.5,1.5,0,0,0,2.68,1.34l6-12A1.5,1.5,0,0,0,53.67,41.66Z" />
    <path d="M68.56,47.94l-7-7a1.5,1.5,0,0,0-2.12,2.12L65.38,49l-5.94,5.94a1.5,1.5,0,1,0,2.12,2.12l7-7A1.5,1.5,0,0,0,68.56,47.94Z" />
    <path d="M40.48,41.24a1.5,1.5,0,0,0-2.12,0l-7,7a1.5,1.5,0,0,0,0,2.12l7,7a1.5,1.5,0,0,0,2.12-2.12l-6-6,6-6A1.5,1.5,0,0,0,40.48,41.24Z" />
  </svg>
);

export default MobileWebIcon;
