import React, { FC } from 'react';

const Adventage4: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 101 101">
    <path
      d="M85.1,72.7C89.5,66.2,92,58.4,92,50C92,27.4,73.6,9,51,9S10,27.4,10,50s18.4,41,41,41c13.8,0,26-6.9,33.5-17.4
	c0.1-0.1,0.1-0.1,0.2-0.2C84.8,73.3,85,73,85.1,72.7z M89,50c0,4.2-0.7,8.2-1.9,11.9c0.1-3.8-0.5-7.6-2.2-9.7
	c-1.8-2.2-4.1-2.1-5.9-1.9c-2.5,0.2-4.9,0.4-7.7-5.5c-2.2-4.7-0.1-6.5,3.5-8.9c2.6-1.7,6-4.1,3.6-8.4c-2.2-3.9-5.5-3.8-8.2-3.8
	c-1.7,0-3.3,0-4.7-0.9c-2.2-1.4-3.9-4.4-5.6-7.2c-0.6-1-1.2-2-1.7-2.9C75.8,16.2,89,31.6,89,50z M40.4,13.5c0,0,0.1,0.1,0.1,0.1
	c2.3,1.9,8,7.8,7.4,12.8c-0.2,1.7-3.6,3.1-6.5,4.5c-4.1,1.8-8.8,3.9-9.7,8c-0.6,2.7,0.6,5.7,3.8,9.3c7.2,8.2,13.2,10,17.6,11.4
	c3.3,1,5.5,1.7,7,4.6c1.9,3.5,0.5,4.9-3.3,8.2c-3.5,3.1-8.3,7.3-8.9,15.6c-2.5-0.2-4.9-0.6-7.3-1.3c0.4-12-4.7-15.9-8.5-18.8
	c-1.2-0.9-2.3-1.8-2.9-2.7c-0.5-0.8,0.6-2.7,1.6-4.4c1.5-2.6,3.2-5.6,2.1-8.5c-0.8-2.1-2.8-3.6-6.2-4.8c-11.4-3.8-9.9-14-9.8-14.4
	c0,0,0,0,0-0.1C21.8,23.6,30.2,16.5,40.4,13.5z M13,50c0-3.8,0.6-7.5,1.6-10.9c1.1,4,4,8.8,11.3,11.2c2.5,0.8,4,1.8,4.4,3
	c0.6,1.6-0.7,3.8-1.9,6c-1.5,2.5-3,5.2-1.6,7.5c0.8,1.4,2.2,2.4,3.6,3.5c3.3,2.5,7.4,5.6,7.3,15.4C23.3,80.2,13,66.3,13,50z M51,88
	C51,88,51,88,51,88c0.5-7,4.4-10.4,7.8-13.5c3.4-3,7-6.2,3.9-11.9c-2.2-4-5.2-4.9-8.8-6c-4.2-1.3-9.5-3-16.2-10.5
	c-2.4-2.7-3.5-5-3.1-6.7c0.6-2.6,4.5-4.3,8-5.9c4-1.8,7.9-3.5,8.3-6.8c0.7-5.8-4.4-11.6-7-14.1c2.3-0.4,4.7-0.7,7.1-0.7
	c1.1,0,2.2,0.1,3.2,0.1c0.1,0.1,0.1,0.2,0.2,0.2c1,1.1,2.1,2.9,3.2,4.7c1.7,3,3.7,6.4,6.5,8.2c2.1,1.4,4.3,1.4,6.3,1.3
	c2.8,0,4.3,0.1,5.5,2.3c1,1.7,0.3,2.4-2.7,4.5c-3.2,2.1-7.9,5.4-4.6,12.7c3.6,7.8,7.6,7.5,10.6,7.2c1.7-0.1,2.5-0.2,3.3,0.8
	C85.1,57.3,84,68,82.7,71C75.9,81.2,64.2,88,51,88z"
    />
  </svg>
);

export default Adventage4;
