import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [option, setOption] = useState('1');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setOption(event.target.value);

  return (
    <div>
      <p>{option}</p>
      <input type="radio" onChange={handleRadioChange} checked={option === '1'} value="1" />
      <input type="radio" onChange={handleRadioChange} checked={option === '2'} value="2" />
      <input type="radio" onChange={handleRadioChange} checked={option === '3'} value="3" />
    </div>
  );
};

const Task6: FC = () => {
  const taskTitle: string =
    '6. Даны 3 радиокнопки со значениями 1, 2 и 3. Дан абзац. Сделай так, чтобы значение выбранной радиокнопки выводилось в этот абзац.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        selectedBtn: '1',
      };
    }

    handleRadioChange(value) {
      this.setState({ selectedBtn: value });
    }

    render() {
      return (
        <div>
          <p>{this.state.selectedBtn}</p>
          <input
            type='radio'
            onChange={(event) => this.handleRadioChange(event.target.value)}
            checked={this.state.selectedBtn === '1'}
            value='1'
          />
          <input
            type='radio'
            onChange={(event) => this.handleRadioChange(event.target.value)}
            checked={this.state.selectedBtn === '2'}
            value='2'
          />
          <input
            type='radio'
            onChange={(event) => this.handleRadioChange(event.target.value)}
            checked={this.state.selectedBtn === '3'}
            value='3'
          />
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [option, setOption] = useState('1');

    const handleRadioChange = (event) => setOption(event.target.value);

    return (
      <div>
        <p>{option}</p>
        <input type="radio" onChange={handleRadioChange} checked={option === '1'} value="1" />
        <input type="radio" onChange={handleRadioChange} checked={option === '2'} value="2" />
        <input type="radio" onChange={handleRadioChange} checked={option === '3'} value="3" />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task6;
