import React, { useState } from 'react';

import { Link, useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Vacancies from '../Vacancies';
import useStyles from './style';
import { authService } from '../../services/AuthService';
import { AboutUsModal } from '../AboutUsModal';
import { SelectLanguage } from '../Selectanguage';
import Pages from '../../utils/pages';

const GeneralSecton = () => {
  const history = useHistory();
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [currentModal, setCurrentModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { t } = useTranslation();

  const openModal = (flag: boolean) => {
    setIsModalOpen(true);
    setCurrentModal(flag);
  };

  return (
    <header className="header" id="header">
      <nav className="header__nav">
        <ul>
          {authService.isLogin() && (
            <li
              onClick={() => {
                history.push(Pages.PROFILE);
              }}>
              {t('defaultMenu.profile')}
            </li>
          )}
          <Link to={Pages.LESSONS} className="header__link">
            <li>{t('defaultMenu.lessons')}</li>
          </Link>
          <Link to={Pages.BLOG} className="header__link">
            <li>{t('generalSection.blog')}</li>
          </Link>
          <li onClick={() => openModal(false)}>{t('generalSection.vacancies')}</li>
          <Link to={Pages.TIC_TAC_TOE} className="header__link">
            <li>Tic tac toe</li>
          </Link>
          <Link to={Pages.BATTLESHIP} className="header__link">
            <li>Battleship</li>
          </Link>
          <li onClick={() => openModal(true)}>{t('generalSection.aboutUs')}</li>
          {authService.isLogin() && (
            <li
              onClick={() => {
                authService.logoutUser();
                history.push(Pages.HOME);
              }}>
              {t('defaultMenu.logout')}
            </li>
          )}
        </ul>
        <div className="copyright">
          <div className={classes.selectLanguage}>
            <SelectLanguage styleForSelect={{ bg: '#ffffff', colorText: '#000' }} />
          </div>
          <p>&#169; {`All rights reserved to Unicode studio ${currentYear}`}</p>
        </div>
      </nav>
      <div className="header__actions">
        {!authService.isLogin() && (
          <Link to={Pages.LOGIN}>
            <button className="btn btn-outline-white">{t('defaultMenu.login')}</button>
          </Link>
        )}

        <button className="header__hamburger" id="btn-header">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <Modal isModalOpen={isModalOpen} handleCancel={handleCancel}>
        {currentModal ? <AboutUsModal /> : <Vacancies setIsVacanciesModalOpen={setIsModalOpen} />}
      </Modal>
    </header>
  );
};
export default withRouter(GeneralSecton);
