import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const [person, setPerson] = useState({ name: 'Иван', age: 25 });

  const changeName = () => {
    setPerson({ name: 'Коля', age: 30 });
  };

  return (
    <div>
      <div>
        имя: {person.name}, возраст: {person.age}
      </div>
      <button onClick={changeName}>нажми на меня</button>
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string = '4. Дан следующий стейт:';
  const taskCode: string = `
    const [person, setPerson] = useState({name: 'Иван', age: 25});
  `;

  const taskTitle2: string = `Изначально компонент выводит на экран следующее:`;
  const taskCode2: string = `
  <div>
    имя: Иван, возраст: 25
  </div>
  `;
  const taskTitle3 =
    "Сделай кнопку, по нажатию на которую 'Иван' поменяется на 'Коля', а 25 поменяется на 30.";
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25 };

    changeName = () => {
      this.setState({ name: 'Коля', age: 30 });
    };

    render() {
      return (
        <div>
          <div>
            имя: {this.state.name}, возраст: {this.state.age}
          </div>
          <button onClick={this.changeName}>нажми на меня</button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [state, setState] = useState({ name: 'Иван', age: 25 });

    const changeName = () => {
      setState({ name: 'Коля', age: 30 });
    };

    return (
      <div>
        <div>
          имя: {state.name}, возраст: {state.age}
        </div>
        <button onClick={changeName}>нажми на меня</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
        taskCode2={taskCode2}
        taskTitle3={taskTitle3}
      />
    </div>
  );
};
export default Task4;
