import classNames from 'classnames';
import React, { FC } from 'react';

import useStyles from './style';

interface HowItWorksCardProps {
  title: string;
  desc: string;
  number: string;
}

const HowItWorksCard: FC<HowItWorksCardProps> = ({ title, desc, number }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, 'how-work-item')}>
      <span>{number}</span>
      <h3 className="how-work-item-title">{title}</h3>
      <p className="how-work-item-description">{desc}</p>
    </div>
  );
};

export default HowItWorksCard;
