import React, { FC } from 'react';

const Adventage3: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 99.313 99.313">
    <g>
      <path
        d="M32.781,53.065c-16.576,0-30.062,13.486-30.062,30.062c0,0.829,0.672,1.5,1.5,1.5h57.128
		c0.828,0,1.5-0.671,1.5-1.5C62.847,66.551,49.359,53.065,32.781,53.065z M5.76,81.627C6.541,67.4,18.363,56.065,32.781,56.065
		c14.42,0,26.243,11.335,27.024,25.562H5.76z"
      />
      <path
        d="M33.284,49.149c9.598,0,17.405-7.809,17.405-17.408c0-9.597-7.808-17.404-17.405-17.404
		c-9.599,0-17.408,7.808-17.408,17.404C15.876,41.34,23.686,49.149,33.284,49.149z M33.284,17.336
		c7.943,0,14.405,6.462,14.405,14.404c0,7.945-6.462,14.408-14.405,14.408c-7.944,0-14.408-6.463-14.408-14.408
		C18.876,23.798,25.34,17.336,33.284,17.336z"
      />
      <path
        d="M73.51,60.397c-4.054,0-8.038,1.083-11.521,3.132c-0.714,0.42-0.952,1.339-0.532,2.053
		c0.42,0.715,1.34,0.952,2.054,0.533c3.022-1.778,6.48-2.718,10-2.718c10.376,0,18.908,8.049,19.677,18.23H68.34
		c-0.828,0-1.5,0.671-1.5,1.5s0.672,1.5,1.5,1.5h26.404c0.828,0,1.5-0.671,1.5-1.5C96.243,70.594,86.045,60.397,73.51,60.397z"
      />
      <path
        d="M73.876,55.902c7.348,0,13.325-5.978,13.325-13.324c0-7.349-5.978-13.327-13.325-13.327
		c-7.345,0-13.32,5.979-13.32,13.327C60.556,49.924,66.531,55.902,73.876,55.902z M73.876,32.25c5.693,0,10.325,4.633,10.325,10.327
		c0,5.693-4.632,10.324-10.325,10.324c-5.69,0-10.32-4.631-10.32-10.324C63.556,36.883,68.186,32.25,73.876,32.25z"
      />
    </g>
  </svg>
);

export default Adventage3;
