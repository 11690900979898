import classNames from 'classnames';
import React, { FC } from 'react';

import useStyles from './style';

interface ServiceCardProps {
  icon: any;
  title: string;
  content: string;
}

const ServiceCard: FC<ServiceCardProps> = ({ icon, title, content }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, 'item')}>
      {icon}
      <h3 className="service-subtitle">{title}</h3>
      <div className="service-content">{content}</div>
    </div>
  );
};

export default ServiceCard;
