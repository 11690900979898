import React, { ChangeEvent, FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface CitiesProps {
  arr: string[];
  currentCityInput: string;
  currentCitySelect: string;
}

interface CitiesSelectProps {
  data: CitiesProps;
  setData: React.Dispatch<
    React.SetStateAction<{
      arr: never[] | string[];
      currentCityInput: string;
      currentCitySelect: string;
    }>
  >;
}

const CitiesSelect: FC<CitiesSelectProps> = ({ data, setData }) => {
  const handleSelectCityChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const copyData = { ...data };
    copyData.currentCitySelect = event.target.value;

    setData(copyData);
  };

  return (
    <select value={data.currentCitySelect} onChange={handleSelectCityChange}>
      {data.arr.map((item) => (
        <option key={item}>{item}</option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [cities, setCities] = useState<CitiesProps>({
    arr: [],
    currentCityInput: '',
    currentCitySelect: '',
  });

  const handleCityInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const copyCities = { ...cities };

    copyCities.currentCityInput = event.target.value;

    setCities({ ...copyCities });
  };

  const handleAddCityClick = () => {
    const copyCities = { ...cities };

    copyCities.arr.push(copyCities.currentCityInput);
    copyCities.currentCitySelect = copyCities.currentCityInput;
    copyCities.currentCityInput = '';

    setCities({ ...copyCities });
  };

  return (
    <div>
      <input type="text" value={cities.currentCityInput} onChange={handleCityInputChange} />

      <button onClick={() => cities.currentCityInput !== '' && handleAddCityClick()}>
        Добавить город
      </button>

      <CitiesSelect data={cities} setData={setCities} />

      <p>{cities.currentCitySelect}</p>
    </div>
  );
};

const Task14: FC = () => {
  const taskTitle: string =
    '14. Дан селект. Изначально он пустой. Дан инпут и кнопка. В этот инпут вводится название города. По нажатию на кнопку этот город должен попасть в селект. Пользователь нашего скрипта добавляет несколько городов в селект, затем выбирает один из добавленных городов - и этот город должен отобразиться на экране в каком-нибудь абзаце.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        cities: {
          arr: [],
          currentCityInput: '',
          currentCitySelect: '',
        },
      };
    }

    handleCityInputChange(value) {
      const copyState = { ...this.state };

      copyState.cities.currentCityInput = value;

      this.setState(copyState);
    }

    handleAddCityClick() {
      const copyState = { ...this.state };

      copyState.cities.arr.push(copyState.cities.currentCityInput);
      copyState.cities.currentCitySelect = copyState.cities.currentCityInput;
      copyState.cities.currentCityInput = '';

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <input
            type='text'
            value={this.state.cities.currentCityInput}
            onChange={(event) => this.handleCityInputChange(event.target.value)}
          />

          <button
            onClick={() =>
              this.state.cities.currentCityInput !== '' &&
              this.handleAddCityClick()
            }
          >
            Добавить город
          </button>

          <CitiesSelect
            data={this.state.cities}
            setData={this.setState.bind(this)}
          />

          <p>{this.state.cities.currentCitySelect}</p>
        </div>
      );
    }
  }

  class CitiesSelect extends React.Component {
    handleSelectCityChange(value) {
      const copyData = { ...this.props.data };
      copyData.currentCitySelect = value;

      this.props.setData({ cities: copyData });
    }

    render() {
      return (
        <select
          value={this.props.data.currentCitySelect}
          onChange={(event) => this.handleSelectCityChange(event.target.value)}
        >
          {this.props.data.arr.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const CitiesSelect = ({ data, setData }) => {
    const handleSelectCityChange = (event) => {
      const copyData = { ...data };
      copyData.currentCitySelect = event.target.value;

      setData(copyData);
    };

    return (
      <select value={data.currentCitySelect} onChange={handleSelectCityChange}>
        {data.arr.map((item) => (
          <option key={item}>{item}</option>
        ))}
      </select>
    );
  };

  const App = () => {
    const [cities, setCities] = useState({
      arr: [],
      currentCityInput: '',
      currentCitySelect: '',
    });

    const handleCityInputChange = (event) => {
      const copyCities = { ...cities };

      copyCities.currentCityInput = event.target.value;

      setCities({ ...copyCities });
    };

    const handleAddCityClick = () => {
      const copyCities = { ...cities };

      copyCities.arr.push(copyCities.currentCityInput);
      copyCities.currentCitySelect = copyCities.currentCityInput;
      copyCities.currentCityInput = '';

      setCities({ ...copyCities });
    };

    return (
      <div>
        <input type="text" value={cities.currentCityInput} onChange={handleCityInputChange} />
        
        <button onClick={() => cities.currentCityInput !== '' && handleAddCityClick()}>
          Добавить город
        </button>

        <CitiesSelect data={cities} setData={setCities} />

        <p>{cities.currentCitySelect}</p>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task14;
