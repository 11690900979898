import React from 'react';

import Login from '../../Components/Login';
import Home from '../../Pages/Home';
import SignUp from '../../Components/SingUp';
import TicTacToe from '../../Components/TicTacToe';
import { OldHomePage } from '../../Pages/OldHomeOage';
import Pages from '../../utils/pages';
import Blog from '../../Components/Blog';
import Battleship from '../../Components/Battleship';
import Profile from '../../Components/Profile';
import PrivateRoute from '../PrivateRoute';
import Breadcrumbs from '../../Components/Breadcrumbs';
import Middleware from '../../Components/Middleware';
import Lessons from '../../Components/Middleware/lessons';
import BlogFullInfo from '../../Components/BlogFullInfo';
import LessonsPage from '../../Pages/ Lessons';
import { RouteProps } from 'react-router-dom';

export const ROUTES: IROUTE_MODEL<RouteProps['children']>[] = [
  { path: Pages.SiGN_UP, element: <SignUp />, exact: false },
  { path: Pages.LOGIN, element: <Login />, exact: false },
  { path: Pages.OLD_HOME_PAGE, element: <OldHomePage />, exact: true },
  { path: Pages.HOME, element: <Home />, exact: true },
  { path: Pages.TIC_TAC_TOE, element: <TicTacToe />, exact: true },
  { path: Pages.BLOG, element: <Blog />, exact: true },
  { path: Pages.BATTLESHIP, element: <Battleship />, exact: true },
  { path: Pages.PROFILE, element: <Profile />, exact: true },
  {
    path: Pages.PROFILE,
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
    exact: true,
  },
  {
    path: Pages.TASK,
    element: (
      <PrivateRoute>
        <Breadcrumbs />
        <Middleware />
      </PrivateRoute>
    ),
    exact: true,
  },
  {
    path: Pages.LESSON,
    element: (
      <PrivateRoute>
        <Breadcrumbs />
        <Lessons />
      </PrivateRoute>
    ),
    exact: true,
  },
  { path: Pages.BLOG_ARTICLE, element: <BlogFullInfo />, exact: true },
  { path: Pages.LESSONS, element: <LessonsPage />, exact: true },
];
