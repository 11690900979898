import React from 'react';
import ComponentTemplate from '../../Task';

const Task4: React.FC = () => {
  const taskTitle: string =
    '4. Модифицируй предыдущую задачу так, чтобы пользователь мог выбрать один из вариантов ответа с помощью 4-х радио кнопок. Варианты ответов также должны храниться в массиве c вопросами (придумай удобную структуру массива, чтобы там лежал и вопрос, и правильный ответ, и варианты ответов).';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task4;
