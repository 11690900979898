import React, { useState, FC } from 'react';
import VacancyСard from './Card';
import FeedbackForm from '../Forms/Feedback';
import Modal from '../Modal';

import useStyle from './style';

const VACANCIES = [
  {
    vacancy: 'Junior frontend developer',
    vacancyDescription:
      'Our friendly company is urgently required Fullstack PHP Developer. All terms and conditions will be discussed at the interview.',
  },
  {
    vacancy: 'Senior frontend developer',
    vacancyDescription:
      'Our friendly company is urgently required Fullstack PHP Developer. All terms and conditions will be discussed at the interview. If you need a job leave a request and we will call you back',
  },
];

type Props = {
  setIsVacanciesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Vacancies: FC<Props> = ({ setIsVacanciesModalOpen }) => {
  const classes = useStyle();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
    setIsVacanciesModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={classes.root}>
      <h1 className="vacancies__title">Vacancies</h1>
      <div className="vacancies__cards">
        {VACANCIES.map((vacancy, index) => (
          <div key={index} onClick={openModal}>
            <VacancyСard
              vacancy={vacancy.vacancy}
              vacancyDescription={vacancy.vacancyDescription}
            />
          </div>
        ))}
      </div>
      <Modal isModalOpen={isModalOpen} handleCancel={handleCancel}>
        <FeedbackForm handleCancel={handleCancel} handleOk={handleOk} />
      </Modal>
    </div>
  );
};

export default Vacancies;
