import React from 'react';
import Container from '../Container';
import useStyle from './style';

const AboutUs = () => {
  const classes = useStyle();

  return (
    <Container className={classes.root}>
      <div className="wrapper">
        <div className="about-content">
          <h2 className="title">About us</h2>
          <div className="about-description">
            <p className="about-item">
              We built our company with a dream to create quality products that improve people's
              lives. Every day, we work hard to achieve this dream again and again.
            </p>
            <p className="about-item">
              Startups and emerging companies are our favorite allies. And we are happy to be the
              technical partner they recommend to their peers without a doubt.
            </p>
            <p className="about-item">
              Trust us to develop your web or mobile app, and we'll help you at every stage of the
              product life cycle - from the concept to the golden unicorn.
            </p>
          </div>
        </div>
        <div className="about-images">
          <div className="ibg">
            <picture>
              <source srcSet="/assets/img/image1-575.webp" media="(max-width: 575px)" />
              <source srcSet="/assets/img/image1-900.webp" media="(max-width: 900px)" />
              <img src="/assets/img/image1.webp" alt="winter" />
            </picture>
          </div>
          <div className="ibg">
            <picture>
              <source srcSet="/assets/img/image2-575.webp" media="(max-width: 575px)" />
              <source srcSet="/assets/img/image2-900.webp" media="(max-width: 900px)" />
              <img src="/assets/img/image2.webp" alt="spring" />
            </picture>
          </div>
          <div className="ibg">
            <picture>
              <source srcSet="/assets/img/image3-575.webp" media="(max-width: 575px)" />
              <source srcSet="/assets/img/image3-900.webp" media="(max-width: 900px)" />
              <img src="/assets/img/image3.webp" alt="autumn" />
            </picture>
          </div>
          <div className="ibg">
            <picture>
              <source srcSet="/assets/img/image4-575.webp" media="(max-width: 575px)" />
              <source srcSet="/assets/img/image4-900.webp" media="(max-width: 900px)" />
              <img src="/assets/img/image4.webp" alt="summer" />
            </picture>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
