import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    minHeight: '280px',
    padding: '24px',
    paddingBottom: '44px',
    background: PALITRA.backgroundSection,
    maxWidth: '1024px',
    margin: '0 auto',
    marginBottom: '28px',
    textAlign: 'center',
    boxShadow: '0px 0px 8px rgba(237, 237, 237, 0.5)',
    borderRadius: '3px',

    '@media screen and (max-width: 1024px)': {
      margin: '0px 15px 28px 15px',
      padding: '14px',
      paddingBottom: '34px',
    },

    '& .service-wrapper': {
      display: 'grid',
      rowGap: '20px',

      '@media screen and (min-width: 900px)': {
        columnGap: '30px',
        gridTemplateColumns: 'repeat(2,1fr)',
      },
    },
  },
});

export default useStyles;
