import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const getText = () => <p>текст</p>;

  return <div>{getText()}</div>;
};

const Task10: FC = () => {
  const taskTitle: string =
    "10. Пусть компонент App имеет стрелочную функцию с названием getText, которая своим результатом возвращает '<p>текст</p>'. Используя функцию getText выведи на экран следующее:";
  const taskCode: string = `
  <div>
    <p>текст</p>
  </div>
  `;
  const taskClassSolution: string = `
  class App extends React.Component {
    getText() {
      return <p>текст</p>;
    }

    render() {
      return <div>{this.getText()}</div>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const getText = () => <p>текст</p>;

    return <div>{getText()}</div>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task10;
