import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '& .bread-crumbs__title': {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      paddingLeft: '10px',
    },
    '& .bread-crumbs__sub-title': {
      fontFamily: 'Montserrat',
      fontWeight: '500',
    },
  },
});

export default useStyles;
