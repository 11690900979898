import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: '1024px',

    '@media (max-width: 1024px)': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
});

export default useStyles;
