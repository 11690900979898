import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface ListProps {
  data: {
    name: string;
    showing: boolean;
  }[];
  onClickFunc: (index: number) => void;
  onBlurFunc: (event: React.FocusEvent<HTMLTextAreaElement>, index: number) => void;
}

const List: FC<ListProps> = ({ data, onClickFunc, onBlurFunc }) => {
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <span>{item.name + ' '} </span>

          {item.showing && (
            <textarea intial-value={item.name} onBlur={(event) => onBlurFunc(event, index)} />
          )}

          <button
            onClick={() => onClickFunc(index)}
            style={{ display: item.showing ? 'none' : 'inline' }}>
            Редактировать
          </button>
        </li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  const [list, setList] = useState([
    { name: 'HTML5', showing: false },
    { name: 'CSS3', showing: false },
    { name: 'JavaScript', showing: false },
    { name: 'React', showing: false },
  ]);

  const onHandleClick = (index: number) => {
    const copyList = [...list];
    copyList[index].showing = !copyList[index].showing;
    setList(copyList);
  };

  const onHandleBlur = (event: React.FocusEvent<HTMLTextAreaElement>, index: number) => {
    if (event.target.value) {
      const copyList = [...list];
      copyList[index].name = event.target.value;
      copyList[index].showing = false;
      setList(copyList);
    }
  };

  return <List data={list} onClickFunc={onHandleClick} onBlurFunc={onHandleBlur} />;
};

const Task6: FC = () => {
  const taskTitle: string =
    "6. Дан стейт внутри которого массив. Выведи его элементы в виде списка ul. Сделай так, чтобы элементы массива выводились в отдельном компоненте. Рядом с текстом li сделайте кнопку 'редактировать'. По нажатию на эту кнопку она должна пропасть, а взамен должен появиться инпут, с помощью которого можно будет поредактировать текст li. По потери фокуса из инпута он должен исчезнуть, а взамен должна вернуться ссылка 'редактировать'.";
  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task6;
