import i18n from 'i18next';
import {
  I18nextProvider,
  initReactI18next,
  Trans,
  Translation,
  useTranslation,
} from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationUK from './locales/uk/translation.json';
import translationRUS from './locales/rus/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  uk: {
    translation: translationUK,
  },
  rus: {
    translation: translationRUS,
  },
};

export const initPromise = i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
  },
});

export const t = i18n.t.bind(i18n);

export { I18nextProvider, useTranslation, Trans, Translation };

export default i18n;
