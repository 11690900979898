import React, { FC, useRef, useState } from 'react';
import ComponentTemplate from '../../Task';

interface List {
  data: Array<string>;
}

const List: FC<List> = ({ data }) => {
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  const newValue: React.MutableRefObject<any> = useRef(null);
  const [arr, setArr] = useState(['HTML', 'CSS', 'JavaScript']);

  const handleAddClick = () => {
    const copyArr = [...arr];
    copyArr.splice(Number(newValue.current.value), 1);
    newValue.current.value = '';
    setArr(copyArr);
  };

  return (
    <div>
      <List data={arr} />
      <input type="number" ref={newValue} />
      <button onClick={handleAddClick}>Delete</button>
    </div>
  );
};

const Task11: FC = () => {
  const taskTitle: string =
    '11. Дан cтейт в котором массив. Выведи этот массив в виде списка ul. Также даны инпут и кнопка. Сделай так, чтобы в инпут можно было ввести число, нажать на кнопку - и из списка ul удалился элемент с введенным в инпут номером.';
  const taskClassSolution: string = `
    class App extends React.Component {
    constructor() {
      super();
      this.state = {
        values: ['firstElem', 'secondElem', 'thirdElem', 'fourthElem', 'fifthElem', 'sixthElem'],
        inputValue: ''
      };
    }
  
    handleChange(event){
      this.setState({...this.state, inputValue: event.target.value})
    }
  
    deleteField() {
      this.state.values.splice(this.state.inputValue-1, 1);
      this.setState({values: this.state.values, inputValue: ''});
    }
  
    render() {
      const listNames= this.state.values.map((value, index)=>{
        return <li key={index}>
          {value}
        </li>
      });
  
      return (
        <div>
          <ul>
            {listNames}
          </ul>
          <div>
            <input
              type="number"
              placeholder="Enter number of element"
              value={this.state.inputValue}
              onChange={this.handleChange.bind(this)}
            />
            <button onClick={()=>this.deleteField()}>Delete</button>
          </div>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ data }) => {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const App = () => {
    const newValue = useRef(null);
    const [arr, setArr] = useState(['HTML', 'CSS', 'JavaScript']);

    const handleAddClick = () => {
      const copyArr = [...arr];
      copyArr.splice(Number(newValue.current.value), 1);
      newValue.current.value = '';
      setArr(copyArr);
    };

    return (
      <div>
        <List data={arr} />
        <input type="number" ref={newValue} />
        <button onClick={handleAddClick}>Удалить</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task11;
