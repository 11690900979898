import React, { FC } from 'react';

const Adventage2: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    id="Слой_2"
    data-name="Слой 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100">
    <title>Монтажная область 14</title>
    <path d="M81.23,17H18.77c-3.71,0-6.68,2.43-6.77,5.58V68.32C12,71.45,15,74,18.77,74H48V85H30.5a1.5,1.5,0,0,0,0,3h39a1.5,1.5,0,0,0,0-3H51V74H81.23C85,74,88,71.45,88,68.32V22.68C88,19.55,85,17,81.23,17ZM85,68.32C85,69.77,83.27,71,81.23,71H18.77c-2,0-3.77-1.23-3.77-2.68V22.62C15,21.18,16.73,20,18.77,20H81.23c2,0,3.77,1.23,3.77,2.68Z" />
    <path d="M55.27,36.63a1.5,1.5,0,0,0-2,.65L45.19,53a1.5,1.5,0,1,0,2.67,1.37l8.06-15.75A1.5,1.5,0,0,0,55.27,36.63Z" />
    <path d="M64.86,36.08a1.5,1.5,0,0,0-2.12,2.12l7.48,7.47-7.48,7.47a1.5,1.5,0,1,0,2.12,2.12l8.54-8.53a1.5,1.5,0,0,0,0-2.12Z" />
    <path d="M38.4,36.08a1.5,1.5,0,0,0-2.12,0l-8.54,8.54a1.5,1.5,0,0,0,0,2.12l8.54,8.54a1.5,1.5,0,0,0,2.12-2.12l-7.48-7.47L38.4,38.2A1.5,1.5,0,0,0,38.4,36.08Z" />
  </svg>
);

export default Adventage2;
