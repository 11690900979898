import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const AboutUsModal = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Divider orientation="left">
        <h2>{t('generalSection.aboutUs')}</h2>
      </Divider>
      <h2 className="main-text text">{t('modalAboutUs.webStudio')}</h2>
      <h2 className="main-text text">{t('modalAboutUs.qualityWork')}</h2>
      <h2 className="main-text text">{t('modalAboutUs.visitor-buyer')}</h2>
      <Divider className="deviderHorizontal" orientation="left" />
      <h2 className="text">{t('modalAboutUs.ourLocation')}</h2>
      <h2 className="text">
        {t('modalAboutUs.email')}
        <a className="link" href="mailto:admin@unicode-studio.com">
          admin@unicode-studio.com
        </a>
      </h2>
      <h2 className="text">
        {t('modalAboutUs.phone')}
        <a className="linkPhone" href="tel:0500196878">
          +380 (50) 019 68 78
        </a>
      </h2>
      <h2 className="text">
        {t('modalAboutUs.telegram')}
        <a className="link" href="https://t.me/unicode_st">
          @unicode_st
        </a>
      </h2>
    </div>
  );
};

export { AboutUsModal };
