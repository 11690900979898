import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <input
      type="text"
      style={{
        border:
          inputValue.length >= 4 && inputValue.length <= 9 ? '1px solid green' : '1px solid green',
      }}
      value={inputValue}
      onChange={handleChange}
    />
  );
};

const Task11: FC = () => {
  const taskTitle: string =
    '11. Дан инпут. В него вводится строка. Сделай так, чтобы если длина введенной строки от 4 до 9 символов - граница инпута была зеленой, в противном случае - красной. Инпут должен проверять свое содержимое по мере ввода.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        inputValue: '',
      };
    }

    handleChange(value) {
      this.setState({ inputValue: value });
    }

    render() {
      return (
        <input
          type='text'
          style={{
            border:
              this.state.inputValue.length >= 4 &&
              this.state.inputValue.length <= 9
                ? '1px solid green'
                : '1px solid red',
          }}
          value={this.state.inputValue}
          onChange={(event) => this.handleChange(event.target.value)}
        />
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
      setInputValue(event.target.value);
    };

    return (
      <input
        type="text"
        style={{
          border:
            inputValue.length >= 4 && inputValue.length <= 9
            ? '1px solid green' : '1px solid red',
        }}
        value={inputValue}
        onChange={handleChange}
      />
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task11;
