import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const person = { name: 'Иван', age: 25 };

  const showName = () => {
    alert(person.name);
  };

  return <button onClick={showName}>нажми на меня</button>;
};

const Task3: FC = () => {
  const taskTitle: string = '3. Дан следующий объект:';
  const taskCode: string = `
    const person = {name: 'Иван', age: 25};
  `;
  const taskTitle2: string = `Сделай кнопку, по нажатию на которую сработает функция showName и на экран алертом выведется имя пользователя из объекта.`;
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25 };

    showName = () => {
      alert(this.state.name);
    };
    render() {
      return <button onClick={this.showName}>нажми на меня</button>;
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const state = { name: 'Иван', age: 25 };

    const showName = () => {
      alert(state.name);
    };

    return <button onClick={showName}>нажми на меня</button>;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
        taskTitle2={taskTitle2}
      />
    </div>
  );
};
export default Task3;
