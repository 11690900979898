import React, { FC, useRef, useState } from 'react';
import ComponentTemplate from '../../Task';

interface NotesListProps {
  data: {
    content: string;
    time: Date;
    showing: boolean;
  }[];
  onClickEdit: (index: number, value: string) => void;
  onClickDelete: (index: number) => void;
}

const NotesList: FC<NotesListProps> = ({ data, onClickEdit, onClickDelete }) => {
  const editContent: React.MutableRefObject<any> = useRef(null);
  return (
    <div>
      {data.map((item, index) => (
        <div key={index}>
          <h2>Заметка {index + 1}</h2>
          <p>{item.content}</p>
          <span>
            Время создания:
            {item.time.toLocaleString()}
          </span>
          <button
            onClick={() => onClickEdit(index, item.showing ? editContent?.current.value : null)}>
            Редактировать
          </button>

          {item.showing ? (
            <input ref={editContent} type="text" initial-value={item.content} />
          ) : null}

          <button onClick={() => onClickDelete(index)}>Удалить</button>
        </div>
      ))}
    </div>
  );
};

const TaskReady: FC = () => {
  const [data, setData] = useState([
    {
      content: 'Первое содержимое',
      time: new Date(),
      showing: false,
    },
    {
      content: 'Второе содержимое',
      time: new Date(),
      showing: false,
    },
  ]);

  const newNote: React.MutableRefObject<any> = useRef(null);

  const handleAddButtonClick = () => {
    const copyData = [...data];
    copyData.push({ content: newNote.current.value, time: new Date(), showing: false });
    newNote.current.value = '';
    setData(copyData);
  };

  const handleEditClick = (index: number, value: string) => {
    const copyData = [...data];
    copyData[index].showing = !copyData[index].showing;
    value ? (copyData[index].content = value) : (value = copyData[index].content);
    setData(copyData);
  };

  const handleDeleteClick = (index: number) => {
    const copyData = [...data];
    copyData.splice(index, 1);
    setData(copyData);
  };

  return (
    <div>
      <NotesList data={data} onClickEdit={handleEditClick} onClickDelete={handleDeleteClick} />
      <div>
        <textarea ref={newNote} />
        <button onClick={handleAddButtonClick}>Добавить</button>
      </div>
    </div>
  );
};

const Task8: FC = () => {
  const taskTitle: string =
    "8. Дан текстареа и кнопка. В текстареа пользователь нашего сайта будет вводить свои заметки. После нажатия на кнопку введенный текст должен появится под текстареа в виде блока div. Таких заметок может быть много. В каждой заметке должен стоять заголовок (заметка1, заметка2 и так далее), время создания заметки (часы, минуты, секунды), а также должна быть кнопка 'удалить' и кнопка 'редактировать'.";
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        notes: [
          {
            content: 'Первое содержимое',
            time: new Date(),
            showing: false,
          },
          {
            content: 'Второе содержимое',
            time: new Date(),
            showing: false,
          },
        ],
      };
      this.newNote = React.createRef();
    }

    handleAddButtonClick() {
      const copyState = { ...this.state };
      copyState.notes.push({
        content: this.newNote.current.value,
        time: new Date(),
        showing: false,
      });
      this.newNote.current.value = '';

      this.setState(copyState);
    }

    handleEditClick(index, value) {
      const copyState = { ...this.state };
      copyState.notes[index].showing = !copyState.notes[index].showing;
      value
        ? (copyState.notes[index].content = value)
        : (value = copyState.notes[index].content);

      this.setState(copyState);
    }

    handleDeleteClick(index) {
      const copyState = { ...this.state };
      copyState.notes.splice(index, 1);

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          <NotesList
            data={this.state.notes}
            onClickEdit={this.handleEditClick.bind(this)}
            onClickDelete={this.handleDeleteClick.bind(this)}
          />
          <div>
            <textarea ref={this.newNote} />
            <button onClick={this.handleAddButtonClick.bind(this)}>
              Добавить
            </button>
          </div>
        </div>
      );
    }
  }

  class NotesList extends React.Component {
    constructor() {
      super();
      this.editContent = React.createRef();
    }

    render() {
      return (
        <div>
          {this.props.data.map((item, index) => (
            <div key={index}>
              <h2>Заметка {index + 1}</h2>
              <p>{item.content}</p>
              <span>
                Время создания:
                {item.time.toLocaleString()}
              </span>
              <button
                onClick={() =>
                  this.props.onClickEdit(
                    index,
                    item.showing ? this.editContent.current.value : null
                  )
                }
              >
                Редактировать
              </button>

              {item.showing ? (
                <input
                  ref={this.editContent}
                  type='text'
                  initial-value={item.content}
                />
              ) : null}

              <button onClick={() => this.props.onClickDelete(index)}>
                Удалить
              </button>
            </div>
          ))}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const NotesList = ({ data, onClickEdit, onClickDelete }) => {
    const editContent = useRef(null);
    return (
      <div>
        {data.map((item, index) => (
          <div key={index}>
            <h2>Заметка {index + 1}</h2>
            <p>{item.content}</p>
            <span>
              Время создания:
              {item.time.toLocaleString()}
            </span>
            <button
              onClick={() => onClickEdit(index, item.showing ? editContent?.current.value : null)}>
              Редактировать
            </button>

            {item.showing ? (
              <input ref={editContent} type="text" initial-value={item.content} />
            ) : null}

            <button onClick={() => onClickDelete(index)}>Удалить</button>
          </div>
        ))}
      </div>
    );
  };

  const App = () => {
    const [data, setData] = useState([
      {
        content: 'Первое содержимое',
        time: new Date(),
        showing: false,
      },
      {
        content: 'Второе содержимое',
        time: new Date(),
        showing: false,
      },
    ]);

    const newNote = useRef(null);

    const handleAddButtonClick = () => {
      const copyData = [...data];
      copyData.push({ content: newNote.current.value, time: new Date(), showing: false });
      newNote.current.value = '';
      setData(copyData);
    };

    const handleEditClick = (index, value) => {
      const copyData = [...data];
      copyData[index].showing = !copyData[index].showing;
      value ? (copyData[index].content = value) : (value = copyData[index].content);
      setData(copyData);
    };

    const handleDeleteClick = (index) => {
      const copyData = [...data];
      copyData.splice(index, 1);
      setData(copyData);
    };

    return (
      <div>
        <NotesList data={data} onClickEdit={handleEditClick} onClickDelete={handleDeleteClick} />
        <div>
          <textarea ref={newNote} />
          <button onClick={handleAddButtonClick}>Добавить</button>
        </div>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task8;
