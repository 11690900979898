import { createUseStyles } from 'react-jss';
interface PropsColor {
  styleForSelect: { bg: string; colorText: string };
}
const useStyles = createUseStyles(({ styleForSelect }: PropsColor) => ({
  root: {
    '& .svgLanguage': {
      marginRight: '10px !important',
    },
    '& .ant-select.ant-select-single.ant-select-show-arrow': {
      marginTop: '12px !important',
    },
    '& .ant-select-selector': {
      backgroundColor: `${styleForSelect.bg} !important`,
      color: `${styleForSelect.colorText} !important`,
      border: 'none !important',
      width: '165px !important',
      boxShadow: 'none !important',
      transition: 'none !important',
    },
    '& .ant-select-selection-item': {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '16px',
    },
  },
  select: {
    '& .svgLanguage': {
      marginRight: '10px',
    },
  },
}));

export { useStyles };
