import React, { FC } from 'react';

interface Props {
  animName: string;
  colors: { main: string; secondary: string };
}

const AnimatedBackground: FC<Props> = ({ animName, colors }) => {
  return (
    <style type="text/css">
      {`@keyframes ${animName} {
          0% {
            background-image: linear-gradient(to right, ${colors.main} 0%, ${colors.secondary}), linear-gradient(to right, ${colors.secondary} 0%, ${colors.main} 100%);
            background-position: -100%, -50%;
            background-size: 50%, 50%;
            background-repeat: no-repeat;
          }
          100% {
            background-image: linear-gradient(to right, ${colors.main} 0%,${colors.secondary}), linear-gradient(to right, ${colors.secondary} 0%, ${colors.main} 100%);
            background-position: 100%,  150%;
            background-size: 50%, 50%;
            background-repeat: no-repeat;
          }
        }`}
    </style>
  );
};

export default AnimatedBackground;
