import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface ProductsStateProps {
  name: string;
  price: number;
  quantity: number;
  status: boolean;
}
interface NewItemFormProps {
  newItem: {
    name: string;
    price: string;
    quantity: string;
    status: boolean;
  };
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addItem: () => void;
}
interface TableProps {
  data: ProductsStateProps[];
  deleteItem: (id: number) => void;
  changeItemStatus: (id: number) => void;
}

interface SumOfItemsProps {
  data: ProductsStateProps[];
}

const SumOfItems: FC<SumOfItemsProps> = ({ data }) => {
  const sum = data.reduce((acc, item) => {
    if (item.status) {
      return acc + item.price * item.quantity;
    }
    return acc;
  }, 0);

  return (
    <div>
      <h3>Сумма продуктов - {sum}</h3>
    </div>
  );
};

const NewItemForm: FC<NewItemFormProps> = ({ newItem, handleInputChange, addItem }) => (
  <div>
    <div>
      <div>Name</div>
      <input type="text" value={newItem.name} name="name" onChange={handleInputChange} />
    </div>

    <div>
      <div>Price </div>
      <input type="text" value={newItem.price} name="price" onChange={handleInputChange} />
    </div>

    <div>
      <div>Quantity</div>
      <input type="text" value={newItem.quantity} name="quantity" onChange={handleInputChange} />
    </div>

    <button onClick={addItem}>Добавить</button>
  </div>
);

const Table: FC<TableProps> = ({ data, deleteItem, changeItemStatus }) => (
  <table width="40%">
    <thead>
      <tr>
        <th></th>
        <th>Название</th>
        <th>Цена</th>
        <th>Количество</th>
        <th>Сумма</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>
            <input type="checkbox" checked={item.status} onChange={() => changeItemStatus(index)} />
          </td>
          <td>{item.name}</td>
          <td>{item.price}</td>
          <td>{item.quantity}</td>
          <td>{item.quantity * item.price}</td>
          <td>
            <button onClick={() => deleteItem(index)}>Удалить продукт</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [products, setProducts] = useState<ProductsStateProps[]>([
    { name: 'lemon', price: 20, quantity: 3, status: true },
    { name: 'apple', price: 10, quantity: 10, status: true },
    { name: 'pamelo', price: 50, quantity: 1, status: true },
  ]);

  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    quantity: '',
    status: true,
  });

  const deleteProduct = (id: number) => {
    products.splice(id, 1);
    setProducts([...products]);
  };

  const handleNewProductInputChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    let checkedValue: string = value;

    if (name === 'price' || name === 'quantity') {
      const regexp = /^\d+$/;
      const match = value.match(regexp)?.input;

      checkedValue = match ? match : newProduct[name].length === 1 ? '' : newProduct[name];
    }

    setNewProduct({
      ...newProduct,
      [name]: checkedValue,
    });
  };

  const addProduct = () => {
    products.push({
      ...newProduct,
      price: Number(newProduct.price),
      quantity: Number(newProduct.quantity),
    });
    setProducts([...products]);
    setNewProduct({ name: '', price: '', quantity: '', status: true });
  };

  const chnageProductStatus = (id: number) => {
    products[id].status = !products[id].status;
    setProducts([...products]);
  };

  return (
    <>
      <Table data={products} deleteItem={deleteProduct} changeItemStatus={chnageProductStatus} />
      <SumOfItems data={products} />
      <NewItemForm
        newItem={newProduct}
        handleInputChange={handleNewProductInputChange}
        addItem={addProduct}
      />
    </>
  );
};

const Task10: FC = () => {
  const taskTitle: string =
    '10. Модифицируй предыдущую задачу так, чтобы в таблице появилась еще одна колонка, в которой будут находиться чекбоксы. Изначально эти чекбоксы отмечены. Сделай так, чтобы полная стоимость всех продуктов выводилась только для тех продуктов, у которых чекбокс отмечен.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task10;
