import classNames from 'classnames';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../Components/Container';
import { getNameImage } from '../../utils/common';

import useStyles from './style';

interface BlogPage2Props {
  id: number;
  info: DATA_BLOG_ITEM;
  clas: string;
}

const BlogPage2: FC<BlogPage2Props> = ({ id, info, clas }) => {
  const classes = useStyles();

  return (
    <Container className={classNames(clas, classes.root)}>
      <Helmet>
        <meta name="description" content={info.desc} />
        <title>{info.title}</title>
      </Helmet>
      <div className="blog-wrap">
        <h2 className="blog-title">{info.title}</h2>
        <div className="blog-date">{info.date}</div>
        <div className="blog-image margin-bottom">
          <img src={info.src} alt={getNameImage(id)} className="blog-img" />
        </div>
        <div className="blog-text">
          <p className="blog-text-p">
            It’s no secret that{' '}
            <a
              href="https://www.entrepreneur.com/article/295798"
              target="_blank"
              rel="noopener noreferrer">
              nine out of ten
            </a>{' '}
            startups fail, mostly because of the lack of interest and problems with the
            product-market fit. But validating your hypothesis from the beginning can dramatically
            improve your odds for success. How? With the Lean startup approach.
          </p>
          <p className="blog-text-p">
            Lean startups are not a novelty. They’re often the reason why small businesses take the
            lead over bureaucratic and not that open-minded corporations. The flexibility of Lean
            allows companies to quickly develop an MVP and focus on post-release adjustments based
            on customer feedback.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default BlogPage2;
