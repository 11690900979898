import React from 'react';
import ComponentTemplate from '../../Task';

const Task1: React.FC = () => {
  const taskTitle: string =
    '1. Дан стейт внутри которого массив с работниками. У каждого работника есть имя, фамилия, количество отработанных дней и зарплатная ставка за день. Выведи этих работников на экран в виде таблицы. Сделай так, чтобы в последней колонке автоматически рассчитывалась зарплата работника (количество отработанных дней умножить на ставку). Сделай так, чтобы количество дней и ставка выводились в виде инпутов. Если поредактировать эти инпуты - зарплата также должна поменяться. Под таблицей также выведи суммарную зарплату всех работников.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task1;
