import React from 'react';
import ComponentTemplate from '../../Task';

const Task6: React.FC = () => {
  const taskTitle: string =
    '6. Реализуй TODO-лист (чеклист). В нем должен быть список задач, которые хочет сделать пользователь. Задачу можно добавить, удалить, поредактировать. Кроме того, рядом с каждой задачей должен быть чекбокс, с помощью которого можно отметить эту задачу сделанной. Сделанная задача не удаляется из списка, а становится перечеркнутой (перечеркнуть текст можно с помощью text-decoration).';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task6;
