import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface List {
  data: {
    name: string;
    age: string | undefined;
  }[];
}

interface TaskReady {
  users: { name: string; age: string | undefined }[];
  newUser: { name: string; age: string | undefined };
}

const List: FC<List> = ({ data }) => {
  return (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.age}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TaskReady: FC = () => {
  const [items, setItems] = useState<TaskReady>({
    users: [
      { name: 'Коля', age: '30' },
      { name: 'Вася', age: '40' },
      { name: 'Петя', age: '50' },
    ],
    newUser: { name: '', age: '' },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItems({ ...items, newUser: { ...items.newUser, name: e.target.value } });
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(e.target.value)))
      setItems({ ...items, newUser: { ...items.newUser, age: e.target.value } });
  };

  const addNewHref = () => {
    const copyItems = { ...items };

    if (copyItems.newUser.name !== '' && copyItems.newUser.age !== '') {
      copyItems.users.push({ name: copyItems.newUser.name, age: copyItems.newUser.age });
      copyItems.newUser = { name: '', age: '' };
    }

    setItems(copyItems);
  };

  return (
    <div>
      <List data={items.users} />
      <input type="text" value={items.newUser.name} onChange={handleNameChange} placeholder="Имя" />
      <input
        type="text"
        value={items.newUser.age}
        onChange={handleAgeChange}
        placeholder="Возраст"
      />
      <button onClick={addNewHref}>Добавить</button>
    </div>
  );
};

const Task12: FC = () => {
  const taskTitle: string = '12. Дан следующий стейт:';
  const taskCode: string = `
  const [items, setItems] = useState({
    users: [
      { name: 'Коля', age: '30' },
      { name: 'Вася', age: '40' },
      { name: 'Петя', age: '50' },
    ],
    newUser: { name: '', age: '' },
  });
  `;
  const taskTitle2: string = 'Выведи его на экран в виде таблицы:';
  const taskCode2: string = `
  <table>
    <tr>
      <td>Коля</td><td>30</td>
    </tr>
    <tr>
      <td>Коля</td><td>40</td>
    </tr>
    <tr>
      <td>Коля</td><td>50</td>
    </tr>
  </table>
  `;
  const taskTitle3: string =
    'Сделай 2 инпута и кнопку, по нажатию на которую в список добавится новая tr с именем и возрастом.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        users: [
          {name: 'Коля', age: 30},
          {name: 'Вася', age: 40},
          {name: 'Петя', age: 50}
        ],
        inputValues: {name: '', age: ''}
      };
    }
    
    handleChangeName(event){
      this.setState({...this.state, inputValues: {...this.state.inputValues, name: event.target.value}})
    }
  
    handleChangeAge(event){
      this.setState({...this.state, inputValues: {...this.state.inputValues, age: event.target.value}})
    }
  
    addField() {
      if (this.state.inputValues.name && this.state.inputValues.age){
        this.state.users.push(this.state.inputValues);
        this.setState({users: this.state.users, inputValues: {name: '', age: ''}});
      }
    }
  
    render() {
      const listNames= this.state.users.map((value, index)=>{
        return <tr key={index}>
          <td>{value.name}</td>
          <td>{value.age}</td>
        </tr>
      });
  
      return (
        <div>
          <table>
            {listNames}
          </table>
          <div>
            <input
              type="text"
              placeholder="Имя"
              value={this.state.inputValues.name}
              onChange={this.handleChangeName.bind(this)}
            />
            <input
              type="number"
              placeholder="Возраст"
              value={this.state.inputValues.age}
              onChange={this.handleChangeAge.bind(this)}
            />
            <button onClick={()=>this.addField()}>Добавить</button>
          </div>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ data }) => {
    return (
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.age}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const App = () => {
    const [items, setItems] = useState({
      users: [
        { name: 'Коля', age: '30' },
        { name: 'Вася', age: '40' },
        { name: 'Петя', age: '50' },
      ],
      newUser: { name: '', age: '' },
    });

    const handleNameChange = (e)>) => {
      setItems({ ...items, newUser: { ...items.newUser, name: e.target.value } });
    };

    const handleAgeChange = (e) => {
      if (!isNaN(Number(e.target.value)))
        setItems({ ...items, newUser: { ...items.newUser, age: e.target.value } });
    };

    const addNewHref = () => {
      const copyItems = { ...items };

      if (copyItems.newUser.name !== '' && copyItems.newUser.age !== '') {
        copyItems.users.push({ name: copyItems.newUser.name, age: copyItems.newUser.age });
        copyItems.newUser = { name: '', age: '' };
      }

      setItems(copyItems);
    };
    
    return (
      <div>
        <List data={items.users} />
        <input
          type="text"
          value={items.newUser.name}
          onChange={handleNameChange}
          placeholder="Имя"
        />

        <input
          type="text"
          value={items.newUser.age}
          onChange={handleAgeChange}
          placeholder="Возраст"
        />

        <button onClick={addNewHref}>Добавить</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskTitle2={taskTitle2}
        taskTitle3={taskTitle3}
        taskCode2={taskCode2}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task12;
