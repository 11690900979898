import React from 'react';
import { Helmet } from 'react-helmet';

import SliderSlick from '../../Components/SliderSlick';
import OurServices from '../../Components/OurServices';
import ContactWithUs from '../../Components/ContactWithUs';
import HowItWorks from '../../Components/HowItWorks';
import Advantages from '../../Components/Advantages';
import OurClients from '../../Components/OurClients';
import Blog from '../../Components/Blog';
import Contact from '../../Components/Contact';
import AboutUs from '../../Components/AboutUs';

import useStyle from './style';
 

const OldHomePage = () => {
   const classNamees = useStyle();
  return (
    <div className={classNamees.root}>
      <Helmet>
        <meta name="description" content="Web site created for learning React" />
        <title>UniCode website</title>
        
      </Helmet>
      <SliderSlick />
      <AboutUs />
      <OurServices />
      <ContactWithUs />
      <HowItWorks />
      <Advantages />
      <OurClients />
      <Blog />
      <Contact />
    </div>
  );
};
export { OldHomePage };
