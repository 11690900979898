import React, { ChangeEvent, FC, useState } from 'react';
import { PALITRA } from '../../../utils/constants';
import ComponentTemplate from '../../Task';

interface QuestionsProps {
  data: {
    question: string;
    answers: string[];
    currentAnswer: string;
    right: number;
  }[];
  onRadioChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void;
}

const Questions: FC<QuestionsProps> = ({ data, onRadioChange }) => (
  <div>
    {data.map((item, index) => (
      <div key={index}>
        <p>{item.question}</p>
        {item.answers.map((answer, id) => (
          <div>
            <input
              type="radio"
              checked={answer === item.currentAnswer}
              onChange={(event) => onRadioChange(index, event)}
              value={answer}
            />
            <span
              style={{
                color:
                  id === item.right && answer === item.currentAnswer
                    ? PALITRA.green.greenBright
                    : answer === item.currentAnswer
                    ? PALITRA.red.red
                    : PALITRA.black,
              }}>
              {answer}
            </span>
          </div>
        ))}
      </div>
    ))}
  </div>
);

const TaskReady: FC = () => {
  const [test, setTest] = useState([
    {
      question: 'В каком году началась Вторамя мировая война?',
      answers: ['1941', '1945', '2001', '1939', '1830'],
      currentAnswer: '',
      right: 3,
    },
    {
      question: 'Какая формула в физике используеться для нахождения силы тяжести?',
      answers: ['F = m*g', 'S = k/a', 'L = R*m', 'N = A/t', 'A = F*s'],
      currentAnswer: '',
      right: 0,
    },
  ]);

  const handleRadioChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const copyTest = [...test];
    copyTest[index].currentAnswer = event.target.value;
    setTest(copyTest);
  };

  return <Questions data={test} onRadioChange={handleRadioChange} />;
};

const Task16: FC = () => {
  const taskTitle: string =
    '16. Дан следующий стейт внутри которого массив с вопросами и вариантами ответов:';
  const taskCode: string = `
  const [state, setState] = useState({
    test: [
      {
        question: 'Вопрос 1',
        answers: [
          'Ответ1',
          'Ответ2',
          'Ответ3',
          'Ответ4',
          'Ответ5',
        ],
        right: 3, //номер правильного ответа
      },
      {
        ...
      },
      {
        ...
      },
    ]
  });
  `;
  const taskTitle2: string =
    'Реализуй текст с вопросами и вариантами ответов. Каждый вопрос должен быть в своем абзаце, а под ним - 5 радиокнопок, с помощью которых можно выбрать один из ответов. Если ответ правильный - вопрос должен покраситься в зеленый цвет, а если неправильный - в красный.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        tests: [
          {
            question: 'В каком году началась Вторамя мировая война?',
            answers: ['1941', '1945', '2001', '1939', '1830'],
            currentAnswer: '',
            right: 3,
          },
          {
            question:
              'Какая формула в физике используеться для нахождения силы тяжести?',
            answers: ['F = m*g', 'S = k/a', 'L = R*m', 'N = A/t', 'A = F*s'],
            currentAnswer: '',
            right: 0,
          },
        ],
      };
    }

    handleRadioChange(index, value) {
      const copyState = { ...this.state };
      copyState.tests[index].currentAnswer = value;

      this.setState(copyState);
    }

    render() {
      return (
        <Questions
          data={this.state.tests}
          onRadioChange={this.handleRadioChange.bind(this)}
        />
      );
    }
  }

  class Questions extends React.Component {
    render() {
      return (
        <div>
          {this.props.data.map((item, index) => (
            <div key={index}>
              <p>{item.question}</p>
              {item.answers.map((answer, id) => (
                <div>
                  <input
                    type='radio'
                    checked={answer === item.currentAnswer}
                    onChange={(event) =>
                      this.props.onRadioChange(index, event.target.value)
                    }
                    value={answer}
                  />
                  <span
                    style={{
                      color:
                        id === item.right && answer === item.currentAnswer
                          ? 'green'
                          : answer === item.currentAnswer
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {answer}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Questions = ({ data, onRadioChange }) => (
    <div>
      {data.map((item, index) => (
        <div key={index}>
          <p>{item.question}</p>
          {item.answers.map((answer, id) => (
            <div>
              <input
                type="radio"
                checked={answer === item.currentAnswer}
                onChange={(event) => onRadioChange(index, event)}
                value={answer}
              />
              <span
                style={{
                  color:
                    id === item.right && answer === item.currentAnswer
                      ? 'green'
                      : answer === item.currentAnswer
                      ? 'red'
                      : 'black',
                }}>
                {answer}
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const App = () => {
    const [test, setTest] = useState([
      {
        question: 'В каком году началась Вторамя мировая война?',
        answers: ['1941', '1945', '2001', '1939', '1830'],
        currentAnswer: '',
        right: 3,
      },
      {
        question: 'Какая формула в физике используеться для нахождения силы тяжести?',
        answers: ['F = m*g', 'S = k/a', 'L = R*m', 'N = A/t', 'A = F*s'],
        currentAnswer: '',
        right: 0,
      },
    ]);

    const handleRadioChange = (index, event) => {
      const copyTest = [...test];
      copyTest[index].currentAnswer = event.target.value;
      setTest(copyTest);
    };

    return <Questions data={test} onRadioChange={handleRadioChange} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskTitle2={taskTitle2}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task16;
