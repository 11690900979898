import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyle = createUseStyles({
  root: {
    height: 'calc(100vh - 120px)',
    marginBottom: '28px',
    '@media screen and (max-width: 1024px)': {
      marginLeft: '15px',
      marginRight: '15px',
    },

    '& .contentStyle': {
      height: 'calc(100vh - 120px)',
      textAlign: 'center',
      background: PALITRA.blue.blueDark,
      position: 'relative',

      '& h1': {
        position: 'absolute',
        color: PALITRA.white,
        fontSize: '48px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        '@media screen and (max-width: 768px)': {
          fontSize: '38px',
          width: '100%',
        },
      },
    },

    '@media screen and (max-width: 900px)': {
      '& .slick-dots li': {
        width: '24px',
        height: '7px',
        marginLeft: '10px',
        marginRight: '10px',

        '& button': {
          height: '4px',
        },
      },

      '& .slick-active': {
        width: '32px',
      },
    },
  },
});

export default useStyle;
