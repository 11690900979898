import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    // '& .link': {
    //   fontSize: '30px',
    //   '&::after': {
    //     content: '12',
    //   },
    // },
    // '& .blog-wrap': {
    //   textAlign: 'left',
    //   maxWidth: '620px',
    //   margin: '0 auto',
    //   '& .blog-title': {
    //     fontSize: '32px',
    //     fontWeight: '600',
    //     margin: '0 auto',
    //     lineHeight: '1.2',
    //     color: PALITRA.black,
    //     marginBottom: '15px',
    //   },
    //   '& .blog-date': {
    //     letterSpacing: '1.5px',
    //     fontSize: '10px',
    //     color: 'rgba(0,0,0,.5)',
    //     fontWeight: '600',
    //     marginBottom: '15px',
    //   },
    //   '& .blog-text': {
    //     '& .blog-text-p': {
    //       fontSize: '18px',
    //       '& a': {
    //         color: PALITRA.crimson,
    //         textDecoration: 'underline',
    //         position: 'relative',
    //       },
    //       '& a::after': {
    //         content: '',
    //         position: 'absolute',
    //         top: '0',
    //         left: '0',
    //         height: '1px',
    //         width: '100%',
    //         backgroundColor: PALITRA.crimson,
    //       },
    //     },
    //     '& .blog-text-p.author': {
    //       textAlign: 'center',
    //     },
    //     '& .center': {
    //       color: PALITRA.black,
    //       fontSize: '18px',
    //       textAlign: 'center',
    //       fontStyle: 'italic',
    //       fontWeight: '700',
    //     },
    //   },
    //   '& .blog-image': {
    //     position: 'relative',
    //     padding: '0px 0px 85% 0px',
    //     '& .blog-img': {
    //       position: 'absolute',
    //       top: '0',
    //       left: '0',
    //       width: '100%',
    //       height: '100%',
    //       objectFit: 'cover',
    //     },
    //   },
    // },
    // '@media screen and (max-width: 767px)': {
    //   '& .blog-wrap': {
    //     maxWidth: '540px',
    //     '& .blog-title': {
    //       fontSize: '28px !important',
    //     },
    //     '& .blog-text': {
    //       '& .blog-text-p': {
    //         fontSize: '16px !important',
    //       },
    //     },
    //   },
    // },
  },
});

export default useStyles;
