import React, { FC } from 'react';

import ServiceCard from './ServiceCard';

import useStyles from './style';
import { DATA_SERVICE } from '../../utils/constants';
import Container from '../Container';

const OurServices: FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} title="Our services">
      <div className="service-wrapper">
        {DATA_SERVICE.map((item) => (
          <ServiceCard icon={item.icon} key={item.id} title={item.title} content={item.content} />
        ))}
      </div>
    </Container>
  );
};

export default OurServices;
