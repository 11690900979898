import React, { FC } from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import useStyles from './style';
import classNames from 'classnames';

interface MenuNavigation {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<
    React.SetStateAction<{
      dropdownMenu: boolean;
      dropMenuLessons: boolean; 
    }>
  >;
}

const MenuNavigation: FC<MenuNavigation> = ({ isMenuOpen, setIsMenuOpen }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, 'menu-navigation__icon')}>
      {isMenuOpen ? (
        <CloseOutlined
          className="menu-close-icon"
          onClick={() =>
            setIsMenuOpen((prev) => ({
              ...prev,
              dropdownMenu: false,
            }))
          }
        />
      ) : (
        <MenuOutlined
          className="menu-burger-icon"
          onClick={() =>
            setIsMenuOpen((prev) => ({
              ...prev,
              dropdownMenu: true,
            }))
          }
        />
      )}
    </div>
  );
};

export default MenuNavigation;
