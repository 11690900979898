import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface SelectProps {
  values: {
    cities: string[];
    currentCity: string;
  };
  changeFunc: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: FC<SelectProps> = ({ values, changeFunc }) => {
  return (
    <select value={values.currentCity} onChange={changeFunc}>
      {values.cities.map((item, index) => (
        <option key={index}>{item}</option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [values, setValue] = useState({
    cities: ['Moscow', 'New York', 'Paris', 'London'],
    currentCity: 'Moscow',
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setValue({ ...values, currentCity: event.target.value });

  return (
    <div>
      <p>Выбор: {values.currentCity}</p>
      <Select values={values} changeFunc={handleSelectChange} />
    </div>
  );
};

const Task5: FC = () => {
  const taskTitle: string =
    '5. Модифицируй предыдущую задачу так, чтобы список городов хранился в массиве из стейта.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        selectedCity: 'Moscow',
        cities: ['Moscow', 'New York', 'Paris', 'London'],
      };
    }

    render() {
      return (
        <div>
          <p>Выбор: {this.state.selectedCity}</p>
          <Select
            cities={this.state.cities}
            selectedCity={this.state.selectedCity}
            setState={this.setState.bind(this)}
          />
        </div>
      );
    }
  }

  class Select extends React.Component {
    render() {
      return (
        <select
          value={this.props.selectedCity}
          onChange={(event) =>
            this.props.setState({ selectedCity: event.target.value })
          }
        >
          {this.props.cities.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Select = ({ values, changeFunc }) => {
    return (
      <select value={values.currentCity} onChange={changeFunc}>
        {values.cities.map((item, index) => (
          <option key={index}>{item}</option>
        ))}
      </select>
    );
  };

  const App = () => {
    const [values, setValue] = useState({
      cities: ['Moscow', 'New York', 'Paris', 'London'],
      currentCity: 'Moscow',
    });

    const handleSelectChange = (event) =>
      setValue({ ...values, currentCity: event.target.value });

    return (
      <div>
        <p>Выбор: {values.currentCity}</p>
        <Select values={values} changeFunc={handleSelectChange} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task5;
