import React, { FC } from 'react';

const Adventage6: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100.75 100.75">
    <g>
      <path
        d="M83.246,19.261c-0.105-0.036-0.215-0.065-0.33-0.076C71.06,17.999,61.54,15.374,50.733,5.966
		c-0.606-0.526-1.523-0.463-2.05,0.142c-0.03,0.034-0.039,0.078-0.065,0.114c-10.72,9.262-20.195,11.867-31.975,13.044
		c-0.028,0.003-0.053,0.012-0.08,0.016c-0.046,0.007-0.091,0.016-0.135,0.027c-0.049,0.013-0.096,0.027-0.143,0.044
		c-0.043,0.016-0.084,0.033-0.125,0.053c-0.044,0.021-0.087,0.044-0.128,0.07c-0.039,0.024-0.076,0.049-0.112,0.076
		c-0.038,0.028-0.073,0.058-0.108,0.089c-0.035,0.032-0.068,0.065-0.1,0.101c-0.029,0.033-0.057,0.066-0.084,0.101
		c-0.03,0.04-0.058,0.082-0.084,0.125c-0.022,0.036-0.041,0.072-0.06,0.11c-0.023,0.047-0.043,0.094-0.06,0.143
		c-0.014,0.04-0.027,0.08-0.037,0.121c-0.013,0.051-0.024,0.102-0.032,0.155c-0.006,0.043-0.011,0.086-0.013,0.13
		c-0.002,0.029-0.009,0.056-0.009,0.085v37.356c0,0.129,0.017,0.256,0.051,0.38c6.499,23.965,32.717,35.354,33.83,35.826
		c0.182,0.077,0.375,0.116,0.568,0.116c0.191,0,0.383-0.038,0.563-0.113c1.125-0.474,27.645-11.861,34.143-35.829
		c0.021-0.076,0.035-0.153,0.043-0.232l0.127-1.244c0.005-0.049,0.008-0.099,0.008-0.147V20.711
		C84.666,19.92,84.033,19.279,83.246,19.261z M81.652,57.802c-5.667,20.646-28.01,31.761-31.867,33.542
		c-3.823-1.783-25.84-12.851-31.546-33.471V22.014c11.495-1.271,20.977-4.071,31.588-13.015
		C60.536,17.99,70.101,20.734,81.76,21.982V56.75L81.652,57.802z"
      />
      <path
        d="M58.121,42.295c0,0.803,0.65,1.453,1.453,1.453s1.453-0.65,1.453-1.453c0-4.641-4.179-8.478-9.572-9.072v-7.416
		c0-0.803-0.65-1.453-1.453-1.453s-1.453,0.65-1.453,1.453v7.416c-5.396,0.594-9.576,4.431-9.576,9.072
		c0,4.639,4.181,8.474,9.576,9.068v12.495c-3.786-0.528-6.67-3.083-6.67-6.153c0-0.803-0.65-1.453-1.453-1.453
		s-1.453,0.65-1.453,1.453c0,4.645,4.181,8.485,9.576,9.076v7.412c0,0.803,0.65,1.453,1.453,1.453s1.453-0.65,1.453-1.453v-7.414
		c5.394-0.597,9.572-4.434,9.572-9.074c0-4.638-4.178-8.474-9.572-9.07V36.146C55.24,36.676,58.121,39.228,58.121,42.295z
		 M41.879,42.295c0-3.067,2.883-5.619,6.67-6.149v12.293C44.762,47.91,41.879,45.359,41.879,42.295z M58.121,57.705
		c0,3.065-2.881,5.619-6.666,6.151V51.558C55.24,52.09,58.121,54.641,58.121,57.705z"
      />
    </g>
  </svg>
);

export default Adventage6;
