import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface List {
  data: {
    hrefs: {
      href: string;
      text: string;
    }[];
    newHref: {
      href: string;
      text: string;
    };
  };
}

const List: FC<List> = ({ data }) => {
  return (
    <ul>
      {data.hrefs.map((item, index) => (
        <li key={index}>
          <a href={item.href}>{item.text}</a>
        </li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  const [items, setItems] = useState({
    hrefs: [
      { href: '1.html', text: 'link 1' },
      { href: '2.html', text: 'link 2' },
      { href: '3.html', text: 'link 3' },
    ],
    newHref: { href: '', text: '' },
  });

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setItems({ ...items, newHref: { ...items.newHref, [name]: value } });
  };

  const addNewHref = () => {
    const copyItems = { ...items };
    copyItems.hrefs.push({ href: copyItems.newHref.href, text: copyItems.newHref.text });
    copyItems.newHref = { href: '', text: '' };
    setItems(copyItems);
  };

  return (
    <div>
      <List data={items} />
      <input
        type="text"
        name="href"
        value={items.newHref.href}
        onChange={handleChange}
        placeholder="Link"
      />
      <input
        type="text"
        name="text"
        value={items.newHref.text}
        onChange={handleChange}
        placeholder="Text"
      />
      <button onClick={addNewHref}>Add</button>
    </div>
  );
};

const Task10: FC = () => {
  const taskTitle: string = '10. Дан следующий стейт:';
  const taskCode: string = `
  const [state, setState] = useState({
    hrefs: [
      {href: '1.html', text: 'link 1'},
      {href: '2.html', text: 'link 2'},
      {href: '3.html', text: 'link 3'},
    ]
  });
  `;
  const taskTitle2: string = 'Выведи на экран следующее:';
  const taskCode2: string = `
  <ul>
    <li><a href="1.html">link 1</a></li>
    <li><a href="2.html">link 2</a></li>
    <li><a href="3.html">link 3</a></li>
  </ul>
  `;
  const taskTitle3: string =
    'Сделай 2 инпута и кнопку, по нажатию на которую в список добавится новый li, причем href возьмется из первого инпута, а текст ссылки - из второго.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        hrefs: [
          {href: '1.html', text: 'link 1'},
          {href: '2.html', text: 'link 2'},
          {href: '3.html', text: 'link 3'},
        ],
        inputValues: {href: '', text: ''}
      };
    }
  
    handleRefChange(event){
      this.setState({...this.state, inputValues: {...this.state.inputValues, href: event.target.value}})
    }
  
    handleTextChange(event){
      this.setState({...this.state, inputValues: {...this.state.inputValues, text: event.target.value}})
    }
  
    addField(){
      this.state.hrefs.push(this.state.inputValues);
      this.setState({...this.state, hrefs: this.state.hrefs, inputValues: {href: '', text: ''}});
  
    }
  
    render() {
      const listNames= this.state.hrefs.map((value, index)=>{
        return <li key={index}>
          <a href={value.href}>{value.text}</a>
        </li>
      });
  
      return (
        <div>
          <ul>
            {listNames}
          </ul>
          <div>
            <input
              type="text"
              placeholder="Link"
              value={this.state.inputValues.href}
              onChange={this.handleRefChange.bind(this)}
            />
            <input
              type="text"
              placeholder="Text"
              value={this.state.inputValues.text} 
              onChange={this.handleTextChange.bind(this)}
            />
            <button onClick={()=>this.addField()}>Add</button>
          </div>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ data }) => {
    return (
      <ul>
        {data.hrefs.map((item, index) => (
          <li key={index}>
            <a href={item.href}>{item.text}</a>
          </li>
        ))}
      </ul>
    );
  };

  const App = () => {
    const [items, setItems] = useState({
      hrefs: [
        { href: '1.html', text: 'link 1' },
        { href: '2.html', text: 'link 2' },
        { href: '3.html', text: 'link 3' },
      ],
      newHref: { href: '', text: '' },
    });

    const handleChange = ({ target: { name, value } }) => {
      setItems({ ...items, newHref: { ...items.newHref, [name]: value } });
    };

    const addNewHref = () => {
      const copyItems = { ...items };
      copyItems.hrefs.push({ href: copyItems.newHref.href, text: copyItems.newHref.text });
      copyItems.newHref = { href: '', text: '' };
      setItems(copyItems);
    };

    return (
      <div>
        <List data={items} />
        <input
          type="text"
          name="href"
          value={items.newHref.href}
          onChange={handleChange}
          placeholder="Link"
        />
        <input
          type="text"
          name="text"
          value={items.newHref.text}
          onChange={handleChange}
          placeholder="Text"
        />
        <button onClick={addNewHref}>Add</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskTitle2={taskTitle2}
        taskTitle3={taskTitle3}
        taskCode2={taskCode2}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task10;
