import classNames from 'classnames';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../Components/Container';
import { getNameImage } from '../../utils/common';

import useStyles from './style';

interface BlogPage4Props {
  id: number;
  info: DATA_BLOG_ITEM;
  clas: string;
}

const BlogPage4: FC<BlogPage4Props> = ({ id, info, clas }) => {
  const classes = useStyles();

  return (
    <Container className={classNames(clas, classes.root)}>
      <Helmet>
        <meta name="description" content={info.desc} />
        <title>{info.title}</title>
      </Helmet>
      <div className="blog-wrap">
        <h2 className="blog-title">{info.title}</h2>
        <div className="blog-date">{info.date}</div>
        <div className="blog-image margin-bottom">
          <img src={info.src} alt={getNameImage(id)} className="blog-img" />
        </div>
        <div className="blog-text">
          <p className="blog-text-p">
            According to the US Bureau of Labor Statistics, the demand for software developers will
            grow by 21% by 2028. Yet, despite that and the hiring landscape shift caused by the
            pandemic, hiring really good IT professionals is not easy, especially when it comes to
            short-term contracts. Not easy, but still doable. And we know that because one of our
            clients asked us to find 100+ tech specialists ASAP.
          </p>
          <p className="blog-text-p">
            TL;DR: We analyzed the market, various hiring channels, and payment models. This gave us
            a pool of 85 developers, 30 QA engineers, 25 DevOps, and 20 other professionals
            available to join within two months. Our experience has shown you CAN subcontract 100
            specialists in a fortnight, but boy, will you have to work for it.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default BlogPage4;
