import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

interface ListProps {
  names: string[];
}

const List: FC<ListProps> = ({ names }) => {
  return (
    <ul>
      {names.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  const state = ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'];

  return <List names={state} />;
};

const Task1: FC = () => {
  const taskTitle: string =
    "1. Дан массив имен ['Коля', 'Вася', 'Петя', 'Иван', 'Дима']. Выведи каждый элемент этого массива в отдельной <li> в списке ul.";
  const taskClassSolution: string = `
  class List extends React.Component {
    render() {
      return (
        <ul>
          {this.props.names.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }
  }

  class App extends React.Component {
    state = ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'];

    render() {
      return <List names={this.state} />;
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ names }) => {
    return (
      <ul>
        {names.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  const App = () => {
    const state = ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'];

    return <List names={state} />;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task1;
