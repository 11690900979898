import React, { useEffect, useMemo, useState } from 'react';

import { storageService } from '../../services/StorageService';
import anime from 'animejs';

import useStyle from './style';

const Logo = () => {
  const [isRun, setIsRun] = useState(false);
  const isLogAninmationMode = !storageService.get('animationLogMode');
  const classNames = useStyle({ isStart: isLogAninmationMode });

  const onMouseEnter = () => {
    mouseOverAnimationRotate.restart();
  };

  useEffect(() => {
    anime
      .timeline({
        direction: 'reverse',
        duration: 1500,
        complete: () => isLogAninmationMode && storageService.set('animationLogMode', true),
      })
      .add({
        targets: isLogAninmationMode ? '.logo-wrapper' : '',
        scale: 0,
        easing: 'easeInOutBack',
      })
      .add(
        {
          targets: ' .el',
          color: 'rgb(255, 216, 62, 0)',
          translateX: (el: { getAttribute: (arg0: string) => any }) => el.getAttribute('data-x'),
          translateY: (el: any, i: number) => i + 1 * anime.random(-700, 700),
          scale: (el: any, i: number, l: number) => l - i + anime.random(0.1, 0.5),
          easing: 'easeInCirc',
          rotate: () => anime.random(0, 360),
          delay: (el: any, i: number, l: number) => i + 100,
        },
        '+=1200',
      );
    setIsRun(!isRun);
    // eslint-disable-next-line
  }, [isLogAninmationMode]);

  const mouseOverAnimationRotate = useMemo(
    () =>
      anime({
        targets: '.logo-el',
        rotate: 360,
        duration: 1000,
        delay: (el: any, i: number) => i * 50,
      }),
    // eslint-disable-next-line
    [isRun],
  );

  return (
    <div className={classNames.root}>
      <div className="logo-wrapper " id="logo" onMouseEnter={onMouseEnter}>
        <div className="logo-el">U</div>
        <div className="logo-el">n</div>
        <div className="logo-el">i</div>
        <div className="logo-el">C</div>
        <div className="logo-el">o</div>
        <div className="logo-el">d</div>
        <div className="logo-el">e</div>
        <div className="sub-text">
          <div className="logo-el">S</div>
          <div className="logo-el">t</div>
          <div className="logo-el">u</div>
          <div className="logo-el">d</div>
          <div className="logo-el">i</div>
          <div className="logo-el">o</div>
        </div>
      </div>
      <div className="ml5">
        <div className="text-wrapper">
          <div className="line">
            <div data-x="-500" className="small el">
              U
            </div>
          </div>
          <div className="line">
            <div data-x="-400" className="small el">
              n
            </div>
          </div>
          <div className="line">
            <div data-x="-500" className="small el">
              i
            </div>
          </div>
          <div className="line">
            <div data-x="-600" className="small el">
              C
            </div>
          </div>
          <div className="line">
            <div data-x="-400" className="small el">
              o
            </div>
          </div>
          <div className="line">
            <div data-x="-400" className="small el">
              d
            </div>
          </div>
          <div className="line">
            <div data-x="-500" className="small el">
              e
            </div>
          </div>
          <div className="sub-text">
            <div className="line">
              <div data-x="400" className="small el">
                S
              </div>
            </div>
            <div className="line">
              <div data-x="500" className="small el">
                t
              </div>
            </div>
            <div className="line">
              <div data-x="600" className="small el">
                u
              </div>
            </div>
            <div className="line">
              <div data-x="400" className="small el">
                d
              </div>
            </div>
            <div className="line">
              <div data-x="600" className="small el">
                i
              </div>
            </div>
            <div className="line">
              <div data-x="500" className="small el">
                o
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
