import React from 'react';
import { useTranslation } from 'react-i18next';

const Section5To7 = () => {
  const { t } = useTranslation();

  return (
    <section className="space__section space__section--1">
      <div className="space__slides">
        <div className="space__slide">
          <h2 className="big-text big-text--black">
            <span>{t('section1.digitBusinnes')}</span>
          </h2>
        </div>
        <div className="space__slide">
          <span>{t('section1.wasteTime')}</span>
        </div>
        <div className="space__slide">
          <h2 className="big-text big-text--black">
            <span>
              {t('section1.consultations')} <br />
              {t('section1.provider')}
            </span>
          </h2>
        </div>
      </div>
    </section>
  );
};
export { Section5To7 };
