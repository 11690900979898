import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useHistory, withRouter } from 'react-router-dom';

import Menu from './Menu';

import useStyles from './style';

const { Header } = Layout;

const CustomHeader = () => {
  const history = useHistory();
  const classes = useStyles();

  const [, setPath] = useState([``]);

  useEffect(() => {
    const items = history.location.pathname.split('/');
    let arr;

    if (items[2]) arr = [`/${items[1]}/${items[2]}`];
    else arr = [`/${items[1]}`];

    setPath(arr);
  }, [history.location.pathname]);

  return (
    <Header className={classes.root}>
      <Menu pathname={history.location.pathname} />
    </Header>
  );
};

export default withRouter(CustomHeader);
