import React from 'react';
import ComponentTemplate from '../../Task';

const Task5: React.FC = () => {
  const taskTitle: string =
    '5. Модифицируй предыдущую задачу так, чтобы пользователь мог выбрать несколько вариантов ответа с помощью 4-х чекбоксов.';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task5;
