import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackForm from '../Forms/Feedback';
import { AboutUsModal } from '../AboutUsModal';
import Modal from '../Modal';
import { appState } from '../../Context/secondClass';

const Section1To4 = () => {
  const { t } = useTranslation();

  const [currentModal, setCurrentModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (flag: boolean) => {
    setIsModalOpen(true);
    appState.isModalOpen = true;
    setCurrentModal(flag);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    appState.isModalOpen = false;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    appState.isModalOpen = false;
  };

  return (
    <section className="space__section space__section--0">
      <Modal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}>
        {currentModal ? (
          <AboutUsModal />
        ) : (
          <FeedbackForm handleCancel={handleCancel} handleOk={handleOk} />
        )}
      </Modal>
      <div className="space__slides">
        <div className="space__slide space__slide--diff-o">
          <h2 className="big-text">
            <span>{t('section0.immerse')}</span>
            <span>{t('section0.tech')}</span>
            <span className="big-text__point">{t('section0.us')}</span>
          </h2>
        </div>
        <div className="space__slide space__slide--init-state">
          <span className="noise-vector-1">
            <svg fill="none" viewBox="0 0 623 564" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50.2695 394.123C192.798 288.243 906.269 -131.877 496.269 43.1227"
                stroke="url(#paint0_radial_23_4)"
                strokeWidth="2"
              />
              <path
                d="M432.77 453.623C82.2696 603.623 37.9015 564.623 18.8359 533.623C-0.229662 502.623 -19.7297 446.123 50.2699 394.123"
                stroke="white"
                strokeWidth="2"
              />
              <defs>
                <radialGradient
                  cx="0"
                  cy="0"
                  gradientTransform="translate(526.77 -44.8773) rotate(81.4077) scale(184.066 267.594)"
                  gradientUnits="userSpaceOnUse"
                  id="paint0_radial_23_4"
                  r="1">
                  <stop offset="0.258908" stopColor="white" stopOpacity="0" />
                  <stop offset="1" stopColor="white" />
                </radialGradient>
              </defs>
            </svg>
            <i className="vector-pin"></i>
          </span>
          <span className="noise-vector-2">
            <svg fill="none" viewBox="0 0 499 659" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M165 82.5C321.474 -32.9736 489.359 -19.8471 496.779 95.5"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M482.5 263C478 273.5 463.6 299.9 440 321.5"
                stroke="white"
                strokeLinecap="round"
                strokeWidth="4"
              />
              <path
                d="M101.771 636.05C-101.714 664.23 56.9222 561.482 212.159 479.835"
                stroke="white"
                strokeLinecap="round"
                strokeWidth="6"
              />
            </svg>
            <i className="vector-pin "></i>
            <i className="vector-pin vector-pin--big"></i>
          </span>

          <h2 className="big-text big-text--white">
            <span>{t('section0.digtal')}</span>
            <span>{t('section0.metauniverse')}</span>
          </h2>
        </div>
        <div className="space__slide space__slide--init-state">
          <div className="image__content">
            <div className="small-half">
              <p className="small-half__title">{t('section0.lead')}</p>
              <p className="small-half__description">
                {t('section0.from')}
                <br />
              </p>

              <button className="btn btn-submit" onClick={() => showModal(false)}>
                {t('section0.let_s')}
              </button>
            </div>

            <div className="big-half">
              <div className="slide-3">
                <div className="image-box">
                  <img alt="person" src="assets/imagesHome/slide-3__image-1.png" />
                </div>
                <div className="image-box">
                  <img alt="person" src="assets/imagesHome/slide-3__image-2.png" />
                </div>
                <div className="big-image-box">
                  <div className="big-image-box__fragment">
                    <svg fill="none" viewBox="0 0 977 753" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M279.386 750.687C582.849 594.677 1054.01 195.733 962.958 51.08C941.944 17.6949 857.246 -28.0523 681 34C321.232 162.828 -222.045 318.043 101.968 62.6881"
                        stroke="url(#paint0_linear_708_3406)"
                        strokeWidth="5"
                      />
                      <defs>
                        <linearGradient
                          gradientUnits="userSpaceOnUse"
                          id="paint0_linear_708_3406"
                          x1="198.5"
                          x2="457.704"
                          y1="-94"
                          y2="233.839">
                          <stop stopColor="white" stopOpacity="0" />
                          <stop offset="1" stopColor="white" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span className="big-image-box__pin"></span>
                  </div>
                  <div className="big-image-box__fragment">
                    <svg fill="none" viewBox="0 0 218 467" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_700_3211)">
                        <path
                          d="M86.7386 448.396C17.9694 481.868 -6.54861 440.028 134.578 228.439C241.839 67.6231 262.5 -159.5 5.99916 188"
                          stroke="white"
                          strokeLinecap="round"
                          strokeWidth="5"
                        />
                      </g>
                      <defs>
                        <filter
                          colorInterpolationFilters="sRGB"
                          filterUnits="userSpaceOnUse"
                          height="466.105"
                          id="filter0_d_700_3211"
                          width="216.784"
                          x="0.499023"
                          y="0.0823975">
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dx="1" dy="1" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                          />
                          <feBlend
                            in2="BackgroundImageFix"
                            mode="normal"
                            result="effect1_dropShadow_700_3211"
                          />
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_700_3211"
                            mode="normal"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <span className="big-image-box__pin"></span>
                  </div>
                  <img alt="people group" src="assets/imagesHome/slide-3__main-image.png" />
                  <i className="vector-pin"></i>
                  <i className="vector-pin"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space__slide space__slide--init-state">
          <div className="image__content image__content--reverse">
            <div className="small-half">
              <p className="small-half__title">{t('section0.ourTeam')}</p>
              <p className="small-half__description">
                {t('section0.dev')} <br /> {t('section0.meet')}
              </p>

              <button onClick={() => showModal(true)} className="btn btn-submit">
                {t('section0.let_s')}
              </button>
            </div>

            <div className="big-half">
              <div className="slide-4">
                <div className="big-image-box">
                  <div className="big-image-box__laptop-vector">
                    <svg
                      className="vector-small"
                      fill="none"
                      viewBox="0 0 107 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M105.917 20.8731C97.679 17.8592 74.5722 4.47254 55.685 2.18682C27.7914 -1.18883 11.2033 9.38336 0.831543 22.2796"
                        stroke="white"
                        strokeLinecap="round"
                        strokeWidth="1.60743"
                      />
                    </svg>
                  </div>
                  <div className="big-image-box__laptop-vector">
                    <svg fill="none" viewBox="0 0 278 352" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M97.5001 351C-66.3104 200.933 -28.4673 -53.2039 277.089 11.5"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                    <span className="big-image-box__pin"></span>
                  </div>
                  <div className="big-image-box__laptop-vector">
                    <svg fill="none" viewBox="0 0 619 316" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.5 25.0002C98.9999 108 232.343 170.864 298.5 191.5C789 344.5 197 423.5 618 1"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <img alt="laptop" src="assets/imagesHome/slide-4__main-image.png" />
                  <i className="vector-pin"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export { Section1To4 };
