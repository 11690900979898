import { Button, Form, Input } from 'antd';
import React, { FC } from 'react';

import useStyles from './style';

const ContactWithUs: FC = () => {
  const classes = useStyles();

  const [form] = Form.useForm();

  const handleOnFinish = (values: any) => {
    form.resetFields();
  };

  return (
    <div className={classes.root}>
      <div className="shadow">
        <h2 className="contact-title">Let's get connected to build an excellent application</h2>
        <div className="contact-subtitle">
          Leave your contact details, and we will reach you within 24 hours
        </div>
        <Form
          form={form}
          className="contact-form"
          onFinish={handleOnFinish}
          autoComplete="off"
          initialValues={{
            name: '',
            email: '',
            message: '',
          }}>
          <div className="contact-wrapper">
            <Form.Item name="name">
              <Input
                type="text"
                autoComplete="off"
                readOnly={true}
                onFocus={(event) => event.target.removeAttribute('readonly')}
                allowClear
                placeholder="Your name"
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                type="email"
                autoComplete="off"
                readOnly={true}
                onFocus={(event: any) => event.target.removeAttribute('readonly')}
                allowClear
                placeholder="Your E-mail"
              />
            </Form.Item>
            <Form.Item name="message">
              <Input
                type="text"
                autoComplete="off"
                readOnly={true}
                onFocus={(event: any) => event.target.removeAttribute('readonly')}
                allowClear
                placeholder="Your text"
              />
            </Form.Item>

            <Button htmlType="submit">Contact</Button>
          </div>
          <div className="subLine">
            By clicking on the button, you consent to the processing of personal data according to
            GDPR
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContactWithUs;
