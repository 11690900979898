import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import BlogPage1 from '../../Pages/BlogPage1';
import BlogPage3 from '../../Pages/BlogPage3';
import BlogPage2 from '../../Pages/BlogPage2';
import BlogPage4 from '../../Pages/BlogPage4';
import { DATA_BLOG } from '../../utils/constants';

import useStyles from './style';
import { scrollToTop } from '../../utils/windowFunction';

const BlogFullInfo = () => {
  const classes = useStyles();

  const history = useHistory();

  const numberBlog = Number(history.location.pathname.split('/')[2]);

  useEffect(() => {
    scrollToTop();
  }, []);

  switch (numberBlog) {
    case 1:
      return <BlogPage1 clas={classes.root} id={numberBlog} info={DATA_BLOG[numberBlog - 1]} />;
    case 2:
      return <BlogPage2 clas={classes.root} id={numberBlog} info={DATA_BLOG[numberBlog - 1]} />;
    case 3:
      return <BlogPage3 clas={classes.root} id={numberBlog} info={DATA_BLOG[numberBlog - 1]} />;
    case 4:
      return <BlogPage4 clas={classes.root} id={numberBlog} info={DATA_BLOG[numberBlog - 1]} />;
    default:
      return <div>Error</div>;
  }
};

export default BlogFullInfo;
