import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useStyles from './style';

interface LessonsDropdownProps {
  className: string;
}

const LessonsDropdown: FC<LessonsDropdownProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const numberLessons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <ul className={classNames(classes.root, className)}>
      {numberLessons.map((lesson, i) => {
        const line = `/lesson/${lesson}`;
        return (
          <li key={i}>
            <Link to={line}>
              {t('defaultMenu.lessons')} {lesson}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default LessonsDropdown;
