import React, { FC } from 'react';

import LessonListTemplate from '../LessonListTemplate';
import Task1 from './Task1';
import Task2 from './Task2';
import Task3 from './Task3';
import Task4 from './Task4';
import Task5 from './Task5';
import Task6 from './Task6';
import Task7 from './Task7';
import Task8 from './Task8';
import Task9 from './Task9';
import Task10 from './Task10';
import Task11 from './Task11';
import Task12 from './Task12';
import Task13 from './Task13';
import Task14 from './Task14';
import Task15 from './Task15';
import Task16 from './Task16';

const tasks = [
  <Task1 />,
  <Task2 />,
  <Task3 />,
  <Task4 />,
  <Task5 />,
  <Task6 />,
  <Task7 />,
  <Task8 />,
  <Task9 />,
  <Task10 />,
  <Task11 />,
  <Task12 />,
  <Task13 />,
  <Task14 />,
  <Task15 />,
  <Task16 />,
];

export const tasksQuantity7: number = tasks.length;

const LessonList: FC = () => <LessonListTemplate tasks={tasks} lesson="7" />;

export default LessonList;
