import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface ListProps {
  data: {
    name: string;
    checked: boolean;
  }[];
  func: (index: number) => void;
}

const List: FC<ListProps> = ({ data, func }) => {
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <input checked={item.checked} type="checkbox" onChange={() => func(index)} />
          <span style={{ textDecoration: item.checked ? 'line-through' : 'none' }}>
            {item.name}
          </span>
        </li>
      ))}
    </ul>
  );
};

const TaskReady: FC = () => {
  const [values, setValues] = useState([
    { name: 'David', checked: true },
    { name: 'Alex', checked: false },
    { name: 'Bodya', checked: false },
  ]);

  const handleCheckboxChange = (index: number) => {
    const copyValues = [...values];
    copyValues[index].checked = !copyValues[index].checked;
    setValues(copyValues);
  };

  return <List data={values} func={handleCheckboxChange} />;
};

const Task1: FC = () => {
  const taskTitle: string =
    '1. Дан массив. Выведи элементы этого массива в виде списка ul. Сделайте так, чтобы внутри каждой li был чекбокс, по нажатию на который содержимое чекбокса будет перечеркиваться.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        values: [
          { name: 'David', checked: true },
          { name: 'Alex', checked: false },
          { name: 'Bodya', checked: false },
        ],
      };
    }

    handleCheckboxChange(index) {
      this.state.values[index].checked = !this.state.values[index].checked;
      this.setState(this.state);
    }

    render() {
      return (
        <List
          data={this.state.values}
          func={this.handleCheckboxChange.bind(this)}
        />
      );
    }
  }

  class List extends React.Component {
    render() {
      return (
        <ul>
          {this.props.data.map((item, index) => (
            <li key={index}>
              <input
                checked={item.checked}
                type='checkbox'
                onChange={() => this.props.func(index)}
              />
              <span
                style={{ textDecoration: item.checked ? 'line-through' : 'none' }}
              >
                {item.name}
              </span>
            </li>
          ))}
        </ul>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List= ({ data, func }) => {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <input checked={item.checked} type="checkbox" onChange={() => func(index)} />
            <span style={{ textDecoration: item.checked ? 'line-through' : 'none' }}>
              {item.name}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const App= () => {
    const [values, setValues] = useState([
      { name: 'David', checked: true },
      { name: 'Alex', checked: false },
      { name: 'Bodya', checked: false },
    ]);

    const handleCheckboxChange = (index) => {
      const copyValues = [...values];
      copyValues[index].checked = !copyValues[index].checked;
      setValues(copyValues);
    };

    return <List data={values} func={handleCheckboxChange} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task1;
