import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import * as serviceWorker from './serviceWorker';
import i18n, { I18nextProvider, initPromise } from './i18n';
import { Provider } from 'react-redux';
import AppProviderClass from './Context/firstClass';
import './index.css';
import { store } from './redux';

initPromise.then(() =>
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AppProviderClass>
          <App />
        </AppProviderClass>
      </Provider>
    </I18nextProvider>,
    document.getElementById('root'),
  ),
);

serviceWorker.unregister();
