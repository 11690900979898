import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    minHeight: 'calc(100vh - 198px)',

    '& .list': {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: '0',

      '& .list-item': {
        border: `1px solid ${PALITRA.grey.lightGrey1}`,
        borderRadius: '20px',
        margin: '0px 30px 30px 0px',
        overflow: 'hidden',
        padding: '109px 0px',
        position: 'relative',
        textAlign: 'center',
        width: '22.7%',
        paddingTop: '12%',
        transition: 'all 700ms ease-in-out',

        '&:hover': {
          webkitTransform: 'scale(1.04)',
          msTransform: 'scale(1.04)',
          transform: 'scale(1.04)',
          webkitTransition: 'all 700ms ease',
          mozTransition: 'all 700ms ease',
          msTransition: 'all 700ms ease',
          oTransition: 'all 700ms ease',
          transition: 'all 700ms ease',
        },

        '& a': {
          alignItems: 'center',
          display: 'flex',
          fontSize: '25px',
          fontWeight: '600',
          height: '100%',
          justifyContent: 'center',
          left: '0',
          position: 'absolute',
          top: '0',
          width: '100%',
        },
      },

      '& .link-layer': {
        filter: 'blur(1px)',
        webkitFilter: 'blur(1px)',
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        '&:after': {
          content: `''`,
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          background: 'rgba(0,0,0,.35)',
          zIndex: '2',
        },
      },
      '& .link-text': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '2',
        width: '80%',
        padding: '20px',
        textAlign: 'center',
        color: PALITRA.white,
      },
    },

    '@media (min-width: 1104px)': {
      '& .list-item:nth-child(4n)': {
        marginRight: '0px !important',
      },
    },

    '@media (max-width: 1103px) and (min-width: 901px)': {
      '& .list-item': {
        width: '31% !important',

        '&:nth-child(3n)': {
          marginRight: '0px !important',
        },
      },
    },

    '@media (min-width:551px) and (max-width: 951px)': {
      '& .list': {
        justifyContent: 'space-around',

        '& .list-item': {
          marginRight: '0px !important',
          width: '48% !important',
          paddingTop: '29% !important',
        },
      },
    },

    '@media (max-width: 550px)': {
      '& .list': {
        justifyContent: 'center',

        '& .list-item': {
          marginRight: '0px !important',
          width: '90% !important',
          paddingTop: '65% !important',
        },
      },
    },
  },
});

export default useStyles;
