import React, { FC, useEffect } from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { Layout } from 'antd';

import TaskNavigation from '../Components/TaskNavigation';
import { appState } from '../Context/secondClass';
import { ROUTES } from './routes';

const { Content } = Layout;

const Rouths: FC = () => {
  const { pathname } = useHistory().location;

  const isTask = pathname.includes('lesson') && pathname.includes('task');

  useEffect(() => {
    const checkScreen = () => {
      if (window.innerWidth <= 1280) {
        return (appState.screenSizeDesktop = false);
      } else {
        return (appState.screenSizeDesktop = true);
      }
    };

    checkScreen();

    window.addEventListener('resize', checkScreen);

    return () => window.removeEventListener('resize', checkScreen);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {appState.screenSizeDesktop && isTask && <TaskNavigation className="side-bars left-only" />}

      <Content className="main__block">
        <Switch>
          {ROUTES.map(
            ({ path, element, exact }: IROUTE_MODEL<RouteProps['children']>, index: number) => (
              <Route key={index} path={path} exact={exact}>
                {element}
              </Route>
            ),
          )}
        </Switch>
      </Content>

      {appState.screenSizeDesktop && isTask && <TaskNavigation className="side-bars right-only" />}
    </>
  );
};

export default Rouths;
