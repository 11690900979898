import { createUseStyles } from 'react-jss';

import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '56px',
    lineHeight: '53px',
    padding: '0',
    backgroundColor: PALITRA.headerAndFooter,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
});

export default useStyles;
