import React, { FC, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import FillingField from './fillingField';

import { randomInteger } from '../../utils/common';

import useStyles from './style';

const LETTER = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К'];
const NUMBERS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const Field: FC<FieldArgsType> = ({
  field,
  data,
  setData,
  fieldOwner,
  score,
}) => {
  const selectElem = (rowId: number, colId: number) => {
    const copyData = { ...data };
    if (
      fieldOwner === 'computer' &&
      !copyData.isVictory &&
      !copyData.restart &&
      !checkElem(copyData.compField, rowId, colId)
    ) {
      copyData.compField = placePoint(copyData.compField, rowId, colId);
      copyData.isVictory = checkVictory(copyData.compField, copyData, 'Human');
      copyData.isVictory && copyData.humanScore++;

      if (copyData.compField[rowId][colId] === '+') {
        copyData.hit = true;
      } else {
        copyData.hit = false;
      }

      if (!copyData.isVictory && !copyData.hit) {
        computerStep(copyData.userField);
        copyData.isVictory = checkVictory(
          copyData.userField,
          copyData,
          'Computer'
        );
        copyData.isVictory && copyData.computerScore++;
      }
    }

    setData(copyData);
  };

  const computerStep = (field: string[][]) => {
    let rowId = 0;
    let colId = 0;
    let hit = false;

    do {
      do {
        rowId = randomInteger(0, 9);
        colId = randomInteger(0, 9);
      } while (checkElem(field, rowId, colId));

      placePoint(field, rowId, colId);
      if (field[rowId][colId] === '+') {
        hit = true;
      } else {
        hit = false;
      }
    } while (hit);
  };

  const checkElem = (field: string[][], rowId: number, colId: number) => {
    if (field[rowId][colId] !== 'O' && field[rowId][colId] !== '+') {
      return false;
    }

    return true;
  };

  const placePoint = (field: string[][], rowId: number, colId: number) => {
    if (field[rowId][colId] === 'X') {
      field[rowId][colId] = '+';
    } else {
      field[rowId][colId] = 'O';
    }

    return field;
  };

  const checkVictory = (
    field: string[][],
    copyData: ItemsType,
    user: string
  ) => {
    for (let i = 0; i < field.length; i++) {
      if (field[i].indexOf('X') >= 0) {
        return false;
      }
    }
    copyData.restart = !copyData.restart;
    copyData.resultGame = `${user} WIN!!!`;

    return true;
  };

  const fieldElemClassNames = (
    colItem: string,
    title: string,
    isVictory: boolean
  ) =>
    classNames({
      userShipElem: title === 'human' && colItem === 'X',
      computerShipElem: fieldOwner === 'computer' && colItem !== 'O',
      computerField_victory: isVictory && colItem === 'X',
      shot: colItem === 'O',
      hit: colItem === '+',
    });

  return (
    <table className={classNames('gameField', { userField: fieldOwner === 'human' })}>
      <tbody>
        <tr>
          <td />
          {NUMBERS.map((item, index) => (
            <td key={index}> {item} </td>
          ))}
        </tr>

        {field.map((rowItem, rowIndex) => (
          <tr key={rowIndex}>
            <td>{LETTER[rowIndex]}</td>

            {rowItem.map((columnItem, columnIndex) => (
              <td
                className={fieldElemClassNames(
                  columnItem,
                  fieldOwner,
                  data.isVictory
                )}
                key={columnIndex}
                onClick={() => selectElem(rowIndex, columnIndex)}
              >
                {columnItem}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={11} className="scoreItem">
            Score: {score}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const Task10_9 = () => {
  const classes = useStyles();
  const [items, setItems] = useState<ItemsType>({
    userField: [
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
    ],
    compField: [
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
    ],
    isVictory: false,
    restart: true,
    humanScore: 0,
    computerScore: 0,
    resultGame: '',
    hit: false,
  });

  const newGame = () => {
    const copyItems = { ...items };

    if (copyItems.restart) {
      copyItems.userField = FillingField();
      copyItems.compField = FillingField();
      copyItems.isVictory = false;
      copyItems.restart = false;
    }
    setItems(copyItems);
  };

  return (
    <div className="site-layout-content">
      <div className={classes.root}>
        <Button
          type="primary"
          className={classNames({
            startBtn: true,
            startBtn_active: !items.restart,
          })}
          onClick={newGame}
        >
          {items.restart ? 'NEW GAME' : 'STARTED'}
        </Button>

        <div className="container">
          <Field
            field={items.userField}
            data={items}
            setData={setItems}
            fieldOwner="human"
            score={items.humanScore}
          />

          <Field
            field={items.compField}
            data={items}
            setData={setItems}
            fieldOwner="computer"
            score={items.computerScore}
          />
        </div>
        {items.isVictory && <p className="resultGame">{items.resultGame}</p>}
      </div>
    </div>
  );
};

export default Task10_9;
