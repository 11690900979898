import React from 'react';
import { withRouter } from 'react-router-dom';

const HomeFooter = () => {
  // const classes = useStyles();

  return <div></div>;
};

export default withRouter(HomeFooter);
