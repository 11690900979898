import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

  const showText = () => {
    setState({ ...state, show: !state.show });
  };

  const text = (
    <p>
      имя: {state.name}, возраст: {state.age}
    </p>
  );

  return (
    <div>
      {state.show && text}
      <button onClick={showText}>Нажми на меня</button>
    </div>
  );
};

const Task3: FC = () => {
  const taskTitle: string =
    '3. Переделай предыдущую задачу так, чтобы по первому нажатию на кнопку абзац с текстом показывался, а по второму нажатию - скрывался.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25, show: false };

    showText() {
      this.setState({ show: !this.state.show });
    }

    render() {
      const text = (
        <p>
          имя: {this.state.name}, возраст: {this.state.age}
        </p>
      );

      return (
        <div>
          {this.state.show && text}
          <button onClick={() => this.showText()}>Нажми на меня</button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

    const showText = () => {
      setState({ ...state, show: !state.show });
    };

    const text = (
      <p>
        имя: {state.name}, возраст: {state.age}
      </p>
    );

    return (
      <div>
        {state.show && text}
        <button onClick={showText}>Нажми на меня</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task3;
