import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '&.advantage-item': {
      boxSizing: 'border-box',

      '& .advantage-item-wrapper': {
        display: 'flex',
        alignItems: 'center',

        '& .advantage-icon': {
          width: '50px',
        },

        '& .adventage-item-title': {
          fontSize: '16px',
          fontWeight: '600',
          marginLeft: '20px',
          marginBottom: '0px',
          textAlign: 'left',
        },
      },

      '& .advantage-item-description': {
        marginBottom: '0px',
        fontWeight: '300',
      },

      '@media screen and (max-width: 900px)': {
        borderRight: `1px solid ${PALITRA.grey.lightGrey2}`,
        padding: '14px',

        '&:nth-child(2n)': {
          borderRight: '0px',
          paddingRight: '0px',
        },

        '&:nth-child(-n+4)': {
          borderBottom: `1px solid ${PALITRA.grey.lightGrey2}`,
        },

        '&:nth-child(-n+2)': {
          borderBottom: `1px solid ${PALITRA.grey.lightGrey2}`,
          paddingTop: '0px',
        },
      },

      '@media screen and (max-width: 576px)': {
        borderRight: '0px',
        padding: '14px',

        '&:nth-child(2n)': {
          paddingRight: '14px',
          paddingTop: '14px',
        },

        '&:nth-child(5)': {
          borderBottom: `1px solid ${PALITRA.grey.lightGrey2}`,
        },
      },

      '@media screen and (min-width: 900px)': {
        borderRight: `1px solid ${PALITRA.grey.lightGrey2}`,
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: '14px',

        '&:nth-child(3n)': {
          borderRight: '0px',
          paddingRight: '0px',
        },

        '&:nth-child(-n+3)': {
          borderBottom: `1px solid ${PALITRA.grey.lightGrey2}`,
          paddingTop: '0px',
        },
      },
    },
  },
});

export default useStyles;
