import React, { FC } from 'react';

import LessonListTemplate from '../LessonListTemplate';
import Task1 from './Task1';

const tasks = [<Task1 />];

export const tasksQuantity8: number = tasks.length;

const LessonList: FC = () => <LessonListTemplate tasks={tasks} lesson="8" />;

export default LessonList;
