import React from 'react';
import { DATA_HOW_IT_WORKS } from '../../utils/constants';
import Container from '../Container';

import HowItWorksCard from './HowItWorksCard';

import useStyles from './style';

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <Container
      className={classes.root}
      title="How it works"
      subtitle="We will help you get from the initial product concept to a working service with happy users,
        guiding you through the entire process.">
      <div className="how-work-wrapper">
        {DATA_HOW_IT_WORKS.map((item) => (
          <HowItWorksCard key={item.id} number={item.number} title={item.title} desc={item.desc} />
        ))}
      </div>
    </Container>
  );
};

export default HowItWorks;
