import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  root: {
    '& .wrapper': {
      display: 'grid',

      gap: '20px',
      '@media screen and (min-width: 900px)': {
        gridTemplateColumns: 'repeat(2, minmax(50px, 1fr))',
      },

      '& .about-content': {
        textAlign: 'left',
        '& .about-description': {
          '& .about-item': {
            fontSize: '16px',
          },
        },
      },
      '& .about-images': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(120px, 1fr))',
        gap: '15px',
        '& .ibg': {
          position: 'relative',
          padding: '0px 0px 85% 0px',
          '& img': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
      },
    },
  },
});

export default useStyle;
