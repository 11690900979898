import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { DATA_BLOG } from '../../utils/constants';

import Container from '../Container';
import BlogItem from './BlogItem';

import useStyles from './style';

const Blog: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const moreInformationAboutBlog = (num: number) => {
    history.push(`/blog/${num}`);
  };

  return (
    <Container
      className={classes.root}
      title="UniCode Blog"
      subtitle="Here we publish the most interesting things we experienced during our work">
      <div className="blog-wrap">
        {DATA_BLOG.map((item, k) => (
          <BlogItem
            moreInformation={() => moreInformationAboutBlog(k + 1)}
            title={item.title}
            key={k}
            desc={item.desc}
            date={item.date}
            src={item.src}
          />
        ))}
      </div>
    </Container>
  );
};

export default Blog;
