import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

  const showText = () => {
    setState({ ...state, show: !state.show });
  };

  const text = (
    <p>
      имя: {state.name}, возраст: {state.age}
    </p>
  );

  return (
    <div>
      {state.show && text}
      <button onClick={showText}>{state.show ? 'Скрыть' : 'Показать'}</button>
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string =
    "4. Переделай предыдущую задачу так, чтобы по заходу на страницу текст кнопки был 'показать', а после нажатия на нее - 'скрыть' (ну и так далее - по каждому нажатию текст должен чередоваться).";
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { name: 'Иван', age: 25, show: false };

    showText() {
      this.setState({ show: !this.state.show });
    }

    render() {
      const text = (
        <p>
          имя: {this.state.name}, возраст: {this.state.age}
        </p>
      );

      return (
        <div>
          {this.state.show && text}
          <button onClick={() => this.showText()}>
            {this.state.show ? 'Скрыть' : 'Показать'}
          </button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [state, setState] = useState({ name: 'Иван', age: 25, show: false });

    const showText = () => {
      setState({ ...state, show: !state.show });
    };

    const text = (
      <p>
        имя: {state.name}, возраст: {state.age}
      </p>
    );

    return (
      <div>
        {state.show && text}
        <button onClick={showText}>
          {state.show ? 'Скрыть' : 'Показать'}
        </button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task4;
