import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

const List = ({ names, removeFunc }: any) => {
  return (
    <ul>
      {names.map((item: string, index: number) => (
        <li key={index}>
          {item}
          <button onClick={() => removeFunc(index)}>Удалит</button>
        </li>
      ))}
    </ul>
  );
};

const TaskReady = () => {
  const [state, setState] = useState({ items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] });

  const addElement = () => {
    state.items.push('пункт');
    setState({ ...state });
  };

  const removeElement = (index: number) => {
    state.items.splice(index, 1);
    setState({ ...state });
  };

  return (
    <div>
      <List names={state.items} removeFunc={removeElement} />
      <button onClick={addElement}>Добавить</button>
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string =
    '4. Переделай предыдущую задачу так, чтобы рядом с каждым именем появилась кнопка, по нажатию на которую li с этим именем будет удаляться.';
  const taskClassSolution: string = `
  class List extends React.Component {
    render() {
      return (
        <ul>
          {this.props.names.map((item, index) => (
            <li key={index}>
              {item}
              <button onClick={() => this.props.removeFunc(index)}>Удалить</button>
            </li>
          ))}
        </ul>
      );
    }
  }

  class App extends React.Component {
    state = { items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] };

    addElement() {
      this.state.items.push('пункт');
      this.setState(this.state);
    }

    removeElement(index) {
      this.state.items.splice(index, 1);
      this.setState({ ...this.state });
    }

    render() {
      return (
        <div>
          <List names={this.state.items} removeFunc={this.removeElement.bind(this)} />
          <button onClick={() => this.addElement()}>Добавить</button>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List = ({ names, removeFunc }) => {
    return (
      <ul>
        {names.map((item, index) => (
          <li key={index}>
            {item}
            <button onClick={() => removeFunc(index)}>Удалит</button>
          </li>
        ))}
      </ul>
    );
  };

  const App = () => {
    const [state, setState] = useState({ items: ['Коля', 'Вася', 'Петя', 'Иван', 'Дима'] });

    const addElement = () => {
      state.items.push('пункт');
      setState({ ...state });
    };

    const removeElement = (index) => {
      state.items.splice(index, 1);
      setState({ ...state });
    };

    return (
      <div>
        <List names={state.items} removeFunc={removeElement} />
        <button onClick={addElement}>Добавить</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task4;
