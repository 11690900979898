import { createUseStyles } from 'react-jss';

import { PALITRA } from '../../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '&.menu-navigation__icon': {
      '& span': {
        position: 'absolute',
        top: '18px',
        right: '16px',
        opacity: '0',
        pointerEvents: 'none',
        zIndex: '9999',

        '& svg': {
          fill: PALITRA.white,
          fontSize: '24px',
        },
      },
    },

    '@media (max-width: 900px)': {
      '&.menu-navigation__icon span': {
        opacity: '1 !important',
        pointerEvents: 'all !important',
        cursor: 'pointer',
        transition: 'opacity 0.7s',
      },
    },
  },
});

export default useStyles;
