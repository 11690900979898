import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    userSelect: 'none',

    '& table': {
      borderCollapse: 'collapse',
      textAlign: 'center',

      '& td': {
        border: `1px solid ${PALITRA.white}`,
        width: '40px',
        height: '40px',
      },

      '@media only screen and (max-width: 550px)': {
        '& td': {
          width: '30px !important',
          height: '30px !important',
        },
      },

      '@media only screen and (max-width: 450px)': {
        '& td': {
          width: '20px !important',
          height: '20px !important',
          lineHeight: '20px !important',
        },
      },
    },

    '& .startBtn': {
      display: 'block',
      width: '200px',
      margin: '20px auto',
      background: PALITRA.green.greenLight,
      borderColor: PALITRA.green.greenLight,
      transition: 'all 1s ease',
      fontWeight: '600',
    },

    '& .startBtn_active': {
      background: PALITRA.green.greenDark,
      borderColor: PALITRA.green.greenDark,
    },

    '& .container': {
      margin: '50px auto',
      width: '100%',
      display: 'flex',

      '@media only screen and (max-width: 1050px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    '& .userField': {
      marginRight: '100px',

      '@media only screen and (max-width: 1050px)': {
        marginRight: '0px',
        marginBottom: '20px',
      },
    },

    '& .computerShipElem': {
      color: 'transparent',
    },

    '& .userShipElem': {
      background: PALITRA.green.greenGrey,
      color: 'transparent',
    },

    '& .computerField_victory': {
      background: PALITRA.green.greenGrey,
    },

    '& .shot': {
      fontWeight: '900',
      color: PALITRA.red.redBrown,
    },

    '& .hit': {
      color: 'transparent',
      background: PALITRA.red.redBrown,
    },

    '& .scoreItem': {
      fontSize: '20px',
    },

    '& .resultGame': {
      fontWeight: '700',
      fontSize: '24px',
      color: PALITRA.black,
      margin: '0 auto',
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      backgroundColor: PALITRA.green.greenDark,
    },
  },
});

export default useStyles;
