import { createUseStyles } from 'react-jss';

const useSyles = createUseStyles({
  root: {
    position: 'absolute',
    top: '-40%',
    right: '10px',

    '& .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner':
      {
        borderColor: 'rgb(115, 197, 123)',
      },

    '& .ant-checkbox-checked': {
      '&::after': {
        border: '1px solid rgb(115, 197, 123)',
      },
      '& .ant-checkbox-inner': {
        width: '35px',
        height: '35px',
        backgroundColor: 'rgb(115, 197, 123)',
        borderColor: 'rgb(115, 197, 123)',
      },
      '& .ant-checkbox-inner::after': {
        top: '47%',
        left: '31%',
        width: '10.714286px',
        height: '16.142857px',
      },
    },

    '& .ant-checkbox': {
      '& .ant-checkbox-inner': {
        width: '35px',
        height: '35px',
      },
    },
  },
});

export default useSyles;
