import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '& .blog-wrap': {
      marginTop: '50px',
      display: 'grid',
      rowGap: '20px',
      '@media screen and (max-width: 576px)': {
        rowGap: '0px',
      },
    },
  },
});

export default useStyles;
