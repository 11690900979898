import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyle = createUseStyles({
  // keyframes for our team
  '@keyframes wrapperLogo': {
    from: {
      scale: '1',
    },
    to: {
      scale: '0',
    },
  },
  '@keyframes circleAnim': {
    from: {
      opacity: '1',
    },
    to: {
      opacity: '0',
    },
  },
  '@keyframes unicorn-in': {
    '0%': {
      top: '35%',
      left: '60%',
      width: '6vh',
      height: '6vh',
      transform: 'translate3d(0, 0, -1140px)',
    },
    '100%': {
      top: '25%',
      left: '50%',
      width: '40vh',
      height: '40vh',
      transform: 'translate3d(0, 0, -1420px)',
    },
  },
  '@keyframes unicorn-out': {
    '0%': {
      top: '25%',
      left: '50%',
      width: '40vh',
      height: '40vh',
      transform: 'translate3d(0, 0, -1420px)',
    },
    '100%': {
      top: '35%',
      left: '60%',
      width: '6vh',
      height: '6vh',
      transform: 'translate3d(0, 0, -1140px)',
    },
  },
  '@keyframes section-1In': {
    '0%': {
      transform: 'translate3d(0, 0, 2400px)',
    },
    '100%': {
      transform: 'translate3d(-15%, 10%, 3200px)',
    },
  },

  // те что при переносе ломают код

  // '@keyframes image-pulse': {
  //   '0%': {
  //     transform: 'translate(var(--translate-x), var(--translate-y))',
  //   },
  //   '100%': {
  //     transform: 'translate(var(--translate-x), -3.25vh)',
  //   },
  // },
  // '@keyframes bubble-pulse': {
  //   '0%': {
  //     transform: 'translate3d(0, 0, var(--bubble-z))',
  //   },
  //   '100%': {
  //     transform: 'translate3d(var(--bubble-x), var(--bubble-y), var(--bubble-z))',
  //   },
  // },

  // '@keyframes strokeIn': {
  //   '0%': {
  //     strokeDashoffset: 'var(--start-in)',
  //   },
  //   '100%': {
  //     strokeDashoffset: 'var(--start-out)',
  //   },

  //   '@keyframes wrapperLogo': {
  //     '0%': {
  //       scale: '1',
  //     },
  //     '100%': {
  //       scale: '0',
  //     },
  //   },
  // },
  root: {
    'body, html': {
      margin: '0',
      padding: '0',
      fontFamily: 'sans-serif',
      overflowY: 'auto',
    },
    '& .wrapper': {
      transform: 'rotate(180deg)',
    },
    '& .function-based-values-demo': {
      position: 'absolute',
      zIndex: '50',
    },
    '& .btn': {
      width: 'max-content',
      maxWidth: '185px',
      padding: '10px 24px',
      fontSize: '15px',
      fontWeight: '500',
      borderRadius: '8px',
      transition: 'ease-in-out 0.35s',
      cursor: 'pointer',
      border: '2px solid transparent',
      backgroundColor: 'transparent',
    },
    '& .btn.btn-submit': {
      color: PALITRA.black,
      borderColor: PALITRA.yellow,
      backgroundColor: PALITRA.yellow,
      boxShadow: '2px 2px 4px rgb(0 0 0 / 25%)',
      '&:hover': {
        color: PALITRA.yellow,
        backgroundColor: 'transparent',
      },
    },

    '& .btn.btn-submit.btn-submit--position': {
      marginTop: '3vh',
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '& .btn.btn-outline': {
      display: 'block',
      float: 'right',
      marginRight: '70px',
      margin: '0px auto',
      color: PALITRA.red.redPink,
      borderColor: PALITRA.red.redPink,
      '&:hover': {
        color: PALITRA.white,
        backgroundColor: PALITRA.red.redPink,
      },
    },

    '& .btn.btn-outline-white': {
      transition: 'none',
      color: PALITRA.white,
      borderColor: PALITRA.white,
      '&:hover': {
        color: PALITRA.yellow,
        borderColor: PALITRA.yellow,
      },
    },

    '& .header': {
      width: '100%',
      height: '68px',
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      top: '0',
      left: '0',
      zIndex: '999',
      padding: '0 20px',
    },
    '& .header.header__init-state .header__logo': {
      width: '110px',
      height: '60px',
      left: '50%',
      transform: 'translate(-50%, 15px)',
    },
    '& .header.header__init-state .header__actions': {
      opacity: '0',
    },
    '& .header .header__logo': {
      width: '165px',
      height: '75px',
      position: 'relative',
      left: '0',
      transform: 'translate(0, 0)',
      transition: 'linear 0.5s',
    },
    '& .header .header__logo svg path': {
      fill: PALITRA.white,
      transition: 'ease 0.5s',
    },
    '& .header.header--black .header__logo svg path': {
      fill: PALITRA.black,
    },
    '& .header.header--black .header__hamburger': {
      backgroundColor: PALITRA.red.redPink,
    },
    '& .header.header--black .header__hamburger span': {
      backgroundColor: PALITRA.yellow,
    },
    '& .header.header--black.header--black .btn.btn-outline-white': {
      borderColor: PALITRA.red.redPink,
      color: PALITRA.red.redPink,
    },
    '& .header .header__hamburger': {
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '3px',
      backgroundColor: PALITRA.white,
      borderRadius: '8px',
      border: 'none',
      transition: 'ease-out 0.5s',
      cursor: 'pointer',
    },
    '& .header .header__hamburger span': {
      width: '20px',
      height: '3px',
      borderRadius: '2px',
      backgroundColor: PALITRA.red.redPink,
      zIndex: '10000',
      transition: 'all ease-out 0.35s',
    },
    '& .header .header__nav': {
      position: 'fixed',
      width: '20vw',
      height: '100vh',
      top: '50%',
      right: '-15px',
      left: 'unset',
      paddingTop: '80px',
      background: PALITRA.white,
      transform: 'translate(100%, -50%)',
      transition: 'right ease-in-out 0.35s',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
      zIndex: '-1',
    },
    '& .header.show-nav .header__nav': {
      right: '20vw',
    },
    '& .header.show-nav .header__hamburger span': {
      '&:nth-child(1)': {
        transform: 'translateY(6px) rotate(45deg)',
      },
      '&:nth-child(2)': {
        opacity: '0',
      },
      '&:nth-child(3)': {
        transform: 'translateY(-6px) rotate(-45deg)',
      },
    },

    '& .header .header__nav .copyright': {
      width: 'calc(100% - 16px)',
      position: 'absolute',
      left: '50%',
      bottom: '20px',
      transform: 'translateX(-50%)',
    },
    '& .header .header__nav .copyright p': {
      margin: '0',
      fontSize: '15px',
      fontWeight: '400',
      textAlign: 'center',
    },
    '& .header .header__nav ul': {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      gap: '32px',
      margin: '0',
      padding: '20px 32px',
      fontSize: '18px',
      listStyleType: 'none',
    },
    '& .header__link': {
      color: 'rgba(0, 0, 0, 0.85)',
    },
    '& .el': {
      position: 'relative',
      color: 'inherit',
    },
    '& .el::after': {
      position: 'absolute',
      top: '15px',
      right: '-10px',
      content: '""',
      width: '60px',
      height: '60px',
      background: 'rgb(255, 216, 62)',
      opacity: '0',
      borderRadius: '50%',
      animationName: '$circleAnim',
      animationDuration: '1000ms',
      animationTimingFunction: 'ease-in',
    },
    '& .small': {
      textAlign: 'center',
    },
    '& .sub-text': {
      display: 'flex',
      color: 'inherit',
      marginLeft: '.2em',
    },

    '& .logo-wrapper': {
      color: PALITRA.white,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'absolute',
      zIndex: 1000,
      top: 15,
      left: 20,
      fontSize: '1.7em',
      fontWeight: 600,
      cursor: 'default',
      userSelect: 'none',
    },

    '& .header .header__nav ul li': {
      cursor: 'pointer',
      transition: 'color ease-in-out 0.35s',
      '&:hover': {
        color: PALITRA.pink,
      },
    },

    '& .header .header__actions': {
      width: '100%',
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      transition: 'ease-in 0.35s',
    },
    '& .vector-pin': {
      width: '2vh',
      height: '2vh',
      position: 'absolute',
      top: '0',
      left: '0',
      backgroundColor: PALITRA.yellow,
      borderRadius: '50%',
      outline: `3px solid ${PALITRA.red.redPink}`,
    },
    '& .vector-pin--big': {
      width: '3vh',
      height: '3vh',
    },
    '& .space': {
      width: '100vw',
      height: '100vh',
      transform: 'perspective(700px) translate3d(0, 0, 0)',
      perspective: '700px',
      '-webkit-perspective': '700px',
      backgroundColor: PALITRA.red.redPink,
      overflow: 'hidden',
      transition: 'all ease-in-out 0.35s',
    },
    '& .space.space--with-menu': {
      width: 'calc(100vw - 20vw)',
    },
    '& .space__control': {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      top: '0',
      left: '0',
      transform: 'translate3d(0, 0, 900px)',
      transformStyle: 'preserve-3d',
      '-webkit-transform-style': 'preserve-3d',
      transition: 'all ease-out 0.5s',
    },
    '& .space__control.space--animate-1': {
      animation: '2s section-1In',
    },
    '& .space__section': {
      width: '100%',
      height: '100vh',
      position: 'fixed',
      top: '0',
      left: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .space__section.space__section--0': {
      transform: 'translate3d(0, 0, 0)',
      transformStyle: 'preserve-3d',
    },
    '& .space__section.space__section--1': {
      transform: 'translate3d(15%, -10%, -3000px)',
      transformStyle: 'preserve-3d',
    },
    '& .space__section.space__section--2': {
      transform: 'translate3d(15%, 100%, -5100px)',
      transformStyle: 'preserve-3d',
    },
    '& .space__slides': {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      top: '0',
      left: '0',
      transform: 'translate3d(0px, 0px, -500px)',
      transformStyle: 'preserve-3d',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
    },

    // когда убираешь этот стиль из app.css и ставишь тут, работает не коректно

    // '& .space__slide': {
    //   position: 'absolute',
    //   transformStyle: 'preserve-3d',
    //   opacity: '0',
    //   transition: 'ease-in-out 5s',
    // },

    '& .space__slide.space__slide--diff-o': {
      opacity: '1',
    },
    '& .space__section--0 .space__slide': {
      '&:nth-child(1)': {
        transform: 'translate3d(0, 0, 0)',
      },
      '&:nth-child(2)': {
        transform: 'translate3d(0, 0, -500px)',
      },
      '&:nth-child(3)': {
        transform: 'translate3d(0, 0, -1000px)',
      },
      '&:nth-child(4)': {
        transform: 'translate3d(0, 0, -1500px)',
      },
    },

    '& .image__content': {
      display: 'flex',
      alignItems: 'flex-end',
    },
    '& .image__content.image__content--reverse': {
      flexDirection: 'row-reverse',
    },
    '& .image__content .big-half': {
      flex: '2',
    },
    '& .small-half': {
      userSelect: 'none',
    },
    '& .image__content .small-half': {
      flex: '1',
      minWidth: '25vw',
      marginBottom: '5vh',
    },
    '& .image__content .small-half .small-half__title': {
      color: PALITRA.white,
      fontSize: '6vh',
      fontWeight: '500',
      margin: '8px 0 12px',
    },
    '& .image__content .small-half .small-half__description': {
      color: PALITRA.white,
      fontSize: '2vh',
      fontWeight: '500',
      margin: '4px 0 24px',
    },
    '& .image__content.image__content--reverse .small-half': {
      marginLeft: '7.5vw',
    },
    '& .big-image-box': {
      width: '50vh',
      height: '50vh',
      borderRadius: '50%',
      position: 'relative',
      '&:before': {
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '-6px',
        top: '-6px',
        borderRadius: '50%',
        border: `6px solid ${PALITRA.red.redPink}`,
      },
    },

    '& .image-box': {
      position: 'absolute',
      borderRadius: '50%',
      background: PALITRA.yellow,
      overflow: 'hidden',
      zIndex: '1',
      animation: '4s image-pulse alternate infinite',
    },
    '& .image-box img': {
      width: '100%',
      height: '100%',
    },
    '& .space__slide--init-state .noise-vector-1': {
      display: 'none',
    },
    '& .space__slide--init-state .noise-vector-2': {
      display: 'none',
    },
    '& .noise-vector-1': {
      display: 'block',
      position: 'absolute',
      top: '-60%',
      left: '-40%',
      width: '65%',
    },
    '& .noise-vector-1 svg path:nth-child(1)': {
      '--start-in': '-900',
      '--end-in': '900',
      strokeDasharray: '900',
      strokeDashoffset: '900',
      animation: '0.8s strokeIn ease-in forwards',
    },
    '& .noise-vector-1 svg path:nth-child(2)': {
      '--start-in': '-600',
      '--end-in': '600',
      strokeDasharray: '600',
      strokeDashoffset: '600',
      animation: '0.55s strokeIn 1s ease-out forwards',
    },

    '& .noise-vector-1 .vector-pin': {
      top: '90%',
      width: '1.5vh',
      height: '1.5vh',
    },
    '& .noise-vector-2': {
      display: 'block',
      position: 'absolute',
      top: '-75%',
      right: '-10%',
      width: '60%',
    },
    '& .noise-vector-2 svg path': {
      '&:nth-child(1)': {
        '--start-in': '400',
        '--end-in': '-400',
        strokeDasharray: '400',
        strokeDashoffset: '400',
        animation: '0.65s strokeIn 2s ease-out forwards',
      },
      '&:nth-child(2)': {
        '--start-in': '100',
        '--end-in': '-100',
        strokeDasharray: '100',
        strokeDashoffset: '100',
        animation: '0.2s strokeIn 2.75s linear forwards',
      },
      '&:nth-child(3)': {
        '--start-in': '-500',
        '--end-in': '500',
        strokeDasharray: '500',
        strokeDashoffset: '500',
        animation: '0.55s strokeIn 3s ease-out forwards',
      },
    },

    '& .noise-vector-2 .vector-pin': {
      top: '-2%',
      left: '80%',
    },

    '& .noise-vector-2 .vector-pin--big': {
      top: '22%',
      left: '93%',
    },

    '& .space__slide--init-state .big-half .slide-3 .big-image-box .big-image-box__fragment': {
      animation: 'none',
    },
    '& .slide-3': {
      position: 'relative',
    },
    '& .slide-3 .image-box': {
      '&:nth-child(1)': {
        translateX: '-55%',
        translateY: '-25%',
        top: '0',
        left: '0',
        transform: 'translate(-55%, -25%)',
        width: '15vh',
        height: '15vh',
        zIndex: '-1',
        animation: '3s image-pulse alternate infinite',
      },
      '&:nth-child(2)': {
        translateX: '-10%',
        translateY: '45%',
        bottom: '0',
        right: '0',
        transform: 'translate(-10%, 45%)',
        width: '20vh',
        height: '20vh',
        animation: '8s image-pulse alternate infinite',
      },
    },

    '& .slide-3 .big-image-box': {
      translateX: '0',
      translateY: '0',
      margin: '0 auto',
      background: PALITRA.white,
      animation: '6s image-pulse alternate infinite',
    },
    '& .slide-3 .big-image-box img': {
      width: '105%',
      height: '125%',
      transform: 'translate(-2.25%, -18%)',
    },
    '& .slide-3 .big-image-box .big-image-box__fragment': {
      '&:nth-child(1)': {
        '--start-in': '-2500',
        '--start-out': '500',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '135%',
        height: '110%',
        transform: 'translate(-20%, 0)',
        strokeDasharray: '5000',
        strokeDashoffset: '5000',
        animation: '3s strokeIn ease-in forwards',
        zIndex: '-1',
      },
      '&:nth-child(2)': {
        '--start-in': '1000',
        '--start-out': '0',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '28.75%',
        height: '65%',
        transform: 'translate(0, 14.75%)',
        strokeDasharray: '1000',
        strokeDashoffset: '1000',
        animation: '1s strokeIn 2.75s ease-out forwards',
        zIndex: '1',
      },
    },
    '& .slide-3 .big-image-box .big-image-box__fragment:nth-child(1) .big-image-box__pin': {
      top: '4%',
      right: '0',
      width: '6%',
      height: '7.5%',
      animation: '0.35s simpleScaleUp 1s ease-in forwards',
    },

    '& .slide-3 .big-image-box .big-image-box__fragment:nth-child(2) .big-image-box__pin': {
      bottom: '15%',
      left: '12%',
      width: '19.5%',
      height: '9%',
      animation: '0.35s simpleScaleUp 2s ease-in forwards',
    },
    '& .slide-3 .big-image-box .vector-pin': {
      '&:nth-child(4)': {
        top: '95%',
        left: '4%',
        width: '1.5vh',
        height: '1.5vh',
        zIndex: '1',
      },
      '&:nth-child(5)': {
        width: '3vh',
        height: '3vh',
        left: '97.5%',
      },
    },

    '& .space__slide--init-state .big-half .slide-4 .big-image-box .big-image-box__laptop-vector': {
      animation: 'none',
    },
    '& .slide-4 .big-image-box': {
      translateX: '0',
      translateY: '0',
      width: '52.5vh',
      height: '52.5vh',
      backgroundColor: PALITRA.yellow,
      animation: '8s image-pulse alternate infinite',
    },
    '& .slide-4 .big-image-box img': {
      width: '115%',
      height: '118%',
      transform: 'translate(-12.5%, -16%) rotateZ(4.5deg)',
    },
    '& .slide-4 .big-image-box .big-image-box__laptop-vector': {
      '&:nth-child(1)': {
        '--start-in': '-1000',
        '--start-out': '1000',
        position: 'absolute',
        top: '0',
        right: '0',
        width: '90%',
        height: '20%',
        transform: 'translate(58%, 100%)',
        strokeDasharray: '2000',
        strokeDashoffset: '2000',
        animation: '1s strokeIn 2s ease-out forwards',
        zIndex: '1',
      },
      '&:nth-child(2)': {
        '--start-in': '2000',
        '--start-out': '0',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '45%',
        height: '60%',
        transform: 'translate(-33.5%, 0)',
        strokeDasharray: '2000',
        strokeDashoffset: '2000',
        animation: '1s strokeIn 1s linear forwards',
      },
      '&:nth-child(3)': {
        '--start-in': '-2000',
        '--start-out': '0',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '105%',
        height: '55%',
        transform: 'translate(0px, 95%)',
        strokeDasharray: '2000',
        strokeDashoffset: '2000',
        animation: '1s strokeIn linear forwards',
        zIndex: '1',
      },
    },

    '& .slide-4 .big-image-box .big-image-box__laptop-vector:nth-child(2) .big-image-box__pin': {
      top: '10%',
      left: '12%',
      width: '10%',
      height: '8.33%',
      animation: '0.35s simpleScaleUp 2.5s ease-in forwards',
    },

    '& .slide-4 .big-image-box .vector-pin': {
      width: '2.5vh',
      height: '2.5vh',
      top: '12.5%',
      left: '-15%',
    },
    '& .slide-4 .big-image-box .big-image-box__laptop-vector .vector-small': {
      display: 'none',
    },

    '& .space__section--1 .space__slide': {
      '&:nth-child(1)': { transform: 'translate3d(0, 0, 0)' },
      '&:nth-child(2)': { transform: 'translate3d(0, 0, -600px)' },
      '&:nth-child(3)': { transform: 'translate3d(0, 0, -1200px)' },
    },

    '& .space__section--2 .space__slide': {
      '&:nth-child(1)': { transform: 'translate3d(0, 0, 550px)' },
      '&:nth-child(2)': { transform: 'translate3d(0, 0, -50px)' },
    },

    '& .finale-slide': {
      opacity: '1',
      position: 'relative',
      // padding: '15vh 10vw',
      backgroundColor: 'rgb(255 255 255 / 40%)',
    },
    '& .finale-slide__title': {
      color: PALITRA.blue.blueBlack,
      fontSize: '6.5vh',
      fontWeight: '500',
      textAlign: 'center',
    },
    '& .finale-slide__benefits': {
      display: 'flex',
      gap: '30px',
      justifyContent: 'center',
      padding: '0 20px',
    },
    '& .finale-slide__benefits .benefit:nth-child(1)': {
      position: 'relative',
    },

    '& .top': {
      top: '30%',
    },

    '& .benefit': {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& .finale-slide__benefits .benefit': {
      maxWidth: '264px',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    '& .finale-slide__benefits .benefit .benefit__icon': {
      maxHeight: '25vh',
    },
    '& .finale-slide__benefits .benefit .benefit__icon img': {
      width: '100%',
      height: '100%',
    },
    '& .finale-slide__benefits .benefit .benefit__title': {
      color: PALITRA.blue.blueBlack,
      fontSize: '28px',
      fontWeight: '500',
      margin: '0 0 18px',
    },
    '& .finale-slide__benefits .benefit .benefit__description': {
      color: PALITRA.blue.blueBlack,
      fontSize: '18px',
      fontWeight: '400',
      margin: '0 0 20px',
      textAlign: 'center',
    },
    '& .finale-slide__benefits .benefit button': {
      marginTop: 'auto',
    },
    '& .big-text': {
      margin: '0',
      opacity: '1',
      color: PALITRA.pink,
      position: 'relative',
      fontSize: '7vh',
      fontWeight: '500',
      textAlign: 'center',
      userSelect: 'none',
    },
    '& .big-text.big-text--white': {
      color: PALITRA.white,
    },
    '& .big-text.big-text--black': {
      color: PALITRA.black,
    },
    '& .big-text span': {
      display: 'block',
      lineHeight: '1',
    },
    '& .big-text .big-text__point': {
      position: 'relative',
    },
    '& .big-text .big-text__point::after': {
      content: "''",
      position: 'absolute',
      bottom: '1.5vh',
      right: '-0.65vw',
      width: '1vh',
      height: '1vh',
      borderRadius: '50%',
      background: PALITRA.yellow,
    },
    '& .space__bubbles': {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: '0',
      left: '0',
      transform: 'translate3d(0px, 0px, -900px)',
      transformStyle: 'preserve-3d',
    },

    '& .space__bubbles .space__bubble': {
      '--bubble-x': '0.75vh',
      '--bubble-y': '2vh',
      '--bubble-z': '0',
      position: 'absolute',
      top: '20%',
      left: '10%',
      width: '6vh',
      height: '6vh',
      opacity: '0.85',
      background: PALITRA.pink,
      transform: 'translate3d(0, 0, 0)',
      borderRadius: '50%',
      animation: '3s bubble-pulse alternate infinite',
      '&:nth-child(even)': {
        '--bubble-x': '-0.75vh',
        '--bubble-y': '-2vh',
        '--bubble-z': '0',
        top: '65%',
        left: '85%',
        opacity: '0.25',
        width: '4vh',
        height: '4vh',
        filter: 'blur(2px)',
        '--webkit-filter': 'blur(2px)',
        animation: '2s bubble-pulse alternate infinite',
      },
      '&:nth-child(5n+1)': {
        '--bubble-x': '-2.75vh',
        '--bubble-y': '-4vh',
        width: '7.5vh',
        height: '7.5vh',
        opacity: '0.75',
        background: PALITRA.orange,
        animation: '4s bubble-pulse alternate-reverse infinite',
      },
      '&:nth-child(7n+1)': {
        '--bubble-x': '5vh',
        '--bubble-y': '5vh',
        width: '3vh',
        height: '3vh',
        opacity: '0.9',
        filter: 'blur(2px)',
        '--webkit-filter': 'blur(2px)',
        background: PALITRA.yellow,
        animation: '5s bubble-pulse alternate infinite',
      },
    },

    '& .space__bubbles .space__bubble-unicorn': {
      position: 'absolute',
      top: '35%',
      left: '60%',
      width: '6vh',
      height: '6vh',
      backgroundColor: PALITRA.yellow,
      transform: 'translate3d(0, 0, -1140px)',
      borderRadius: '50%',
    },
    '& .space__bubbles .space__bubble-unicorn.space__bubble-unicorn--animate-in': {
      animation: '0.5s unicorn-in 0.25s ease-out forwards',
    },
    '& .space__bubbles .space__bubble-unicorn.space__bubble-unicorn--animate-out': {
      animation: '0.15s unicorn-out ease-in forwards',
    },

    '& .ml5': {
      position: 'absolute',
      zIndex: '10',
      fontWeight: '800',
      fontSize: '2.5em',
      color: PALITRA.red.redDark,
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },

    '& .ml5 .text-wrapper': {
      display: 'flex',
      color: 'aliceblue',
      animationDuration: '1s',
      animationDelay: '2000ms',
      animationName: 'wrapperLogo',
      animationTimingFunction: 'cubic-bezier(0.05, -0.47, 0.71, 0.31)',
      animationFillMode: 'forwards',
    },

    '& .letters.letters-left': {
      animationDelay: '2s',
      animationDuration: '1s',
      animationName: 'logoUni',
      animationFillMode: 'forwards',
    },

    '& .swiper': {
      width: ' 965px',
      height: '200px',
      zIndex: '7',
      position: 'absolute',
      top: '74%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: '100px',
    },
    '& .wrapperBenefit': {
      display: 'flex',
    },
    '& .swiper .slick-prev': {
      left: '10px',
      '& :before': {
        color: PALITRA.red.red,
        display: 'none',
      },
    },

    '& .swiper .slick-next': {
      right: '10px',
      '& :before': {
        color: PALITRA.red.red,
        display: 'none',
      },
    },

    '& .leftArrow': {
      position: 'absolute',
      zIndex: '80',
      top: '144px',
      left: '0px',
    },
    '& .rightArrow': {
      position: 'absolute',
      top: '144px',
      right: '0px',
      zIndex: '80',
    },
    '& .wrapperTextBenefit': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '80px',
    },
    '& .letters.ampersand': {
      animationDelay: '4s',
      animationDuration: '1s',
      animationName: 'logoAmpersant',
      animationFillMode: 'forwards',
    },
    '& .letters.letters-right': {
      animationDelay: '2s',
      animationDuration: '1s',
      animationName: 'logoSt',
      animationFillMode: 'forwards',
    },

    // media for our team

    '@media screen and (max-width: 1933px)': {
      '& .space__section--0 .space__slide:nth-child(3)': {
        scale: '0.8',
      },
      '& .image__content.image__content--reverse': {
        scale: '0.9',
      },
    },

    '@media screen and (max-width: 1600px)': {
      '& .big-text': {
        fontSize: '6vh',
        padding: '0 26px',
      },
    },

    '@media screen and (max-width: 1528px)': {
      '.& image__content.image__content--reverse': {
        scale: '0.8',
      },
      '& .space__section--0 .space__slide:nth-child(3)': {
        scale: '0.7',
      },
    },

    ' @media screen and (min-width: 1464px)': {
      '& .noise-vector-2': {
        width: '40%',
        top: '-30%',
        right: '-10%',
      },
      '& .image__content .small-half': {
        minWidth: '30.5vw',
      },
    },

    ' @media screen and (max-width: 1300px)': {
      '& .image__content.image__content--reverse': {
        scale: '0.7',
      },
    },

    '@media screen and (max-width: 1200px)': {
      '& .big-text': {
        fontSize: '5vh',
        padding: '0 26px',
      },
      '& .image__content .small-half': {
        minWidth: '33vw',
      },
    },

    '@media screen and (max-width: 1140px)': {
      '& .image__content.image__content--reverse': {
        scale: '0.6',
      },
      '& .image__content .small-half': {
        minWidth: '37vw',
      },
    },
    '@media screen and (max-width: 1053px)': {
      '& .swiper': {
        width: '700px',
      },
    },
    '@media screen and (max-width: 991px)': {
      '& .header': {
        height: '68px',
      },
      '& .big-text': {
        fontSize: '4vh',
        padding: '0 26px',
      },
      '& .header .header__logo': {
        width: '88px',
        height: ' 46px',
      },
      '& .header .header__logo svg': {
        width: '100%',
        height: ' 100%',
      },
      '& .space.space--with-menu': {
        width: '100vw',
      },
      '.big-text.big-text--white': {
        fontSize: '4vh',
      },
      '& .big-text.big-text--black': {
        fontSize: '68px',
        top: 'calc(50% - 15vh)',
      },
      '& .big-text.big-text--animated': {
        fontSize: '40px',
        filter: 'blur(1px)',
        '-webkit-filter': 'blur(1px)',
      },
      '& .big-text span': {
        fontSize: '20px',
        lineHeight: '1',
      },

      '& .image__content': {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },

      '& .image__content.image__content--reverse': {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },

      '& .image__content .small-half': {
        margin: ' 2.5vh 0',
        textAlign: 'center',
      },

      '& .image__content.image__content--reverse .small-half': {
        margin: '2.5vh 0',
        textAlign: 'center',
      },

      '& .image__content .small-half .small-half__title': {
        fontSize: ' 4.5vh',
      },

      '& .slide-3 .big-image-box': {
        width: '50vw',
        height: '50vw',
      },

      '& .slide-4 .big-image-box': {
        width: '50vw',
        height: '50vw',
      },

      '& .finale-slide': {
        // padding: '78px 0 0',
        // overflow: 'auto',
      },

      '& .finale-slide .finale-slide__title': {
        // fontSize: '42px',
      },

      '& .finale-slide .finale-slide__benefits': {
        flexWrap: 'wrap',
        padding: '0 70px 16px',
      },

      '& .finale-slide .finale-slide__benefits .benefit': {
        flex: 'unset',
      },

      '& .finale-slide.finale-slide__hide-state .finale-slide__benefits .benefit': {
        '& :nth-child(1)': {
          transform: 'translate(calc(50% - 15px), -5vh)',
        },
      },

      '& .big-text .big-text__point': {
        '& ::after': {
          width: '12px',
          height: '12px',
          bottom: '-12px',
        },
      },

      '& .big-text__vector': {
        '& :nth-child(1)': {
          height: ' 290px',
          top: '-40px',
          left: '-20%',
        },
        '& :nth-child(2)': {
          height: '350px',
          top: '-40px',
          right: '2.5%',
        },
      },

      '& .vector-pin--big': {
        width: '1.5vh',
        height: '1.5vh',
      },

      '& .slide-3 .image-box': {
        '&:nth-child(1)': {
          width: '13vw!important',
          height: '13vw!important',
        },
        '&:nth-child(2)': {
          width: '18vw!important',
          height: '18vw!important',
        },
      },
    },

    '@media screen and (max-width: 900px)': {
      '& .header.show-nav .header__nav': {
        right: '100%',
      },
      '& .header .header__nav': {
        width: '100%',
      },
    },

    '@media screen and (max-width: 795px)': {
      '&.big-text': {
        fontSize: '3vh',
        padding: '0 26px',
      },
      '& .swiper': {
        width: '436px',
      },
      '& .finale-slide__title': {
        fontSize: '41px',
      },
      '& .btn.btn-outline': {
        marginRight: 'auto',
      },
      '& .benefit__icon img': {
        width: '220px',
        height: '220px',
      },
    },

    '@media screen and (max-width: 624px)': {
      '& .big-text': {
        fontSize: '4vh',
        padding: '0 26px',
      },
      '& .header .header__nav': {
        width: '100%',
        height: ' 85vh',
        top: '-20px',
        left: '50%',
        right: 'unset',
        transform: 'translate(-50%, -100%)',
        borderRadius: '0 0 20px 20px',
        zIndex: '-1',
        backgroundColor: PALITRA.white,
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.24)',
      },

      '& .header .header__nav ul': {
        gap: '24px',
        padding: '3vh 32px 22px',
      },

      '& .header .header__nav ul li': {
        fontSize: '15px',
        fontWeight: '400',
      },

      '& .header.show-nav .header__nav': {
        right: 'unset',
        transform: 'translate(-50%, 0)',
      },

      '& .header .header__nav .copyright p': {
        fontSize: '12px',
      },

      '& .noise-vector-1': {
        width: '70%',
        top: '-120%',
        left: '-35%',
      },
      '& .sub-text': {
        display: 'none',
      },
      '& .noise-vector-2 .vector-pin--big': {
        width: '1.3vh',
        height: '1.3vh',
        top: '23%',
        left: '95.5%',
      },
    },
    '@media screen and (max-width: 479px)': {
      '& .swiper': {
        width: '345px',
      },
      '& .finale-slide__title': {
        fontSize: '31px',
      },
      '& .benefit__icon img': {
        width: '200px',
        height: '200px',
      },
    },
  },
});

export default useStyle;
