import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    cursor: 'pointer',
    display: 'grid',

    borderBottom: `1px solid ${PALITRA.grey.lightGrey2}`,
    '&:last-child': {
      borderBottom: '0px',
    },

    '& .blog-image': {
      position: 'relative',
      padding: '0px 0px 85% 0px',

      '& .blog-img': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '90%',
        height: '90%',
        objectFit: 'cover',
      },
    },

    '& .blog-content': {
      textAlign: 'left',
      paddingRight: '50px',
      '& .blog-title': {
        fontSize: '22px',
        lineHeight: '1.35',
        fontWeight: '600',
        color: PALITRA.black,

        '@media screen and (max-width: 645px)': {
          fontSize: '20px',
        },
      },

      '& .blog-desc': {
        fontSize: '12px',
        marginBottom: '40px',

        '@media screen and (max-width: 901px)': {
          marginBottom: '10px',
        },
      },

      '& .blog-date': {
        letterSpacing: '1.5px',
        fontSize: '10px',
        color: 'rgba(0,0,0,.5)',
        fontWeight: '600',
        marginBottom: '12px',
      },
    },

    '@media screen and (min-width: 576px)': {
      gridTemplateColumns: '1fr 1fr',
      columnGap: '0px',
    },

    '@media screen and (max-width: 576px)': {
      '& .blog-image': {
        '& .blog-img': {
          top: '50% !important',
          left: '50% !important',
          transform: 'translate(-50%, -50%)',
          width: '100% !important',
        },
      },
    },

    '@media screen and (min-width: 726px)': {
      gridTemplateColumns: '1fr 2fr',
      columnGap: '15px',
    },

    '@media screen and (min-width: 900px)': {
      gridTemplateColumns: '1fr 3fr',
      columnGap: '20px',
    },
  },
});

export default useStyles;
