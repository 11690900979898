import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    width: '60%',

    '@media screen and (max-width: 900px)': {
      width: '100%',
    },

    '& h2': {
      fontWeight: '100',
      fontSize: '25px',
    },

    '& .lesson__block': {
      marginBottom: '25px',

      '& .tasks__wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',

        '& div': {
          fontSize: '20px',
          margin: '10px',
          width: '60px',
          height: '55px',
          textAlign: 'center',
          borderRadius: '12px',
          backgroundColor: 'rgb(231, 93, 93)',

          '& a': {
            color: PALITRA.white,
            display: 'block',
            width: '100%',
            padding: '12px 0',
          },
          '&.saved': {
            backgroundColor: 'rgb(115, 197, 123)',
          },
        },
      },
    },
  },
});

export default useStyles;
