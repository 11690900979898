import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface SelectProps {
  data: string[];
}

const Select: FC<SelectProps> = ({ data }) => {
  return (
    <select>
      {data.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

const TaskReady: FC = () => {
  const [values, setValues] = useState({
    options: ['Давид', 'Саша', 'Бодя'],
    newOption: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, newOption: event.target.value });

  const handleClick = () => {
    values.options.push(values.newOption);
    setValues({ ...values, newOption: '' });
  };

  return (
    <div>
      <Select data={values.options} />
      <input value={values.newOption} onChange={handleChange} />
      <button onClick={handleClick}>Добавить</button>
    </div>
  );
};

const Task11: React.FC = () => {
  const taskTitle: string =
    '11.  Дан инпут, кнопка и селект. Сделай следующее: в инпут вводится какая-то строка и по нажатию на кнопку эта строка должна добавится в конец селекта в виде нового option.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        options: ['Давид', 'Саша', 'Бодя'],
        newOption: '',
      };
    }

    handleClick() {
      this.state.options.push(this.state.newOption);

      this.setState({ ...this.state, newOption: '' });
    }

    render() {
      return (
        <div>
          <Select data={this.state.options} />
          <input
            value={this.state.newOption}
            onChange={(event) =>
              this.setState({ ...this.state, newOption: event.target.value })
            }
          />
          <button onClick={() => this.handleClick()}>Добавить</button>
        </div>
      );
    }
  }

  class Select extends React.Component {
    render() {
      return (
        <select>
          {this.props.data.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Select = ({ data }) => {
    return (
      <select>
        {data.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    );
  };

  const TaskReady = () => {
    const [values, setValues] = useState({
      options: ['Давид', 'Саша', 'Бодя'],
      newOption: '',
    });

    const handleChange = (event) =>
      setValues({ ...values, newOption: event.target.value });

    const handleClick = () => {
      values.options.push(values.newOption);
      setValues({ ...values, newOption: '' });
    };

    return (
      <div>
        <Select data={values.options} />
        <input value={values.newOption} onChange={handleChange} />
        <button onClick={handleClick}>Добавить</button>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task11;
