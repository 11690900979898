import React from 'react';

import { Carousel } from 'antd';

import useStyle from './style';

const SliderSlick = () => {
  const classes = useStyle();

  const SLIDER_CONTENT = [
    'Learn React with UniCode! Three',
    'Learn React with UniCode! Two',
    'Learn React with UniCode! One',
    'Learn React with UniCode! Go',
  ];

  return (
    <div className={classes.root}>
      <Carousel autoplay effect="fade" autoplaySpeed={5000}>
        {SLIDER_CONTENT.map((content) => {
          return (
            <div className="contentStyle">
              <h1>{content}</h1>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default SliderSlick;
