import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyle = createUseStyles({
  root: {
    overflow: 'hidden',

    '& .ant-modal-content': {
      margin: '0 5%',
      opacity: 0.97,
      padding: '45px 50px',
      borderRadius: '50px',
      background: `linear-gradient(90deg, ${PALITRA.red.redPink}, ${PALITRA.yellow})`,
    },

    '& .ant-modal-close-x span': {
      marginTop: '0px',
      scale: 1.5,
      position: 'absolute',
      left: '15%',
      top: '50%',
    },

    '& .ant-modal-body': {
      padding: '20px 25px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      borderRadius: '35px',
      margin: '0 auto',
      backgroundColor: PALITRA.white,
      maxHeight: 'calc(87vh - 100px)',

      '& .modal-body': {
        paddingRight: '15px',
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
          width: '9px',
        },

        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: PALITRA.grey.lightGrey1,
          borderRadius: '100px',
        },
      },
    },

    '@media screen and (max-width: 991px)': {
      '& .ant-modal-content': {
        padding: '30px 30px',
      },
      '& .ant-modal-body': {
        maxHeight: 'calc(88vh - 40px)',
        padding: '20px 15px',
      },
      '& .ant-modal-close-x span': {
        scale: 1,
        marginTop: '20px',
        position: 'static',
      },
    },
  },
});

export default useStyle;
