import React, { FC, useEffect } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { errorTitle, success } from '../../utils/constants';
import { storageService } from '../../services/StorageService';
import { Loader } from '../Loader';
import { useLoginUserMutation } from '../../redux/rtkApi';

import useStyles from './style';

type LoginValues = {
  email: string;
  pass: string;
  remember: boolean;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { sm: { span: 16 }, xs: { span: 24 } },
};

const tailLayout = {
  wrapperCol: { sm: { offset: 8, span: 16 }, xs: { offset: 0, span: 24 } },
};

const Login: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loginUser, { data, error, isLoading }] = useLoginUserMutation();

  useEffect(() => {
    if (!data) {
      return;
    }
    storageService.set('user-id', (data as any)._id);
    storageService.set('access_token', (data as any).token.accessToken);
    history.push('/');
    notification.success({
      message: success,
      duration: 1.5,
    });
  }, [data, history]);

  useEffect(() => {
    if (!error) {
      return;
    }
    notification.error({
      message: errorTitle,
      description: (error as any).data.msg,
      duration: 2.5,
    });
  }, [error]);

  const onFinish = async (values: LoginValues) => {
    await loginUser({ ...values });
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loader />
      ) : (
        <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your email!',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="pass"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}>
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>

          <div className="no-account__wrapper">
            <div className="no-account__block">
              <span>Don't have an account yet?</span>
              <span className="register-link" onClick={() => history.push('/sign-up')}>
                Register now
              </span>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Login;
