import React from 'react';
import classNames from 'classnames';

import LessonsList from './LessonList/LessonsList';

import { storageService } from '../../services/StorageService';
import { useGetUserInfoQuery } from '../../redux';

import useStyles from './style';
import { Loader } from '../Loader';

const Home: React.FC = () => {
  const classes = useStyles();
  const userID = storageService.get<string>('user-id');

  const { data } = useGetUserInfoQuery(userID);

  return (
    <div className={classNames(classes.root, 'site-layout-content')}>
      {data ? (
        <>
          <div className="userInfo__block">
            <img src="/assets/icons/user-avatar-filled-big.svg" alt="user avatar" />
            <h3 className="name__heading">{`${data.firstName} ${data.lastName}`}</h3>
            <div className="email__wrapper">
              <img src="/assets/icons/email.svg" width="20px" height="20px" alt="email icon" />
              <h3>{data.email}</h3>
            </div>
            <div className="github__wrapper">
              <img src="/assets/icons/github.svg" width="30px" height="30px" alt="github icon" />
              <h3>
                <a href={data.repositoriesUrl} target="_blank" rel="noopener noreferrer">
                  Link to repository
                </a>
              </h3>
            </div>
          </div>
          <LessonsList lessons={data.lessons} />
        </>
      ) : (
        <div className="spin__wrapper">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Home;
