import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [paragraphs, setParagraphs] = useState([
    { value: 'The first paragraph', checked: true },
    { value: 'The second paragraph', checked: true },
    { value: 'The third paragraph', checked: true },
  ]);

  const handleCheckboxChange = (index: number) => {
    const copyParagraphs = [...paragraphs];
    copyParagraphs[index].checked = !copyParagraphs[index].checked;
    setParagraphs(copyParagraphs);
  };

  return (
    <div>
      {paragraphs.map((item, index) => (
        <div key={index}>
          <input
            type="checkbox"
            checked={item.checked}
            onChange={() => handleCheckboxChange(index)}
          />
          <p>{item.checked ? item.value : null}</p>
        </div>
      ))}
    </div>
  );
};

const Task3: FC = () => {
  const taskTitle: string =
    '3. Дан массив. Выведи каждый элемент этого массива в отдельном абзаце. Сделай так, чтобы над каждым абзацем был чекбокс, со следующим поведением: если он отмечен - абзац под ним есть на экране, а если не отмечен - абзаца нет. По умолчанию пусть все чекбоксы отмечены.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        paragraphs: [
          { value: 'The first paragraph', checked: true },
          { value: 'The second paragraph', checked: true },
          { value: 'The third paragraph', checked: true },
        ],
      };
    }

    handleCheckboxChange(index) {
      const copyState = { ...this.state };
      copyState.paragraphs[index].checked = !copyState.paragraphs[index].checked;

      this.setState(copyState);
    }

    render() {
      return (
        <div>
          {this.state.paragraphs.map((item, index) => (
            <div key={index}>
              <input
                type='checkbox'
                checked={item.checked}
                onChange={() => this.handleCheckboxChange(index)}
              />
              <p>{item.checked ? item.value : null}</p>
            </div>
          ))}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [paragraphs, setParagraphs] = useState([
      { value: 'The first paragraph', checked: true },
      { value: 'The second paragraph', checked: true },
      { value: 'The third paragraph', checked: true },
    ]);

    const handleCheckboxChange = (index: number) => {
      const copyParagraphs = [...paragraphs];
      copyParagraphs[index].checked = !copyParagraphs[index].checked;
      setParagraphs(copyParagraphs);
    };

    return (
      <div>
        {paragraphs.map((item, index) => (
          <div key={index}>
            <input
              type="checkbox"
              checked={item.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <p>{item.checked ? item.value : null}</p>
          </div>
        ))}
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task3;
