import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface ListProps {
  data: {
    name: string;
    age: number;
    showing: boolean;
  }[];
  onClick: (index: number) => void;
  onBlurFunc: (event: React.FocusEvent<HTMLInputElement>, index: number) => void;
}

const Table: FC<ListProps> = ({ data, onClick, onBlurFunc }) => (
  <table>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.name}</td>
          <td>
            {item.age}
            <button onClick={() => onClick(index)}>Редактировать</button>
            {item.showing ? (
              <input
                type="number"
                name="age"
                initial-value={item.age}
                onBlur={(event) => onBlurFunc(event, index)}
              />
            ) : null}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [users, setUsers] = useState([
    { name: 'Коля', age: 30, showing: false },
    { name: 'Вася', age: 40, showing: false },
    { name: 'Петя', age: 50, showing: false },
  ]);

  const handleClick = (index: number) => {
    const copyUsers = [...users];
    copyUsers[index].showing = true;
    setUsers(copyUsers);
  };

  const handleBlur = (
    { target: { value, name } }: React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    const copyUsers = [...users];

    if (value !== '') {
      copyUsers[index][name] = value;
    }
    copyUsers[index].showing = false;

    setUsers(copyUsers);
  };

  return <Table data={users} onClick={handleClick} onBlurFunc={handleBlur} />;
};

const Task6: FC = () => {
  const taskTitle: string = '6. Дан следующий стейт:';
  const taskCode: string = `
  const [state, setState] = useState({
    users: [
      {name: 'Коля', age: 30},
      {name: 'Вася', age: 40},
      {name: 'Петя', age: 50},
    ]
  });
  `;
  const taskTitle2: string = 'Выведи его на экран в виде таблицы:';
  const taskCode2: string = `
  <table>
    <tr>
      <td>Коля</td><td>30</td>
    </tr>
    <tr>
      <td>Вася</td><td>40</td>
    </tr>
    <tr>
      <td>Петя</td><td>50</td>
    </tr>
  </table>
  `;
  const taskTitle3: string =
    "Сделай так, чтобы внутри каждой td была кнопка 'редактировать', по нажатию на которую текст этой td можно будет отредактировать с помощью появившегося в ней инпута.";
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        users: [
          { name: 'Коля', age: 30, showing: false },
          { name: 'Вася', age: 40, showing: false },
          { name: 'Петя', age: 50, showing: false },
        ],
      };
    }

    handleClick(index) {
      const copyState = { ...this.state };
      copyState.users[index].showing = true;

      this.setState(copyState);
    }

    handleBlur({ target: { value, name } }, index) {
      const copyState = { ...this.state };
      if (value !== '') {
        copyState.users[index][name] = value;
      }
      copyState.users[index].showing = false;

      this.setState(copyState);
    }

    render() {
      return (
        <Table
          data={this.state.users}
          onClick={this.handleClick.bind(this)}
          onBlurFunc={this.handleBlur.bind(this)}
        />
      );
    }
  }

  class Table extends React.Component {
    render() {
      return (
        <table>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>
                  {item.age}
                  <button onClick={() => this.props.onClick(index)}>
                    Редактировать
                  </button>
                  {item.showing ? (
                    <input
                      type='number'
                      name='age'
                      initial-value={item.age}
                      onBlur={(event) => this.props.onBlurFunc(event, index)}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Table = ({ data, onClick, onBlurFunc }) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>
              {item.age}
              <button onClick={() => onClick(index)}>Редактировать</button>
              {item.showing ? (
                <input
                  type="number"
                  name="age"
                  initial-value={item.age}
                  onBlur={(event) => onBlurFunc(event, index)}
                />
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const App = () => {
    const [users, setUsers] = useState([
      { name: 'Коля', age: 30, showing: false },
      { name: 'Вася', age: 40, showing: false },
      { name: 'Петя', age: 50, showing: false },
    ]);

    const handleClick = (index) => {
      const copyUsers = [...users];
      copyUsers[index].showing = true;
      setUsers(copyUsers);
    };

    const handleBlur = ({ target: { value, name } }, index: number) => {
      const copyUsers = [...users];
      if (value !== '') {
        copyUsers[index][name] = value;
      }
      copyUsers[index].showing = false;
      setUsers(copyUsers);
    };

    return <Table data={users} onClick={handleClick} onBlurFunc={handleBlur} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskTitle2={taskTitle2}
        taskTitle3={taskTitle3}
        taskCode={taskCode}
        taskCode2={taskCode2}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task6;
