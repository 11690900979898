import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface TableProps {
  data: {
    name: string;
    price: number;
    quantity: number;
  }[];
  deleteItem: (id: number) => void;
}

const Table: FC<TableProps> = ({ data, deleteItem }) => (
  <table width="40%">
    <thead>
      <tr>
        <th>Название</th>
        <th>Цена</th>
        <th>Количество</th>
        <th>Сумма</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.price}</td>
          <td>{item.quantity}</td>
          <td>{item.quantity * item.price}</td>
          <td>
            <button onClick={() => deleteItem(index)}>Удалить продукт</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const TaskReady: FC = () => {
  const [products, setProducts] = useState([
    { name: 'lemon', price: 20, quantity: 3 },
    { name: 'apple', price: 10, quantity: 10 },
    { name: 'pamelo', price: 50, quantity: 1 },
  ]);

  const deleteProduct = (id: number) => {
    const copyProducts = [...products];
    copyProducts.splice(id, 1);
    setProducts(copyProducts);
  };

  return <Table data={products} deleteItem={deleteProduct} />;
};

const Task7: FC = () => {
  const taskTitle: string =
    '7. Пусть в стейте хранится массив с продуктами. У каждого продукта есть название, цена и количество. Выведи все продукты на экран в виде таблицы. Сделай так, чтобы в таблице была колонка, в которой выводится полная стоимость продукта (цена умножить на количество).';
  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} TaskReady={TaskReady} />
    </div>
  );
};

export default Task7;
