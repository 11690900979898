import React from 'react';
import Container from '../Container';
import LinkedIn from '../Icons/LinkedIn';

import useStyles from './style';

const Contact = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <div className="contact-wrap">
        <div className="contact-text">FEEL FREE TO CONTACT US</div>
        <h2 className="title">contact@unicode.io</h2>
        <div className="contact-subtitle">Looking forward to connecting with you</div>
        <div className="contact-icon">
          <LinkedIn className="contact-icn" />
        </div>
      </div>
    </Container>
  );
};

export default Contact;
