/* eslint-disable no-useless-escape */
import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface State {
  value: string;
  fullName: Array<string>;
}

const TaskReady = () => {
  const [state, setState] = useState<State>({ value: '', fullName: [] });

  const handleEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputCopy = event.target.value.match(/^[a-zа-яё][a-zа-яё\s]*$/i);

    const filteredString = inputCopy ? inputCopy.toString().replace(/ {2,}/g, ' ') : '';
    setState({ value: filteredString, fullName: filteredString.split(' ', 3) });
  };

  return (
    <div>
      <input value={state.value} onChange={handleEvent} />
      {state.value && <p>{state.fullName[0]}</p>}
      {state.value && <p>{state.fullName[1]}</p>}
      {state.value && <p>{state.fullName[2]}</p>}
    </div>
  );
};

const Task4: FC = () => {
  const taskTitle: string =
    '4.Дан инпут и 3 абзаца. В инпут вводится ФИО пользователя через пробел. Сделай так, ' +
    'чтобы при наборе текста в первом абзаце появилась фамилия пользователя, во втором - имя, а в третьем - отчество.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { value: '', fullName: [] };

    handleEvent(event) {
      const inputCopy = event.target.value.match(/^[a-zа-яё][a-zа-яё\s]*$/i);

      const filteredString = inputCopy ? inputCopy.toString().replace(/ {2,}/g, ' ') : '';
      this.setState({ value: filteredString, fullName: filteredString.split(' ', 3) });
    }

    render() {
      return (
        <div>
          <input value={this.state.value} onChange={this.handleEvent.bind(this)} />
          {this.state.value && <p>{this.state.fullName[0]}</p>}
          {this.state.value && <p>{this.state.fullName[1]}</p>}
          {this.state.value && <p>{this.state.fullName[2]}</p>}
        </div>
      );
    }
  }
  `;

  const taskFuncSolution: string = `
  const App = () => {
    const [state, setState] = useState({ value: '', fullName: [] });

    const handleEvent = (event) => {
      const inputCopy = event.target.value.match(/^[a-zа-яё][a-zа-яё\\s]*$/i);

      const filteredString = inputCopy ? inputCopy.toString().replace(/ {2,}/g, ' ') : '';
      setState({ value: filteredString, fullName: filteredString.split(' ', 3) });
    };

    return (
      <div>
        <input value={state.value} onChange={handleEvent} />
        {state.value && <p>{state.fullName[0]}</p>}
        {state.value && <p>{state.fullName[1]}</p>}
        {state.value && <p>{state.fullName[2]}</p>}
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task4;
