import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../../utils/constants';

const useStyles = createUseStyles({
  root: {
    '& .vacancy-card__text': {
      '-webkit-line-clamp': '3',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    '& .ant-card': {
      position: 'relative',
      width: '100%',
      maxWidth: '300px',
      minWidth: '230px',
      margin: '0 10px',
      marginBottom: '15px',
      border: `1px solid ${PALITRA.black}`,
      borderRadius: '30px',
      cursor: 'pointer',
      transition: 'all 500ms ease-in-out',
      '&:hover': {
        border: `1px solid ${PALITRA.red.redPink}`,
        webkitTransform: 'scale(1.05)',
        msTransform: 'scale(1.05)',
        webkitTransition: 'all 500ms ease',
        mozTransition: 'all 500ms ease',
        msTransition: 'all 500ms ease',
        oTransition: 'all 00ms ease',
        transition: 'all 500ms ease',
      },
    },
    '& .ant-card-body': {
      height: '130px',
    },
    '@media screen and (max-width: 930px)': {
      '& .ant-card': {
        width: '85%',
        maxWidth: 'none',
        minWidth: '100%',
        margin: '10px auto',
      },
      '& .ant-card-body': {
        height: '100px',
        width: '100%',
      },
    },
    '@media screen and (max-width: 500px)': {
      '& .ant-card': {
        width: '95%',
      },
      '& .ant-card-head-title': {
        fontSize: '11px',
        fontWeight: '600',
      },
      '& .ant-card-body': {
        height: '145px',
      },
      '& .vacancy-card__text': {
        fontSize: '13px',
        '-webkit-line-clamp': '5',
      },
    },
  },
});

export default useStyles;
