import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { tasksQuantity1 } from '../Lesson1/';
import { tasksQuantity2 } from '../Lesson2';
import { tasksQuantity3 } from '../Lesson3';
import { tasksQuantity4 } from '../Lesson4';
import { tasksQuantity5 } from '../Lesson5';
import { tasksQuantity6 } from '../Lesson6';
import { tasksQuantity7 } from '../Lesson7';
import { tasksQuantity8 } from '../Lesson8';
import { tasksQuantity9 } from '../Lesson9';
import { tasksQuantity10 } from '../Lesson10';
import lessonsQuantity from '../../helpers/lessonsQuantity';
import { appState } from '../../Context/secondClass';

import useStyles from './style';

interface TaskNavigationProps {
  className?: string;
}

const TaskNavigation: FC<TaskNavigationProps> = ({ className }) => {
  const classes = useStyles();
  const history = useHistory();
  const [taskLes, seTaskLes] = useState({ lesson: 1, task: 1 });

  useEffect(() => {
    const { lesson, task } = appState.currentTask;

    seTaskLes({ lesson: lesson, task: task });
    // eslint-disable-next-line
  }, [appState.currentTask]);

  let currentTasksQuantity = tasksQuantity1;
  switch (Number(taskLes.lesson)) {
    case 1:
      currentTasksQuantity = tasksQuantity1;
      break;
    case 2:
      currentTasksQuantity = tasksQuantity2;
      break;
    case 3:
      currentTasksQuantity = tasksQuantity3;
      break;
    case 4:
      currentTasksQuantity = tasksQuantity4;
      break;
    case 5:
      currentTasksQuantity = tasksQuantity5;
      break;
    case 6:
      currentTasksQuantity = tasksQuantity6;
      break;
    case 7:
      currentTasksQuantity = tasksQuantity7;
      break;
    case 8:
      currentTasksQuantity = tasksQuantity8;
      break;
    case 9:
      currentTasksQuantity = tasksQuantity9;
      break;
    case 10:
      currentTasksQuantity = tasksQuantity10;
      break;
    default:
      currentTasksQuantity = tasksQuantity1;
      break;
  }

  const prevClickTask = (): void => {
    let nextLesson, nextTask;
    if (taskLes.task > 1) {
      nextTask = Number(taskLes.task) - 1;
      nextLesson = taskLes.lesson;
      history.push(`/lesson/${nextLesson}/task/${nextTask}`);
    }
    if (Number(taskLes.task) === 1) {
      nextLesson = Number(taskLes.lesson) - 1;
      switch (Number(nextLesson)) {
        case 1:
          currentTasksQuantity = tasksQuantity1;
          break;
        case 2:
          currentTasksQuantity = tasksQuantity2;
          break;
        case 3:
          currentTasksQuantity = tasksQuantity3;
          break;
        case 4:
          currentTasksQuantity = tasksQuantity4;
          break;
        case 5:
          currentTasksQuantity = tasksQuantity5;
          break;
        case 6:
          currentTasksQuantity = tasksQuantity6;
          break;
        case 7:
          currentTasksQuantity = tasksQuantity7;
          break;
        case 8:
          currentTasksQuantity = tasksQuantity8;
          break;
        case 9:
          currentTasksQuantity = tasksQuantity9;
          break;
        case 10:
          currentTasksQuantity = tasksQuantity10;
          break;
        default:
          currentTasksQuantity = tasksQuantity1;
          break;
      }
      nextTask = currentTasksQuantity;
      history.push(`/lesson/${nextLesson}/task/${nextTask}`);
    }

    appState.currentTask = { lesson: Number(nextLesson), task: Number(nextTask) };
  };

  const nextClickTask = (): void => {
    let nextLesson, nextTask;

    if (taskLes.task < currentTasksQuantity) {
      nextTask = taskLes.task + 1;
      nextLesson = taskLes.lesson;
      history.push(`/lesson/${nextLesson}/task/${nextTask}`);
    }

    if (Number(taskLes.task) === currentTasksQuantity) {
      nextTask = 1;
      nextLesson = String(Number(taskLes.lesson) + 1);
      history.push(`/lesson/${nextLesson}/task/${nextTask}`);
    }

    appState.currentTask = {
      lesson: Number(nextLesson),
      task: Number(nextTask),
    };
  };

  const firstTask = () => {
    return !(taskLes.lesson === 1 && taskLes.task === 1);
  };

  const lastTask = () => {
    return !(taskLes.lesson === lessonsQuantity && taskLes.task === currentTasksQuantity);
  };

  return (
    <div className={classNames(classes.root, className, 'task-navigation-container')}>
      {firstTask() && (
        <Button
          className="prev__button"
          onClick={prevClickTask}
          size="small"
          shape="circle"
          icon={<LeftOutlined />}
        />
      )}
      {lastTask() && (
        <Button
          className="next__button"
          onClick={nextClickTask}
          size="small"
          shape="circle"
          icon={<RightOutlined />}
        />
      )}
    </div>
  );
};

export default TaskNavigation;
