// @ts-nocheck
import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import Highlight, { defaultProps } from 'prism-react-renderer';
import vsDark from 'prism-react-renderer/themes/vsDark';

import { storageService } from '../../services/StorageService';

import TaskNavigation from '../TaskNavigation';
import ModalWindow from '../ModalWindow';
import { appState } from '../../Context/secondClass';
import useStyles from './style';

const { TabPane } = Tabs;

interface ComponentTemplateProps {
  taskTitle: string;
  TaskReady?: React.FC;
  taskClassSolution?: string;
  taskCode?: string;
  taskCode2?: string;
  taskCode3?: string;
  taskFuncSolution?: string;
  taskTitle2?: string;
  taskTitle3?: string;
}

const ComponentTemplate: React.FC<ComponentTemplateProps> = ({
  taskTitle,
  taskCode,
  taskClassSolution,
  taskFuncSolution,
  TaskReady,
  taskTitle2,
  taskCode2,
  taskTitle3,
  taskCode3,
}) => {
  const classes = useStyles();

  const [shows, setShows] = useState({
    popup: false,
    solutionVerified: storageService.get('showPIN') === process.env.REACT_APP_SHOW_CODE,
    solution: false,
  });

  return (
    <div className={classes.root + ' task-section'}>
      <h3>{taskTitle}</h3>
      {taskCode && (
        // @ts-ignore
        <Highlight {...defaultProps} theme={vsDark} code={taskCode} language="jsx">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      )}
      {taskTitle2 && <h3>{taskTitle2}</h3>}
      {taskCode2 && (
        // @ts-ignore
        <Highlight {...defaultProps} theme={vsDark} code={taskCode2} language="jsx">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      )}
      {taskTitle3 && <h3>{taskTitle3}</h3>}
      {taskCode3 && (
        // @ts-ignore
        <Highlight {...defaultProps} theme={vsDark} code={taskCode3} language="jsx">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      )}
      {taskFuncSolution && taskClassSolution && (
        <div>
          <Button
            type="primary"
            onClick={() =>
              setShows({
                ...shows,
                solution: shows.solutionVerified && !shows.solution,
                popup: !shows.solutionVerified,
              })
            }>
            Show solution
          </Button>

          {shows.solution && (
            <Tabs defaultActiveKey="func" type="card">
              <TabPane tab="Function" key="func">
                {/* @ts-ignore */}
                <Highlight {...defaultProps} theme={vsDark} code={taskFuncSolution} language="jsx">
                  {({ className, style, tokens, getLineProps, getTokenProps }: any) => (
                    <pre className={className} style={style}>
                      {tokens.map((line: any, i: any) => (
                        <div {...getLineProps({ line, key: i })}>
                          {line.map((token: any, key: any) => (
                            <span {...getTokenProps({ token, key })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </TabPane>

              <TabPane tab="Class" key="class">
                {/* @ts-ignore */}
                <Highlight {...defaultProps} theme={vsDark} code={taskClassSolution} language="jsx">
                  {({ className, style, tokens, getLineProps, getTokenProps }: any) => (
                    <pre className={className} style={style}>
                      {tokens.map((line: any, i: any) => (
                        <div {...getLineProps({ line, key: i })}>
                          {line.map((token: any, key: any) => (
                            <span {...getTokenProps({ token, key })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </TabPane>
            </Tabs>
          )}
        </div>
      )}
      {TaskReady && (
        <div>
          <h3>Результат работы программы:</h3>
          <TaskReady />
        </div>
      )}
      {!appState.screenSizeDesktop && <TaskNavigation />}
      <ModalWindow state={shows} setState={setShows} />
    </div>
  );
};

export default ComponentTemplate;
