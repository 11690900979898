import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  root: {
    padding: '5px',

    '& .horisontal': {
      display: 'flex',
      flexDirection: 'row',
      gap: '25px',
    },
    '& .horisontal-item': {
      width: '50%',
    },
    '& .ant-typography': {
      textAlign: 'center',
    },
    '& .ant-form-item': {
      marginBottom: '25px',
    },
    '& .ant-form-item:nth-child(3)': {
      marginBottom: '0',
    },
    '& .form-buttons .ant-form-item-control-input-content': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: '20px',
    },

    '@media screen and (max-width: 991px)': {
      '& .horisontal': {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      },
      '& .horisontal-item': {
        width: '100%',
      },
    },
  },
});

export default useStyle;
