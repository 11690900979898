import React, { FC } from 'react';

import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

import useStyle from './style';

interface Props extends ButtonProps {
  animName: string;
  content: string;
  colors: { main: string; secondary: string };
}

const AnimatedButton: FC<Props> = ({ animName, content, colors, ...props }) => {
  const classNames = useStyle({ colors, animName });

  return (
    <div className={classNames.root}>
      <style type="text/css">
        {`@keyframes ${animName} {
          0% {
            background-image: linear-gradient(to right, ${colors.main} 0%, ${colors.secondary}), linear-gradient(to right, ${colors.secondary} 0%, ${colors.main} 100%);
            background-position: -120px, -60px;
            background-size: 60px, 60px;
            background-repeat: no-repeat;
          }
          100% {
            background-image: linear-gradient(to right, ${colors.main} 0%,${colors.secondary}), linear-gradient(to right, ${colors.secondary} 0%, ${colors.main} 100%);
            background-position: 120px, 180px;
            background-size: 60px, 60px;
            background-repeat: no-repeat;
          }
        }`}
      </style>
      <Button {...props}>{content}</Button>
    </div>
  );
};

export default AnimatedButton;
