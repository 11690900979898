import React, { FC } from 'react';

const Adventage5: FC<IconProps> = ({ ...props }) => (
  <svg
    {...props}
    id="Слой_2"
    data-name="Слой 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100">
    <title>Монтажная область 16</title>
    <path d="M50,52A19.52,19.52,0,0,0,30.5,71.5,1.5,1.5,0,0,0,32,73H68a1.5,1.5,0,0,0,1.5-1.5A19.52,19.52,0,0,0,50,52ZM33.57,70a16.5,16.5,0,0,1,32.87,0Z" />
    <path d="M49,47.5A11.5,11.5,0,1,0,37.5,36,11.51,11.51,0,0,0,49,47.5Zm0-20A8.5,8.5,0,1,1,40.5,36,8.51,8.51,0,0,1,49,27.5Z" />
    <path d="M97,48H91.95A42,42,0,0,0,51,8V2.5a1.5,1.5,0,0,0-3,0V8.05A42.05,42.05,0,0,0,8.05,48H3a1.5,1.5,0,0,0,0,3H8A42,42,0,0,0,48,91.95V97.5a1.5,1.5,0,0,0,3,0V92A42,42,0,0,0,92,51h5a1.5,1.5,0,0,0,0-3ZM51,89V84.5a1.5,1.5,0,0,0-3,0v4.45A39,39,0,0,1,11,51h5a1.5,1.5,0,0,0,0-3H11.05A39.06,39.06,0,0,1,48,11.05V15.5a1.5,1.5,0,0,0,3,0V11A39,39,0,0,1,88.95,48H84a1.5,1.5,0,0,0,0,3h5A39,39,0,0,1,51,89Z" />
  </svg>
);

export default Adventage5;
