import React, { FC, useState } from 'react';

import ComponentTemplate from '../../Task';

interface State {
  firstValue: string;
  secondValue: string;
  sum: string;
}

const TaskReady: FC = () => {
  const [values, setValues] = useState<State>({ firstValue: '', secondValue: '', sum: '' });

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: value });
  };

  const handleClick = () => {
    const copyValues = { ...values };
    copyValues.sum = String(Number(copyValues.firstValue) + Number(copyValues.secondValue));
    copyValues.firstValue = '';
    copyValues.secondValue = '';
    setValues(copyValues);
  };

  return (
    <div>
      <input type="number" name="firstValue" value={values.firstValue} onChange={handleChange} />
      <input type="number" name="secondValue" value={values.secondValue} onChange={handleChange} />
      <button onClick={handleClick}>Нажми на меня</button>
      <p>{values.sum}</p>
    </div>
  );
};

const Task6: FC = () => {
  const taskTitle: string =
    '6. Даны два инпута и кнопка. В инпуты вводятся числа. Сделай так, чтобы по нажатию на кнопку под инпутами в какой-нибудь абзац вывелась сумма этих чисел.';
  const taskClassSolution: string = `
  class App extends React.Component {
    state = { firstInputValue: '' , secondInputValue : '', resultValue:''};
    
    handleChangeFirstInput(event: React.ChangeEvent<HTMLInputElement>) {
      this.setState({...this.state, firstInputValue: Number(event.target.value)})
    }
  
    handleChangeSecondInput(event: React.ChangeEvent<HTMLInputElement>) {
      this.setState({...this.state, secondInputValue: Number(event.target.value)})
    }
  
    handleOnClick() {
      this.setState({resultValue: this.state.firstInputValue + this.state.secondInputValue, firstInputValue: '', secondInputValue : '' }) ;
    }
    
    render() {
      return (
        <div>
          <input value={this.state.firstInputValue} type="number" onChange={this.handleChangeFirstInput.bind(this)} />
          <input value={this.state.secondInputValue} type="number" onChange={this.handleChangeSecondInput.bind(this)} />
          <button onClick={this.handleOnClick.bind(this)}>Нажми на меня</button>
          {<p>{this.state.resultValue}</p>}
        </div>
      );
    }
  }
  `;

  const taskFuncSolution: string = `
  const App: React.FC = () => {
    const [values, setValues] = useState<State>({ firstValue: '', secondValue: '', sum: '' });
  
    const handleChange = ({ target: { name, value }}) => {
      setValues({ ...values, [name]: value });
    };
  
    const handleClick = () => {
      const copyValues = { ...values };
      copyValues.sum = String(Number(copyValues.firstValue) + Number(copyValues.secondValue));
      copyValues.firstValue = '';
      copyValues.secondValue = '';
      setValues(copyValues);
    };
  
    return (
      <div>
        <input type="number" name="firstValue" value={values.firstValue} onChange={handleChange} />
        <input type="number" name="secondValue" value={values.secondValue} onChange={handleChange} />
        <button onClick={handleClick}>Нажми на меня</button>
        <p>{values.sum}</p>
      </div>
    );
};
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task6;
