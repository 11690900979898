import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '&.item': {
      '@media screen and (max-width: 900px)': {
        paddingRight: '30px',
        paddingLeft: '30px',
      },
      '@media screen and (max-width: 576px)': {
        paddingRight: '10px',
        paddingLeft: '10px',
      },
      '& .service-img': {
        width: '90px',
        height: '100px',
      },
      '& .service-subtitle': {
        fontSize: '28px',
        fontWeight: '600',
      },
      '& .service-content': {},
    },
  },
});

export default useStyles;
