import classNames from 'classnames';
import React, { FC } from 'react';

import useStyles from './style';

interface ContainerProps {
  title?: string;
  subtitle?: string;
  className: string;
}

const Container: FC<ContainerProps> = ({ children, title, subtitle, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      {title && <h2 className="title">{title}</h2>}
      {subtitle && <p className="subtitle">{subtitle}</p>}
      {children}
    </div>
  );
};

export default Container;
