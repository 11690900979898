import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface TaskReadyProps {
  values: string[] | never;
  newValue: string;
}

const TaskReady: FC = () => {
  const [texts, setText] = useState<TaskReadyProps>({ values: [], newValue: '' });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setText({ ...texts, newValue: event.target.value });

  const handleClick = () => {
    texts.values.push(texts.newValue);
    setText({ ...texts, newValue: '' });
  };

  return (
    <div>
      <textarea value={texts.newValue} onChange={handleChange} />
      <button onClick={handleClick}>Добавить</button>
      {texts.values.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
};

const Task7: FC = () => {
  const taskTitle: string =
    '7. Дан текстареа и кнопка. Дан также стейт texts с массивом текстов. Изначально этот массив пустой, но каждый раз по нажатию на кнопку в конец этого массива должно записаться текущее содержимое текстареа. Выведи содержимое массива texts так, чтобы каждый элемент массива выводился в новом абзаце.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = { values: [], newValue: '' };
    }

    handleChange(value) {
      this.setState({
        ...this.state,
        newValue: value,
      });
    }

    handleClick() {
      this.state.values.push(this.state.newValue);

      this.setState({ values: this.state.values, newValue: '' });
    }

    render() {
      return (
        <div>
          <textarea
            value={this.state.newValue}
            onChange={(event) => this.handleChange(event.target.value)}
          />
          <button onClick={() => this.handleClick()}>Добавить</button>
          {this.state.values.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [texts, setText] = useState({ values: [], newValue: '' });

    const handleChange = (event) => setText({ ...texts, newValue: event.target.value });

    const handleClick = () => {
      texts.values.push(texts.newValue);
      setText({ ...texts, newValue: '' });
    };

    return (
      <div>
        <textarea value={texts.newValue} onChange={handleChange} />
        <button onClick={handleClick}>Добавить</button>
        {texts.values.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task7;
