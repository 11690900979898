import { createUseStyles } from 'react-jss';
import { PALITRA } from '../../utils/constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 176px)',
    padding: '0 20px',

    '& .ant-form.ant-form-horizontal': {
      '& .ant-col.ant-col-8.ant-form-item-label > label': {
        fontSize: '18px',
      },

      '& .no-account__wrapper': {
        marginLeft: '33.33333333%',

        '& .no-account__block': {
          marginBottom: '24px',
          display: 'flex',
          flexWrap: 'wrap',

          '& span': {
            marginRight: '5px',
          },

          '& .register-link': {
            cursor: 'pointer',
            color: PALITRA.violet.violet,
            fontWeight: 'bold',
          },
        },
      },

      '@media screen and (max-width: 575px)': {
        '& .no-account__wrapper': {
          marginLeft: 0,
        },
      },

      '& .ant-form-item-control-input': {
        '& .ant-form-item-control-input-content': {
          display: 'flex',
          justifyContent: 'space-between',
        },

        '& span > input': {
          height: '25px',
        },

        '& input': {
          height: '35px',
        },

        '& label.ant-checkbox-wrapper': {
          fontSize: '18px',
        },

        '& button': {
          width: '100%',
          height: '40px',

          '&:focus': {
            color: PALITRA.white,
            bordercColor: PALITRA.violet.violet,
          },

          '& span': {
            fontSize: '18px',
          },
        },
      },
    },
  },
});

export default useStyles;
