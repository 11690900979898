import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface DirectionsProps {
  data: {
    values: string[];
    currentValue: string;
  };
  setDirectionsState: React.Dispatch<
    React.SetStateAction<{
      values: string[];
      currentValue: string;
    }>
  >;
}

const Directions: FC<DirectionsProps> = ({ data, setDirectionsState }) => {
  return (
    <div>
      {data.values.map((item, index) => (
        <div>
          <input
            key={index}
            type="radio"
            checked={item === data.currentValue}
            onChange={(event) => setDirectionsState({ ...data, currentValue: event.target.value })}
            value={item}
          />
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
};

const TaskReady: FC = () => {
  const [directions, setDirections] = useState({
    values: ['Черкаси - Київ', 'Черкаси - Одеса', 'Черкаси - Харків'],
    currentValue: 'Черкаси - Київ',
  });

  return (
    <div>
      <p>{directions.currentValue}</p>
      <Directions data={directions} setDirectionsState={setDirections} />
    </div>
  );
};

const Task7: FC = () => {
  const taskTitle: string =
    '7. Дан стейт внутри которого массив с туристическими маршрутами. Выведи эти маршруты на экран так, чтобы рядом с каждым стояла радио-кнопка. Пользователь может выбрать один из маршрутов. Его выбор должен отобразиться на экране в абзаце.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        directions: {
          values: ['Черкаси - Київ', 'Черкаси - Одеса', 'Черкаси - Харків'],
          currentValue: 'Черкаси - Київ',
        },
      };
    }

    render() {
      return (
        <div>
          <p>{this.state.directions.currentValue}</p>
          <Directions
            data={this.state.directions}
            setDirectionsState={this.setState.bind(this)}
          />
        </div>
      );
    }
  }

  class Directions extends React.Component {
    render() {
      return (
        <div>
          {this.props.data.values.map((item, index) => (
            <div key={index}>
              <input
                type='radio'
                checked={item === this.props.data.currentValue}
                onChange={(event) =>
                  this.props.setDirectionsState({
                    directions: {
                      ...this.props.data,
                      currentValue: event.target.value,
                    },
                  })
                }
                value={item}
              />
              <span>{item}</span>
            </div>
          ))}
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const Directions = ({ data, setDirectionsState }) => {
    return (
      <div>
        {data.values.map((item, index) => (
          <div key={index}>
            <input
              type="radio"
              checked={item === data.currentValue}
              onChange={(event) => setDirectionsState({ ...data, currentValue: event.target.value })}
              value={item}
            />
            <span>{item}</span>
          </div>
        ))}
      </div>
    );
  };

  const App = () => {
    const [directions, setDirections] = useState({
      values: ['Черкаси - Київ', 'Черкаси - Одеса', 'Черкаси - Харків'],
      currentValue: 'Черкаси - Київ',
    });

    return (
      <div>
        <p>{directions.currentValue}</p>
        <Directions data={directions} setDirectionsState={setDirections} />
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task7;
