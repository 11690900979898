import classNames from 'classnames';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Container from '../../Components/Container';
import { getNameImage } from '../../utils/common';
import Pages from '../../utils/pages';

import useStyles from './style';

interface BlogPage1Props {
  id: number;
  info: DATA_BLOG_ITEM;
  clas: string;
}

const BlogPage1: FC<BlogPage1Props> = ({ id, info, clas }) => {
  const classes = useStyles();

  // const handleHome = () => {
  //   <Redirect to="/" />;
  // };

  return (
    <Container className={classNames(clas, classes.root)}>
      <Helmet>
        <meta name="description" content={info.desc} />
        <title>{info.title}</title>
      </Helmet>
      <div className="blog-wrap">
        <h2 className="blog-title">{info.title}</h2>
        <div className="blog-date">{info.date}</div>
        <div className="blog-text">
          <p className="blog-text-p">
            Headquartered in the beautiful city of Kyiv, Ukraine, UniCode is one of the country’s
            premier software development companies that offers a multitude of{' '}
            <a
              // className="link"
              href="https://medium.com/swlh/top-7-software-development-trends-of-2021-da95bd4755f4">
              customized solutions and technologies
            </a>
            . Since our establishment, we’ve been striving to help companies and startups
            successfully launch their businesses while building trust and long-lasting relationships
            in the process. Our top priority is your success!
          </p>
          <p className="blog-text-p">
            With that in mind, we are thrilled to announce that Clutch have recognized us as one of
            the 250 best-performing software development companies in Ukraine. Located in
            Washington, DC, Clutch is a{' '}
            <a href="https://clutch.co/developers/ukraine">data-driven resource for businesses</a>.
            They evaluate technology service and solutions companies based on the quality of work,
            thought leadership, and client reviews.
          </p>
        </div>
        <div className="blog-image">
          <img src={info.src} alt={getNameImage(id)} className="blog-img" />
        </div>
        <div className="blog-text">
          <p className="blog-text-p">
            The entire UniCode team is beyond proud to receive this recognition from Clutch. We
            would like to extend our appreciation to all of our clients who helped make this
            possible. We couldn’t have done this without you.
          </p>
          <p className="blog-text-p center">
            <q>
              We are more than happy to be nominated by Clutch as among the best Ukrainian software
              development companies.
            </q>
          </p>
          <p className="blog-text-p author">— Slava Hraichenko, CEO, UniCode</p>
          <p className="blog-text-p">
            Since partnering with Clutch, we’ve garnered a perfect overall star rating of 5.0 across
            all reviews. Here’s what one of our clients says about our work:
          </p>
          <p className="blog-text-p center">
            <q>
              I found them to be very professional. At that time, UniCode was a relatively small
              shop, and they were well organized. Slava (CEO & Co-Founder) did a good job of
              communicating clearly, and the team had a lot of structure and process in place.
            </q>
          </p>
          <p className="blog-text-p author">— Michael Faulconer, Former Product Owner, SmartLane</p>
          <p className="blog-text-p">
            Check out the full review along with other client reviews by visiting our{' '}
            <a href="https://clutch.co/profile/implex#summary"> Clutch profile</a>.
          </p>
          <p className="blog-text-p">
            Do you have a development project in mind? <Link to={Pages.HOME}>Contact us today</Link>{' '}
            and let’s get right down to business.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default BlogPage1;
