import React from 'react';
import ComponentTemplate from '../../Task';

const Task9: React.FC = () => {
  const taskTitle: string = '9. Реализуй игру морской бой (игрок против компьютера).';

  return (
    <div>
      <ComponentTemplate taskTitle={taskTitle} />
    </div>
  );
};

export default Task9;
