import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useStyles from './style';

const LessonsPage = () => {
  const classes = useStyles();

  const numberLessons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className={classNames(classes.root)}>
      <ul className="list">
        {numberLessons.map((lesson, index) => {
          return (
            <li key={index} className="list-item">
              <Link to={`/lesson/${lesson}`}>
                <div
                  className="link-layer"
                  style={{
                    backgroundImage: `url(https://picsum.photos/218/218?grayscale&random=${
                      index + 1
                    })`,
                  }}></div>
                <div className="link-text">Lesson {lesson}</div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LessonsPage;
