import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

interface ListProps {
  data: {
    name: string;
    showing: boolean;
  }[];
  onClick: (index: number) => void;
  onChange: (index: number, event: React.FocusEvent<HTMLInputElement>) => void;
}

const List: FC<ListProps> = ({ data, onClick, onChange }) => (
  <ul>
    {data.map((item, index) => (
      <li key={index}>
        <div style={{ display: 'inline' }} onClick={() => onClick(index)}>
          {item.name}
        </div>
        {item.showing ? (
          <input initial-value={item.name} type="text" onBlur={(event) => onChange(index, event)} />
        ) : null}
      </li>
    ))}
  </ul>
);

const TaskReady: FC = () => {
  const [users, setUsers] = useState([
    { name: 'David', showing: false },
    { name: 'Boghdan', showing: false },
    { name: 'Alex', showing: false },
  ]);

  const handleClick = (index: number) => {
    const copyUsers = [...users];
    copyUsers[index].showing = !copyUsers[index].showing;
    setUsers(copyUsers);
  };

  const handleChange = (index: number, event: React.FocusEvent<HTMLInputElement>) => {
    const copyUsers = [...users];

    if (event.target.value !== '') {
      copyUsers[index].name = event.target.value;
    }
    copyUsers[index].showing = false;

    setUsers(copyUsers);
  };

  return <List data={users} onClick={handleClick} onChange={handleChange} />;
};

const Task5: FC = () => {
  const taskTitle: string =
    '5. Дан стейт внутри которого массив. Выведи элементы этого массив в виде списка ul. Сделай так, чтобы по нажатию на li внутри нее появился инпут, с помощью которого можно будет поредактировать текст этой li. По потери фокуса текст из инпута должен записаться обратно в li, а инпут исчезнуть.';
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        users: [
          { name: 'Коля', surname: 'Иванов', age: 30, checked: true },
          { name: 'Вася', surname: 'Петров', age: 40, checked: true },
          { name: 'Петя', surname: 'Сидоров', age: 50, checked: true },
        ],
      };
    }

    handleClick = (index) => {
      const copyState = { ...this.state };
      copyState.users[index].showing = !copyState.users[index].showing;

      this.setState(copyState);
    };

    handleChange = (index, event) => {
      const copyState = { ...this.state };
      if (event.target.value !== '') {
        copyState.users[index].name = event.target.value;
      }
      copyState.users[index].showing = false;

      this.setState(copyState);
    };

    render() {
      return (
        <List
          data={this.state.users}
          onClick={this.handleClick.bind(this)}
          onChange={this.handleChange.bind(this)}
        />
      );
    }
  }

  class List extends React.Component {
    render() {
      return (
        <ul>
          {this.props.data.map((item, index) => (
            <li key={index}>
              <div
                style={{ display: 'inline' }}
                onClick={() => this.props.onClick(index)}
              >
                {item.name}
              </div>
              {item.showing ? (
                <input
                  initial-value={item.name}
                  type='text'
                  onBlur={(event) => this.props.onChange(index, event)}
                />
              ) : null}
            </li>
          ))}
        </ul>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const List= ({ data, onClick, onChange }) => (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <div style={{ display: 'inline' }} onClick={() => onClick(index)}>
            {item.name}
          </div>
          {item.showing ? (
            <input initial-value={item.name} type="text" onBlur={(event) => onChange(index, event)} />
          ) : null}
        </li>
      ))}
    </ul>
  );

  const App = () => {
    const [users, setUsers] = useState([
      { name: 'David', showing: false },
      { name: 'Boghdan', showing: false },
      { name: 'Alex', showing: false },
    ]);

    const handleClick = (index) => {
      const copyUsers = [...users];
      copyUsers[index].showing = !copyUsers[index].showing;
      setUsers(copyUsers);
    };

    const handleChange = (index, event) => {
      const copyUsers = [...users];
      if (event.target.value !== '') {
        copyUsers[index].name = event.target.value;
      }
      copyUsers[index].showing = false;
      setUsers(copyUsers);
    };

    return <List data={users} onClick={handleClick} onChange={handleChange} />;
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task5;
