import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import useStyles from './style';

interface LessonsListProps {
  lessons: LessonsListType[];
}

const LessonsList: FC<LessonsListProps> = ({ lessons }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {lessons.map((lesson, id) => (
        <div className="lesson__block" key={id}>
          <h2>Lesson {lesson.lesson}</h2>
          <div className="tasks__wrapper">
            {lesson.tasks.map((task, id) => (
              <div key={id} className={classNames('task__item', { saved: task[id + 1] })}>
                <Link to={`/lesson/${lesson.lesson}/task/${id + 1}`}>{id + 1}</Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LessonsList;
