import React, { FC } from 'react';

import ComponentTemplate from '../../Task';

const TaskReady = () => {
  const state = ['Коля', 'Вася', 'Петя'];
  const list = state.map((item, index) => <li key={index}>{item}</li>);

  return <ul>{list}</ul>;
};

const Task5: FC = () => {
  const taskTitle: string =
    "5. Дан массив имен ['Коля', 'Вася', 'Петя']. Выведи каждый элемент этого массива в отдельной <li> в списке ul. Вот так:";

  const taskCode: string = `
  <ul>
    <li>Коля</li>
    <li>Вася</li>
    <li>Петя</li>
  </ul>
  `;

  const taskClassSolution: string = `
  class App extends React.Component {
    state = ['Коля', 'Вася', 'Петя'];

    render() {
      const list = this.state.map((item, index) => <li key={index}>{item}</li>);
      return <ul>{list}</ul>;
    }
  }
  `;

  const taskFuncSolution: string = `
  const App = () => {
    const state = ['Коля', 'Вася', 'Петя'];
    const list = state.map((item, index) => <li key={index}>{item}</li>);

    return <ul>{list}</ul>;
  }
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskCode={taskCode}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};
export default Task5;
