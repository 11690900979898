import { createUseStyles } from 'react-jss';

import { PALITRA } from '../../../../utils/constants';

const params = {
  hoverColor: PALITRA.violet.violet,
};

const useStyles = createUseStyles({
  root: {
    'default-menu__dropdown': {},
    '&.default-menu': {
      position: 'relative',
      listStyle: 'none',
      float: 'left',
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'wrap',
      paddingLeft: '0px',
      width: '100%',
      marginBottom: '0',

      '& .active': {
        borderBottom: `3px solid ${PALITRA.crimson}`,
      },

      '& li:not(:nth-child(5))': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100px',
        cursor: 'pointer',
        height: '56px !important',
        opacity: '1',
        transition: 'opacity 0.5s',
        boxSizing: 'border-box',
        marginRight: '20px',

        '&:last-child': {
          marginRight: '0px',
        },

        '& a, span': {
          fontSize: '16px',
          fontWeight: '600',
          userSelect: 'none',
          position: 'relative',
          color: PALITRA.white,
          backgroundImage: `linear-gradient(
              90deg,
              ${params.hoverColor} 0%,
              ${params.hoverColor} 50%,
              ${params.hoverColor} 50%,
              ${params.hoverColor} 100%
            )`,
          backgroundRepeat: 'repeat',
          backgroundSize: '200%',
          backgroundPosition: '100% 0',
          WebkitBackgroundClip: 'text',
          transition: 'background-position 300ms',
        },

        '&::before': {
          content: "''",
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '56px',
          borderBottom: `3px solid ${PALITRA.crimson}`,
          WebkitTransformOrigin: '100% 0',
          transformOrigin: '100% 0',
          transform: 'scale3d(0, 1, 1)',
          WebkitTransform: 'scale3d(0, 1, 1)',
          transition: 'transform 300ms',
        },

        '&:hover:not(.no-hover)': {
          '& a, span': {
            backgroundPosition: '0 0',
            color: PALITRA.white,
          },

          '&::before': {
            transformOrigin: '0 0',
            transform: 'scale3d(1, 1, 1)',
          },
        },

        '&.profile__item': {
          position: 'absolute',
          right: '75px',
          width: '38px',
        },

        '&.logout__item': {
          position: 'absolute',
          right: '0px',
          width: '75px',
        },

        '&.login__item': {
          position: 'absolute',
          right: '0px',
          width: '75px',
        },

        '&.lessons__item': {
          pointerEvents: 'painted',

          '& span': {
            marginRight: '5px',
            transform: 'rotate(0deg)',
            transition: 'transform 0.2s linear',

            '&.dropped': {
              transform: 'rotate(90deg)',
            },
          },
        },
      },

      '& .logo__item': {
        width: '38px',
        marginRight: '20px',

        '& img': {
          width: '30px',
          height: '30px',
          marginBottom: '4px',
        },
      },
    },

    '&.default-menu > li > a': {
      width: '100%',
      textAlign: 'center',
    },

    '@media (max-width: 900px)': {
      '& .default-menu__battleship, & .default-menu__dropdown, & .login__item, & .default-menu__tic-tac-toe, & .logout__item, li:not(:nth-child(5)).profile__item ':
        {
          display: 'none!important',
        },

      '&.default-menu': {
        '& li:not([class="logo__item"])': {
          opacity: '0',
          pointerEvents: 'none',
        },
        '& li.logo__item': {
          opacity: '1',
          pointerEvents: 'all',
        },

        '& .disabledLogo': {
          opacity: '0 !important',
          pointerEvents: 'none !important',
        },
        '&.default-menu': {
          '& .active': {
            borderBottom: `none`,
          },
        },
      },
    },
  },
  rootDrop: {
    '& .header_dropdown': {
      color: 'white',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '16px',
    },
    '& .ant-dropdown-menu': {
      padding: '0px',
      pointerEvents: 'all',
      position: 'absolute',
      paddingLeft: '0',
      backgroundColor: '#001529',
      width: '225px',
      left: '0',
    },
    '& .ant-dropdown-menu-item': {
      lineHeight: '35px',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '95%',
        left: '0%',
        width: '100%',
        borderBottom: `3px solid ${PALITRA.crimson}`,
        WebkitTransformOrigin: '100% 0',
        transformOrigin: '100% 0',
        transform: 'scale3d(0, 1, 1)',
        WebkitTransform: 'scale3d(0, 1, 1)',
        transition: 'transform 300ms',
      },
      '&:hover': {
        color: PALITRA.white,
        backgroundColor: '#001529',

        '&::before': {
          transformOrigin: '0 0',
          transform: 'scale3d(1, 1, 1)',
        },
      },
    },
  },
});

export default useStyles;
